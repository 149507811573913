import React, {useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import axiosInstance from '../../../api/axios'
import {Link, useNavigate} from 'react-router-dom'
import {motion} from 'framer-motion'
import ReactTable from '../../../../_metronic/partials/widgets/React_Table/ReactTable'
import {Select, Skeleton, FormControl, InputLabel} from '@mui/material'
import {Images} from '../../../../_metronic/partials/widgets/React_Table/Images'
import ReactTableWithPagention from '../../../../_metronic/partials/widgets/React_Table/ReactTableWithPagention'
import Swal from 'sweetalert2'
import {KTSVG} from '../../../../_metronic/helpers'
import {ExportOrders} from './ExportOrders'
import useAuthContext from '../../../Auth/AuthContext'
import {Dropdown, DropdownButton} from 'react-bootstrap'
import {Menu, MenuItem, IconButton} from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'

const OrderHome = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [inputValue, setInputValue] = useState(null)
  const [Export, setExport] = useState(null)

  const [Queries, setQueries] = useState({
    status: 'all',
    admin_approve: 'all',
  })

  const admin_approve_types = [
    {
      id: 'all',
      status: 'All',
    },
    {
      id: 'pending',
      status: 'Pending',
    },
    {
      id: 'approved',
      status: 'Approved',
    },
    {
      id: 'rejected',
      status: 'Rejected',
    },
  ]
  const status_types = [
    {
      id: 'all',
      status: 'All',
    },
    {
      id: 'completed',
      status: 'completed',
    },
    {
      id: 'pending',
      status: 'Pending',
    },
    {
      id: 'failed',
      status: 'failed',
    },
  ]

  const handleChange = (e) => {
    const {name, value} = e.target
    setQueries((prevData) => ({...prevData, [name]: value}))
  }

  const {check_role} = useAuthContext()
  const navigate = useNavigate()

  const [check, setCheck] = useState({
    export: false,
    checkOrder: false,
    browse: false,
    show: false,
    sendOrdersVoucher: false,
  })

  const CustomDiv = () => (
    <div className='row flex-end w-100'>
      <div className='col-4'>
        <FormControl fullWidth>
          <InputLabel
            id='demo-simple-select-label'
            className='mb-10'
            style={{  fontSize: '18px' }}

           
          >
            Status
          </InputLabel>

          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            onChange={handleChange}
            name='status'
            value={Queries.status}
            style={{marginTop: '12px'}}

          >
            {status_types.map((row, key) => (
              <MenuItem key={key} value={row.id}>
                {row?.status}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div className='col-4'>
        <FormControl fullWidth>
          <InputLabel id='demo-simple-select-label' className='mb-10'             style={{  fontSize: '18px' }}
          >
            Admin Approve
          </InputLabel>
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            onChange={handleChange}
            name='admin_approve'
            value={Queries.admin_approve}
            style={{marginTop: '12px'}}

          >
            {admin_approve_types.map((row, key) => (
              <MenuItem key={key} value={row.id}>
                {row?.status}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      {check.export && (
        <div className='col-auto'>
          <button
            onClick={(e) => setExport(true)}
            type='button'
            className='btn py-5 btn-light-primary'
          >
            <KTSVG path='/media/icons/duotune/files/fil021.svg' className='svg-icon-2' />
            {intl.formatMessage({id: 'Table.Export'})}
          </button>
        </div>
      )}
    </div>
  )

  const getData = async () => {
    const {data} = await axiosInstance.get(`/admin-orders?page=${currentPage}`, {
      params: {
        name: inputValue,
        status: Queries.status,
        admin_approve: Queries.admin_approve,
      },
    })
    setItems(data.data)
    setTotalPages(data.meta.last_page)
  }

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1)
  }

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1)
  }
  useEffect(() => {
    getData()
  }, [inputValue, currentPage, Queries])

  const CheckOrder = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert the Changes!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Check it!',
      showLoaderOnConfirm: true,
      preConfirm: async (login) => {
        try {
          const {data} = await axiosInstance.get(`/admin-order-check/${id}`)
          if (!data || data.length === 0) {
            throw new Error(JSON.stringify(data))
          }
          getData()
        } catch (error) {
          Swal.showValidationMessage(`
                      Request failed: ${error}
                  `)
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Done!',
          icon: 'success',
        })
      }
    })
  }

  const send_mail = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert the Changes!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Check it!',
      showLoaderOnConfirm: true,
      preConfirm: async (login) => {
        try {
          const {data} = await axiosInstance.get(`/admin-order-send-vouchars/${id}`)
          if (!data || data.length === 0) {
            throw new Error(JSON.stringify(data))
          }
          getData()
        } catch (error) {
          Swal.showValidationMessage(`
                      Request failed: ${error}
                  `)
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Done!',
          icon: 'success',
        })
      }
    })
  }

  const [orderData, setOrderData] = useState({})

  const fetchOrderData = async (orderId) => {
    // setLoading(true)
    try {
      const response = await axiosInstance.get(`/admin-orders/${orderId}`)
      setOrderData((prev) => ({
        ...prev,
        [orderId]: response.data, // Store fetched data for this specific order
      }))
      // setLoading(false)
    } catch (error) {
      console.error('Failed to fetch order data', error)
      // setLoading(false)
    }
  }

  const columns = [
    {
      Header: intl.formatMessage({id: 'Table.ID'}),
      accessor: 'id',
      Cell: ({row}) => <div className='w-100 flex-center'># {row.original.id}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.User'}),
      accessor: (row) => `${row.user?.name} ${row.user?.id}`,
      Cell: (row) =>
        row.row.original.user && (
          <a href={`/Users/Edit-Users/${row.row.original.user.id}`} target='_blank'>
            <div className='d-flex align-items-center ps-3'>
              <Images img={row.row.original.user.img} name={row.row.original.user.name} />
              <div className='flex-grow-1'>
                <div className='text-dark fw-bold text-hover-primary text-start fs-6'>
                  {row.row.original.user.name}
                </div>
                <span className='text-muted d-block fw-semibold text-start'>
                  #{row.row.original.user.id}
                </span>
              </div>
            </div>
          </a>
        ),
      // Define a custom filter function
      filter: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const user = row.values[id] // id will be 'user' which is the accessor
          return user.toLowerCase().includes(filterValue.toLowerCase()) // Search on concatenated string
        })
      },
    },
    {
      Header: intl.formatMessage({id: 'Table.Total'}),
      accessor: 'total',
      Cell: ({row}) => <div className='w-100 flex-center'>{row.original.total}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.Status'}),
      accessor: 'status',
      Cell: ({row}) => (
        <div className='w-100 flex-center'>
          {row.original.status === 'failed' && (
            <span className='badge badge-light-danger'>{row.original.status}</span>
          )}

          {row.original.status === 'pending' && (
            <span className='badge badge-light-warning'>{row.original.status}</span>
          )}

          {row.original.status === 'completed' && (
            <span className='badge badge-light-success'>{row.original.status}</span>
          )}
        </div>
      ),
    },
    {
      Header: intl.formatMessage({id: 'Table.AdminApproved'}),
      accessor: 'admin_approved',
      Cell: ({row}) => (
        <div className='w-100 flex-center'>
          {row.original.admin_approved === 'refused' && (
            <span className='badge badge-light-danger'>{row.original.admin_approved}</span>
          )}

          {row.original.admin_approved === 'pending' && (
            <span className='badge badge-light-warning'>{row.original.admin_approved}</span>
          )}

          {row.original.admin_approved === 'approved' && (
            <span className='badge badge-light-success'>{row.original.admin_approved}</span>
          )}
        </div>
      ),
    },
    {
      Header: intl.formatMessage({id: 'Table.payment_type'}),
      accessor: 'payment_type',
      Cell: ({row}) => <div className='w-100 flex-center'>{row.original.payment_type}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.Date'}),
      accessor: 'created_at',
      Cell: ({row}) => <div className='w-100 flex-center'>{row.original.created_at}</div>,
    },

    {
      Header: intl.formatMessage({id: 'Table.Action'}),
      Cell: ({row}) => {
        return (
          <div className='d-flex flex-wrap flex-center w-100'>
            <DropdownButton
              id={`dropdown-button-drop-${row.original.id}`}
              title={intl.formatMessage({id: 'Table.Action'})}
              variant='primary'
            >
              {check.show && (
                <Dropdown.Item className='text-center'>
                  <Link
                    to={`/Order-details/${row.original.id}`}
                    className='dropdown-item text-center'
                  >
                    {intl.formatMessage({id: 'Table.View'})}
                  </Link>
                </Dropdown.Item>
              )}

              {check.checkOrder && (
                <Dropdown.Item className='text-center' onClick={() => CheckOrder(row.original.id)}>
                  {intl.formatMessage({id: 'Table.CheckOrder'})}
                </Dropdown.Item>
              )}
              {check.sendOrdersVoucher && (
                <Dropdown.Item className='text-center' onClick={() => send_mail(row.original.id)}>
                  {intl.formatMessage({id: 'Table.SendVouchers'})}
                </Dropdown.Item>
              )}

              <Dropdown.Item onClick={() => send_mail(row.original.id)}>
                {intl.formatMessage({id: 'Table.PDF'})}
              </Dropdown.Item>
            </DropdownButton>
          </div>
        )
      },
    },
  ]

  useEffect(() => {
    setCheck({
      browse: check_role('browse-orders'),
      export: check_role('export-orders'),
      checkOrder: check_role('check-orders'),
      sendOrdersVoucher: check_role('send-orders-voucher'),
      show: check_role('show-orders'),
    })

    if (!check_role('browse-orders')) {
      navigate('/')
    }
    getData()
  }, [])

  return (
    <motion.nav
      initial={{opacity: 0, x: 100}}
      animate={{opacity: 1, x: 0, transition: {duration: 0.3}}}
      exit={{opacity: 0, x: 100, transition: {duration: 0.2}}}
    >
      <div className='mb-5 mt-5 d-flex flex-stack'>
        <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
          <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
            {intl.formatMessage({id: 'Menu.Orders'})}
          </h1>
          <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
            <li className='breadcrumb-item text-muted'>{intl.formatMessage({id: 'Menu.Home'})}</li>
            <li className='breadcrumb-item'>
              <span className='bullet bg-gray-400 w-5px h-2px'></span>
            </li>
            <li className='breadcrumb-item text-muted'>
              {intl.formatMessage({id: 'Menu.Orders'})}
            </li>
          </ul>
        </div>
      </div>

      {!loading ? (
        <div className='d-none d-lg-block'>
          <ReactTableWithPagention
            columns={columns}
            data={items}
            currentPage={currentPage}
            totalPages={totalPages}
            handlePrevPage={handlePrevPage}
            handleNextPage={handleNextPage}
            inputValue={inputValue}
            setInputValue={setInputValue}
            customComponent={<CustomDiv />}
          />
        </div>
      ) : (
        <div className='row'>
          <div className='col-12'>
            <Skeleton
              variant='rounded'
              animation='wave'
              sx={{width: '100%', height: '90vh', marginTop: '1vh'}}
            />
          </div>
        </div>
      )}

      {/* Card */}
      <div className='d-block d-lg-none'>
        <div className='mb-4'>
          <div className='mb-3'>
            <CustomDiv />
          </div>
          <input
            type='text'
            className='form-control'
            placeholder={intl.formatMessage({id: 'Search.Input'})}
            value={inputValue || ''}
            onChange={(e) => setInputValue(e.target.value)}
          />
        </div>

        {items.length > 0 ? (
          <>
            {items.map((row) => (
              <div className='card mb-3' key={row.id}>
                <div className='card-body'>
                  <h5 className='card-title'>
                    {intl.formatMessage({id: 'Table.ID'})}: # {row.id}
                  </h5>
                  <p className='card-text'>
                    <strong>{intl.formatMessage({id: 'Table.User'})}:</strong>
                    <a href={`/Users/Edit-Users/${row.user?.id}`} target='_blank'>
                      {row.user?.name} #{row.user?.id}
                    </a>
                  </p>
                  <p className='card-text'>
                    <strong>{intl.formatMessage({id: 'Table.Total'})}:</strong> {row.total}
                  </p>
                  <p className='card-text'>
                    <strong>{intl.formatMessage({id: 'Table.Status'})}:</strong>
                    {row.status === 'failed' && (
                      <span className='badge badge-light-danger'>{row.status}</span>
                    )}
                    {row.status === 'pending' && (
                      <span className='badge badge-light-warning'>{row.status}</span>
                    )}
                    {row.status === 'completed' && (
                      <span className='badge badge-light-success'>{row.status}</span>
                    )}
                  </p>
                  <p className='card-text'>
                    <strong>{intl.formatMessage({id: 'Table.AdminApproved'})}:</strong>
                    {row.admin_approved === 'refused' && (
                      <span className='badge badge-light-danger'>{row.admin_approved}</span>
                    )}
                    {row.admin_approved === 'pending' && (
                      <span className='badge badge-light-warning'>{row.admin_approved}</span>
                    )}
                    {row.admin_approved === 'approved' && (
                      <span className='badge badge-light-success'>{row.admin_approved}</span>
                    )}
                  </p>
                  <p className='card-text'>
                    <strong>{intl.formatMessage({id: 'Table.payment_type'})}:</strong>{' '}
                    {row.payment_type}
                  </p>
                  <p className='card-text'>
                    <strong>{intl.formatMessage({id: 'Table.Date'})}:</strong> {row.created_at}
                  </p>
                  {/* Action Buttons */}
                  <div className='row flex-between'>
                    {/* View Button */}
                    {check.show && (
                      <div className='col-6'>
                        <button className='btn btn-primary w-100'>
                          <Link
                            to={`/Order-details/${row.id}`}
                            className='text-white text-decoration-none'
                          >
                            {intl.formatMessage({id: 'Table.View'})}
                          </Link>
                        </button>
                      </div>
                    )}

                    {/* Check Order Button */}
                    {check.checkOrder && (
                      <div className='col-6'>
                        <button
                          className='btn btn-warning w-100'
                          onClick={() => CheckOrder(row.id)}
                        >
                          {intl.formatMessage({id: 'Table.CheckOrder'})}
                        </button>
                      </div>
                    )}

                    {/* Send Vouchers Button */}
                    {check.sendOrdersVoucher && (
                      <div className='col-12 my-2'>
                        <button className='btn btn-success w-100' onClick={() => send_mail(row.id)}>
                          {intl.formatMessage({id: 'Table.SendVouchers'})}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}

            {/* Pagination Controls for Card View */}
            <div className='d-flex justify-content-center align-items-center mt-3'>
              <button
                className='btn btn-light-primary me-2'
                onClick={handlePrevPage}
                disabled={currentPage === 1}
              >
                {intl.formatMessage({id: 'Table.Prev'})}
              </button>

              {/* Pagination numbers */}
              <div className='d-flex'>
                {currentPage > 2 && (
                  <button className='btn btn-light mx-1' onClick={() => setCurrentPage(1)}>
                    1
                  </button>
                )}

                {currentPage > 3 && <span className='mx-2'>...</span>}

                {/* Render surrounding pages */}
                {[...Array(totalPages)].map((_, index) => {
                  const pageNumber = index + 1

                  // Only show the current page and surrounding pages
                  if (
                    pageNumber === currentPage ||
                    (pageNumber >= currentPage - 1 && pageNumber <= currentPage + 1)
                  ) {
                    return (
                      <button
                        key={index}
                        className={`btn ${
                          currentPage === pageNumber ? 'btn-primary' : 'btn-light'
                        } mx-1`}
                        onClick={() => setCurrentPage(pageNumber)}
                      >
                        {pageNumber}
                      </button>
                    )
                  }

                  return null
                })}

                {currentPage < totalPages - 2 && <span className='mx-2'>...</span>}

                {currentPage < totalPages - 1 && (
                  <button className='btn btn-light mx-1' onClick={() => setCurrentPage(totalPages)}>
                    {totalPages}
                  </button>
                )}
              </div>

              <button
                className='btn btn-light-primary ms-2'
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                {intl.formatMessage({id: 'Table.Next'})}
              </button>
            </div>
          </>
        ) : (
          <div className='text-center my-5'>
            {/* Spinner for loading effect */}
            <div className='spinner-border text-primary mb-3' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>

            {/* No Data message */}
            <p className='text-muted fs-4'>
              <i className='fas fa-exclamation-circle me-2'></i>
              {intl.formatMessage({id: 'Table.NoData'})}
            </p>
          </div>
        )}
      </div>

      <ExportOrders handleClose={setExport} show={Export} />
    </motion.nav>
  )
}

export default OrderHome
