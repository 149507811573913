import moment from 'moment';
import React, { useState } from 'react'
import axiosInstance from '../../../api/axios';
import { Button, Modal } from 'react-bootstrap';
import { FormControl, MenuItem, Select } from '@mui/material';
import { useIntl } from 'react-intl';
import "flatpickr/dist/themes/light.css";
import Flatpickr from "react-flatpickr";

export const CustomDateModel =  ({ handleClose, show  , setData2 , setLoading , loading}) => {
    const intl = useIntl();
    const types = [
        { id: 'days', name_en: 'days' },
        { id: 'weeks', name_en: 'weeks' },
        { id: 'months', name_en: 'months' },
    ];

    const [data, setData] = useState({
        type: 'days',
        report_type:'custome',
        start_date: moment().format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleEdit_Add_submit = async (event) => {
        event.preventDefault();
        setLoading(true);
         await axiosInstance.get('/admin-analytics', {
            params: {  
                'report_type' : 'custome',
                'type' : data.type,
                'start_date' : data.start_date,
                'end_date' : data.end_date,
            },
        }).then((res) => {
            setData2(res.data);
        })
        setLoading(false);
        handleClose()
    };

  return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                Report
            </Modal.Header>
            <form onSubmit={handleEdit_Add_submit}>
                <Modal.Body>
                    <div className="row flex-between">
                        <div className="col-lg-6 col-md-6 my-5">
                            <h6>{intl.formatMessage({ id: 'Form.StartDate' })}</h6>
                            <div className="card">
                                <Flatpickr
                                    value={data.start_date}
                                    onChange={([start_date]) => {
                                        setData((prevData) => ({
                                            ...prevData,
                                            start_date: moment(start_date).format('YYYY-MM-DD')
                                        }));
                                    }}
                                    className='form-control py-4 text-center pt-5'
                                    placeholder='Pick date'
                                />
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 my-5">
                            <h6>{intl.formatMessage({ id: 'Form.EndDate' })}</h6>
                            <div className="card">
                                <Flatpickr
                                    options={{ minDate: data.start_date }}
                                    value={data.end_date}
                                    onChange={([end_date]) => {
                                        setData((prevData) => ({
                                            ...prevData,
                                            end_date: moment(end_date).format('YYYY-MM-DD')
                                        }));
                                    }}
                                    className='form-control py-4 text-center pt-5'
                                    placeholder='Pick date'
                                />
                            </div>
                        </div>
 
                        <div className="col-12 my-5">
                            <label className="required form-label">{intl.formatMessage({ id: 'Table.ChartBy' })}</label>
                            <FormControl fullWidth>
                                <Select
                                    labelId="demo-simple-select-label2"
                                    id="demo-simple-select"
                                    onChange={handleChange}
                                    name='type'
                                    value={data.type}
                                >
                                    {types.map((row) => (
                                        <MenuItem key={row.id} value={row.id}>
                                            {row.name_en}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={(e) => handleClose(false)}>
                        Close
                    </Button>
                    <Button type="submit" className="btn btn-primary" disabled={loading}>
                        {!loading && intl.formatMessage({ id: 'Form.Serach' })}
                        {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                {intl.formatMessage({ id: 'Form.Pleasewait' })}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}
