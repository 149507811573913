import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import axiosInstance from '../../../../api/axios'
import Swal from 'sweetalert2'
import {Link, useNavigate} from 'react-router-dom'
import {motion} from 'framer-motion'
import ReactTableWithPagention from '../../../../../_metronic/partials/widgets/React_Table/ReactTableWithPagention'
import {Images} from '../../../../../_metronic/partials/widgets/React_Table/Images'
import useAuthContext from '../../../../Auth/AuthContext'
import {Skeleton} from '@mui/material'

const LevelRequestsHome = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [inputValue, setInputValue] = useState(null)

  const {check_role} = useAuthContext()
  const navigate = useNavigate()

  const [check, setCheck] = useState({
    browse: false,
    add: false,
    show: false,
    edit: false,
    delete: false,
  })

  const getData = async () => {
    const {data} = await axiosInstance.get(`/admin-levels-requests?page=${currentPage}`, {
      params: {name: inputValue},
    })
    setItems(data.data)
    setTotalPages(data.meta.last_page)
  }

  const handleSearch = (event) => {
    setInputValue(event.target.value)
  }

  const handleCreate = () => {
    navigate('/Levels/Create')
  }

  const columns = [
    {
      Header: intl.formatMessage({id: 'Table.User'}),
      accessor: (row) => `${row.user?.name} ${row.user?.id}`,
      Cell: (row) =>
        row.row.original.user && (
          <div className='d-flex flex-center align-items-center ps-3'>
            <Images img={row.row.original.user.img} name={row.row.original.user.name} />
            <div className=''>
              <div className='text-dark fw-bold text-hover-primary text-start fs-6'>
                {row.row.original.user.name}
              </div>
              <span className='text-muted d-block fw-semibold text-start'>
                #{row.row.original.user.id}
              </span>
            </div>
          </div>
        ),
    },
    {
      Header: intl.formatMessage({id: 'Table.Level'}),
      accessor: (row) => `${row.level?.name} ${row.level?.id}`,
      Cell: (row) =>
        row.row.original.level && (
          <div className='d-flex align-items-center ps-3 flex-center'>
            <Images img={row.row.original.level.img} name={row.row.original.level.name} />
            <div className=''>
              <div className='text-dark fw-bold text-hover-primary text-start fs-6'>
                {row.row.original.level.name}
              </div>
              <span className='text-muted d-block fw-semibold text-start'>
                #{row.row.original.level.id}
              </span>
            </div>
          </div>
        ),
    },
    {
      Header: intl.formatMessage({id: 'Table.Status'}),
      accessor: 'status',
      Cell: ({row}) => (
        <div className='w-100 flex-center'>
          {row.original.status === -1 && <span className='badge bg-danger'>Refused</span>}
          {row.original.status === 0 && <span className='badge bg-warning'>Pending</span>}
          {row.original.status === 1 && <span className='badge bg-success'>Approved</span>}
        </div>
      ),
    },
    {
      Header: intl.formatMessage({id: 'Table.Date'}),
      accessor: 'created_at',
      Cell: ({row}) => <div className='w-100 flex-center'>{row.original.created_at}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.Action'}),
      Cell: ({row}) => (
        <div className='d-flex flex-center'>
          {check.edit && (
            <Link
              to={`/Levels/Edit-Levels-Requests/${row.original.id}`}
              className='btn btn-primary'
            >
              {intl.formatMessage({id: 'Table.View'})}
            </Link>
          )}
        </div>
      ),
    },
  ]

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1)
  }

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1)
  }

  useEffect(() => {
    setCheck({
      browse: check_role('browse-levels-requests'),
      show: check_role('show-levels-requests'),
      edit: check_role('edit-levels-requests'),
    })

    if (!check_role('browse-levels-requests')) {
      navigate('/')
    }
    getData()
  }, [inputValue, currentPage])

  return (
    <motion.nav
      initial={{opacity: 0, x: 100}}
      animate={{opacity: 1, x: 0, transition: {duration: 0.3}}}
      exit={{opacity: 0, x: 100, transition: {duration: 0.2}}}
    >
      <div className='mb-5 mt-5 d-flex flex-stack'>
        <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
          <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
            {intl.formatMessage({id: 'Menu.LevelsRequests'})}
          </h1>
          <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
            <li className='breadcrumb-item text-muted'>{intl.formatMessage({id: 'Menu.Home'})}</li>
            <li className='breadcrumb-item'>
              <span className='bullet bg-gray-400 w-5px h-2px'></span>
            </li>
            <li className='breadcrumb-item text-muted'>
              {intl.formatMessage({id: 'Menu.Levels'})}
            </li>
          </ul>
        </div>
      </div>

      {!loading ? (
        <>
          {/* Search small screens */}
          <div className='d-block d-lg-none mb-4'>
            <div className='input-group mb-3'>
              <input
                type='text'
                className='form-control'
                placeholder={intl.formatMessage({id: 'Search.Input'})}
                onChange={handleSearch}
              />
            </div>
          </div>

          {/* Table */}
          <div className='d-none d-lg-block'>
            <ReactTableWithPagention
              columns={columns}
              data={items}
              currentPage={currentPage}
              totalPages={totalPages}
              handlePrevPage={handlePrevPage}
              handleNextPage={handleNextPage}
              inputValue={inputValue}
              setInputValue={setInputValue}
              customComponent={<></>}
            />
          </div>

          {/* Card */}
          <div className='d-block d-lg-none row'>
            {items.map((item) => (
              <div key={item.id} className='col-12 col-md-6 mb-4'>
                <div className='card'>
                  <div className='card-body'>
                    {/* عرض المستخدم والمستوى بجانب بعض */}
                    <div className='d-flex justify-content-between align-items-center mb-3'>
                      {/* بيانات المستخدم */}
                      <div className='d-flex align-items-center'>
                        <Images img={item.user?.img} name={item.user?.name} />
                        <h5 className='card-title ms-3'>
                          {item.user?.name} <span className='text-muted'>#{item.user?.id}</span>
                        </h5>
                      </div>

                      {/* بيانات المستوى */}
                      <div className='d-flex align-items-center'>
                        <Images img={item.level?.img} name={item.level?.name} />
                        <p className='card-text ms-3'>
                          {item.level?.name} <span className='text-muted'>#{item.level?.id}</span>
                        </p>
                      </div>
                    </div>

                    {/* عرض حالة الطلب */}
                    <p className='card-text mt-5'>
                      Status:
                      {item.status === -1 && <span className='badge bg-danger ms-2'>Refused</span>}
                      {item.status === 0 && <span className='badge bg-warning ms-2'>Pending</span>}
                      {item.status === 1 && <span className='badge bg-success ms-2'>Approved</span>}
                    </p>

                    {/* عرض التاريخ */}
                    <p className='card-text'>Date: {item.created_at}</p>

                    {/* زر العرض */}
                    {check.edit && (
                      <Link
                        to={`/Levels/Edit-Levels-Requests/${item.id}`}
                        className='btn btn-primary col-6'
                      >
                        {intl.formatMessage({id: 'Table.View'})}
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            ))}
                {/* Pagination Controls for Card View */}
                <div className='d-flex justify-content-center align-items-center mt-3'>
              <button
                className='btn btn-light-primary me-2'
                onClick={handlePrevPage}
                disabled={currentPage === 1}
              >
                {intl.formatMessage({id: 'Table.Prev'})}
              </button>

              {/* Pagination numbers */}
              <div className='d-flex'>
                {currentPage > 2 && (
                  <button className='btn btn-light mx-1' onClick={() => setCurrentPage(1)}>
                    1
                  </button>
                )}

                {currentPage > 3 && <span className='mx-2'>...</span>}

                {/* Render surrounding pages */}
                {[...Array(totalPages)].map((_, index) => {
                  const pageNumber = index + 1

                  // Only show the current page and surrounding pages
                  if (
                    pageNumber === currentPage ||
                    (pageNumber >= currentPage - 1 && pageNumber <= currentPage + 1)
                  ) {
                    return (
                      <button
                        key={index}
                        className={`btn ${
                          currentPage === pageNumber ? 'btn-primary' : 'btn-light'
                        } mx-1`}
                        onClick={() => setCurrentPage(pageNumber)}
                      >
                        {pageNumber}
                      </button>
                    )
                  }

                  return null
                })}

                {currentPage < totalPages - 2 && <span className='mx-2'>...</span>}

                {currentPage < totalPages - 1 && (
                  <button className='btn btn-light mx-1' onClick={() => setCurrentPage(totalPages)}>
                    {totalPages}
                  </button>
                )}
              </div>

              <button
                className='btn btn-light-primary ms-2'
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                {intl.formatMessage({id: 'Table.Next'})}
              </button>
            </div>
          </div>
          
        </>
      ) : (
        <div className='row'>
          <div className='col-12'>
            <Skeleton
              variant='rounded'
              animation='wave'
              sx={{width: '100%', height: '90vh', marginTop: '1vh'}}
            />
          </div>
        </div>
      )}
    </motion.nav>
  )
}

export default LevelRequestsHome
