import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import axiosInstance from '../../../api/axios'
import Swal from 'sweetalert2'
import {KTSVG} from '../../../../_metronic/helpers'
import {Link, useNavigate} from 'react-router-dom'
import {motion} from 'framer-motion'
import ReactTable from '../../../../_metronic/partials/widgets/React_Table/ReactTable'
import {Skeleton} from '@mui/material'
import useAuthContext from '../../../Auth/AuthContext'
import Pagination from '@mui/material/Pagination'

const IntegrationsHome = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [Items, setItems] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')

  const {check_role} = useAuthContext()
  const navigate = useNavigate()

  const [check, setCheck] = useState({
    browse: false,
    add: false,
    show: false,
    edit: false,
    delete: false,
  })

  const CustomDiv = () => (
    <div className='row flex-end w-100'>
      {check.add && (
        <div className='col-auto'>
          <Link to='/Developers/Add-Integrations' type='button' className='btn btn-primary'>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            {intl.formatMessage({id: 'Integrations.Create'})}
          </Link>
        </div>
      )}
    </div>
  )

  const get_data = async () => {
    setLoading(true)
    const {data} = await axiosInstance.get('/admin-integrations')
    setItems(data)
    setLoading(false)
  }

  const edit_status = async (value, id) => {
    const endpoint = `/change-integrations-status/${id}/${value}`
    try {
      await axiosInstance.get(endpoint)
      get_data()
    } catch (error) {
      console.error(error)
    }
  }

  const Delete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const {data} = await axiosInstance.delete(`/admin-integrations/${id}`)
          if (!data || data.length === 0) {
            throw new Error(JSON.stringify(data))
          }
          get_data()
        } catch (error) {
          Swal.showValidationMessage(`Request failed: ${error}`)
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire('Deleted!', 'Your file has been deleted.', 'success')
      }
    })
  }

  const columns = [
    {
      Header: intl.formatMessage({id: 'Table.ID'}),
      accessor: 'id',
      Cell: (row) => <div className='w-100 flex-center'># {row.row.original.id}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.Name'}),
      accessor: 'name',
      Cell: (row) => <div className='w-100 flex-center'>{row.row.original.name}</div>,
    },
    {
      Header: 'Enable',
      accessor: 'status',
      Cell: (row) => (
        <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-center'>
          {check.edit && (
            <input
              className='form-check-input'
              type='checkbox'
              defaultChecked={row.row.original.status}
              onClick={() => edit_status(!row.row.original.status, row.row.original.id)}
            />
          )}
        </div>
      ),
    },
    {
      Header: intl.formatMessage({id: 'Table.Action'}),
      Cell: (row) => (
        <div className='d-flex flex-center'>
          {check.edit && (
            <Link
              to={`/Developers/Edit-Integrations/${row.row.original.id}`}
              className='btn me-2 btn-light-primary'
            >
              {intl.formatMessage({id: 'Table.Edit'})}
            </Link>
          )}
          {check.delete && (
            <button
              onClick={() => Delete(row.row.original.id)}
              className='btn me-2 btn-light-danger'
            >
              {intl.formatMessage({id: 'Table.Delete'})}
            </button>
          )}
        </div>
      ),
    },
  ]

  useEffect(() => {
    setCheck({
      browse: check_role('browse-integrations'),
      add: check_role('add-integrations'),
      show: check_role('show-integrations'),
      edit: check_role('edit-integrations'),
      delete: check_role('delete-integrations'),
    })

    if (!check_role('browse-integrations')) {
      navigate('/')
    }
    get_data()
  }, [])

  const filteredItems = Items
    ? Items.filter((item) => item.name.toLowerCase().includes(searchTerm.toLowerCase()))
    : []

  // Pagination state and logic
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage] = useState(10)

  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem)

  const handlePageChange = (event, value) => {
    setCurrentPage(value)
  }

  return (
    <motion.nav
      initial={{opacity: 0, x: 100}}
      animate={{opacity: 1, x: 0}}
      exit={{opacity: 0, x: 100}}
    >
      <div className='mb-5 mt-5 d-flex flex-stack'>
        <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
          <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
            {intl.formatMessage({id: 'Menu.Integrations'})}
          </h1>
          <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
            <li className='breadcrumb-item text-muted'>{intl.formatMessage({id: 'Menu.Home'})}</li>
            <li className='breadcrumb-item'>
              <span className='bullet bg-gray-400 w-5px h-2px'></span>
            </li>
            <li className='breadcrumb-item text-muted'>
              {intl.formatMessage({id: 'Menu.Integrations'})}
            </li>
          </ul>
        </div>
      </div>

      {Items !== null && !loading ? (
        <>
          <div className='d-none d-lg-block'>
            <ReactTable columns={columns} data={filteredItems} customComponent={<CustomDiv />} />
          </div>

          {/* Card View with Pagination */}
          <div className='d-lg-none'>
            <div className='mb-3'>
              <CustomDiv />
            </div>
            <div className='mb-4'>
              <input
                type='text'
                className='form-control'
                placeholder={intl.formatMessage({id: 'Search.Input'})}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className='row'>
              {currentItems.map((item) => (
                <div key={item.id} className='col-12 mb-3'>
                  <div className='card'>
                    <div className='card-body'>
                      <h5 className='card-title'>ID: {item.id}</h5>
                      <p className='card-text'>Name: {item.name}</p>
                      <div className='form-check form-switch'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          defaultChecked={item.status}
                          onClick={() => edit_status(!item.status, item.id)}
                        />
                        <label className='form-check-label'>Enable</label>
                      </div>
                      <div className='mt-4'>
                        {check.edit && (
                          <Link
                            to={`/Developers/Edit-Integrations/${item.id}`}
                            className='btn btn-light-primary me-2 col-5'
                          >
                            {intl.formatMessage({id: 'Table.Edit'})}
                          </Link>
                        )}
                        {check.delete && (
                          <button
                            onClick={() => Delete(item.id)}
                            className='btn btn-light-danger me-2 col-5'
                          >
                            {intl.formatMessage({id: 'Table.Delete'})}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Pagination */}
            <div className='mt-6 d-flex justify-content-center'>
              <Pagination
                count={Math.ceil(filteredItems.length / itemsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                color='primary'
              />
            </div>
          </div>
        </>
      ) : (
        <Skeleton
          variant='rounded'
          animation='wave'
          sx={{width: '100%', height: '90vh', marginTop: '1vh'}}
        />
      )}
    </motion.nav>
  )
}

export default IntegrationsHome
