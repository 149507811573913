import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import axiosInstance from '../../../../api/axios'
import Swal from 'sweetalert2'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {Link, useNavigate} from 'react-router-dom'
import {motion} from 'framer-motion'
import ReactTable from '../../../../../_metronic/partials/widgets/React_Table/ReactTable'
import {Pagination, Skeleton} from '@mui/material'
import useAuthContext from '../../../../Auth/AuthContext'

const PermissionGroupsHome = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState([])
  const {check_role} = useAuthContext()
  const navigate = useNavigate()
  const [check, setCheck] = useState({
    browse: false,
    add: false,
    show: false,
    edit: false,
    delete: false,
  })
  const [searchTerm, setSearchTerm] = useState('') // State for search term

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 10 // Number of users per page

  const CustomDiv = () => (
    <div className='row flex-end w-100'>
      <div className='col-auto'>
        <Link to='/Roles/Add-PermissionGroups' type='button' className='btn btn-primary'>
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
          {intl.formatMessage({id: 'Group.Create'})}
        </Link>
      </div>
    </div>
  )

  const getData = async () => {
    setLoading(true)
    const {data} = await axiosInstance.get('/admin-permissions-categories')
    setItems(data)
    setLoading(false)
  }

  const deleteItem = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const {data} = await axiosInstance.delete(`/admin-permissions-categories/${id}`)
          if (!data || data.length === 0) {
            throw new Error(JSON.stringify(data))
          }
          getData()
        } catch (error) {
          Swal.showValidationMessage(`Request failed: ${error}`)
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Done!',
          icon: 'success',
        })
      }
    })
  }

  const columns = [
    {
      Header: intl.formatMessage({id: 'Table.ID'}),
      accessor: 'id',
      Cell: ({row}) => <div className='w-100 flex-center'># {row.original.id}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.DescriptionEN'}),
      accessor: 'name_en',
      Cell: ({row}) => <div className='w-100 flex-center'>{row.original.name_en}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.DescriptionAR'}),
      accessor: 'name_ar',
      Cell: ({row}) => <div className='w-100 flex-center'>{row.original.name_ar}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.Action'}),
      Cell: ({row}) => (
        <div className='d-flex flex-center'>
          {check.edit && (
            <Link
              to={`/Roles/Edit-PermissionGroups/${row.original.id}`}
              className='btn me-2 btn-light-primary'
            >
              {intl.formatMessage({id: 'Table.Edit'})}
            </Link>
          )}
          {check.delete && (
            <button
              onClick={() => deleteItem(row.original.id)}
              className='btn me-2 btn-light-danger'
            >
              {intl.formatMessage({id: 'Table.Delete'})}
            </button>
          )}
        </div>
      ),
    },
  ]

  useEffect(() => {
    setCheck({
      browse: check_role('browse-permissions-groups'),
      add: check_role('add-permissions-groups'),
      show: check_role('show-permissions-groups'),
      edit: check_role('edit-permissions-groups'),
      delete: check_role('delete-permissions-groups'),
    })

    if (!check_role('browse-categories')) {
      navigate('/')
    }
    getData()
  }, [])

  // Filter
  const filteredItems = items.filter(
    (item) =>
      item.name_en.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.name_ar.toLowerCase().includes(searchTerm.toLowerCase())
  )

  // Paginate
  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem)

  const renderCardView = () => (
    <div className='d-block d-md-none'>
      <div className='mb-3'>
        <div className='mb-3'>
          <CustomDiv />
        </div>
        <input
          type='text'
          className='form-control'
          placeholder={intl.formatMessage({id: 'Search.Input'})}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)} 
        />
      </div>
      {currentItems.map((item) => (
        <div key={item.id} className='card mb-3'>
          <div className='card-body'>
            <h5 className='card-title'># {item.id}</h5>
            <p className='card-text'>
              <strong>{intl.formatMessage({id: 'Table.DescriptionEN'})}: </strong>
              {item.name_en}
            </p>
            <p className='card-text'>
              <strong>{intl.formatMessage({id: 'Table.DescriptionAR'})}: </strong>
              {item.name_ar}
            </p>
            <div className='d-flex'>
              {check.edit && (
                <Link
                  to={`/Roles/Edit-PermissionGroups/${item.id}`}
                  className='btn me-2 btn-light-primary col-5'
                >
                  {intl.formatMessage({id: 'Table.Edit'})}
                </Link>
              )}
              {check.delete && (
                <button
                  onClick={() => deleteItem(item.id)}
                  className='btn me-2 btn-light-danger col-5'
                >
                  {intl.formatMessage({id: 'Table.Delete'})}
                </button>
              )}
            </div>
          </div>
        </div>
      ))}
      {/* Pagination */}
      <div className='mt-6 d-flex justify-content-center'>
        <Pagination
          count={Math.ceil(filteredItems.length / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color='primary'
        />
      </div>
    </div>
  )

  const handlePageChange = (event, page) => {
    setCurrentPage(page)
  }

  return (
    <motion.nav
      initial={{opacity: 0, x: 100}}
      animate={{opacity: 1, x: 0, transition: {duration: 0.3}}}
      exit={{opacity: 0, x: 100, transition: {duration: 0.2}}}
    >
      <div className='mb-5 mt-5 d-flex flex-stack'>
        <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
          <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
            {intl.formatMessage({id: 'Menu.PermissionGroups'})}
          </h1>
          <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
            <li className='breadcrumb-item text-muted'>{intl.formatMessage({id: 'Menu.Home'})}</li>
            <li className='breadcrumb-item'>
              <span className='bullet bg-gray-400 w-5px h-2px'></span>
            </li>
            <li className='breadcrumb-item text-muted'>{intl.formatMessage({id: 'Menu.Roles'})}</li>
          </ul>
        </div>
      </div>

      {!loading ? (
        <>
          {/* Table screens */}
          <div className='d-none d-md-block'>
            <ReactTable columns={columns} data={currentItems} customComponent={<CustomDiv />} />
          </div>

          {/* Card screens */}
          {renderCardView()}
        </>
      ) : (
        <div className='row'>
          <div className='col-12'>
            <Skeleton
              variant='rounded'
              animation='wave'
              sx={{width: '100%', height: '90vh', marginTop: '1vh'}}
            />
          </div>
        </div>
      )}
    </motion.nav>
  )
}

export default PermissionGroupsHome
