import React, {useEffect} from 'react'
import {Routes, Route, Navigate, useLocation} from 'react-router-dom'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {App} from '../App'
import Login from '../pages/Auth/Login'
import ForgotPassword from '../pages/Auth/ForgotPassword'
import ResetPassword from '../pages/Auth/ResetPassword'
import EmailVerification from '../pages/Auth/EmailVerification'
import GuestLayout from '../Layout/GuestLayout'
import Verification from '../Layout/Verification'
import AuthLayouy from '../Layout/AuthLayouy'
import DashboardWrapper from '../pages/dashboard/DashboardWrapper'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {AnimatePresence} from 'framer-motion'
import {useLang} from '../../_metronic/i18n/Metronici18n'
import BannersHome from '../pages/dashboard/WebDetails/Banners/BannersHome'
import {AddBanner} from '../pages/dashboard/WebDetails/Banners/AddBanner'
import {UpdateBanners} from '../pages/dashboard/WebDetails/Banners/UpdateBanners'
import HomeSctions from '../pages/dashboard/WebDetails/HomeSctions/HomeSctions'
import {AddHomeSection} from '../pages/dashboard/WebDetails/HomeSctions/AddHomeSection'
import {UpdateHomeSections} from '../pages/dashboard/WebDetails/HomeSctions/UpdateHomeSections'
import WebDetails from '../pages/dashboard/WebDetails/Webdetails/WebDetails'
import CountriesHome from '../pages/dashboard/Countries/CountriesHome'
import {AddCountries} from '../pages/dashboard/Countries/AddCountries'
import {UpdateCountries} from '../pages/dashboard/Countries/UpdateCountries'
import CurrenciesHome from '../pages/dashboard/Currencies/CurrenciesHome'
import {AddCurrencies} from '../pages/dashboard/Currencies/AddCurrencies'
import {UpdateCurrencies} from '../pages/dashboard/Currencies/UpdateCurrencies'
import Profile from '../pages/Auth/Profile'
import PaymentsHome from '../pages/dashboard/Payments/PaymentsHome'
import {AddPayments} from '../pages/dashboard/Payments/AddPayments'
import {UpdatePayments} from '../pages/dashboard/Payments/UpdatePayments'
import SuppliersHome from '../pages/dashboard/Suppliers/SuppliersHome'
import {AddSuppliers} from '../pages/dashboard/Suppliers/AddSuppliers'
import {UpdateSuppliers} from '../pages/dashboard/Suppliers/UpdateSuppliers'
import CategoriesHome from '../pages/dashboard/Categories/CategoriesHome'
import {AddCategories} from '../pages/dashboard/Categories/AddCategories'
import {UpdateCategories} from '../pages/dashboard/Categories/UpdateCategories'
import SupplerCategories from '../pages/dashboard/Suppliers/SupplerCategories/SupplerCategories'
import SupplerItems from '../pages/dashboard/Suppliers/SupplerCategories/SupplerItems'
import ProductsHome from '../pages/dashboard/Products/ProductsHome'
import {AddProducts} from '../pages/dashboard/Products/AddProducts'
import {UpdateProducts} from '../pages/dashboard/Products/UpdateProducts'
import Shortcomings from '../pages/dashboard/Shortcomings/Shortcomings'
import NewArrivalsHome from '../pages/dashboard/NewArrivals/NewArrivalsHome'
import {AddNewArrivals} from '../pages/dashboard/NewArrivals/AddNewArrivals'
import TopSellingHome from '../pages/dashboard/TopSelling/TopSellingHome'
import UsersHome from '../pages/dashboard/Users/UsersHome'
import {AddUsers} from '../pages/dashboard/Users/AddUsers'
import {UpdateUsers} from '../pages/dashboard/Users/UpdateUsers'
import OrderHome from '../pages/dashboard/Orders/OrderHome'
import {OrderDetails} from '../pages/dashboard/Orders/OrderDetails'
import SupplierReports from '../pages/dashboard/Suppliers/SupplerCategories/SupplierReports'
import HomeSliders from '../pages/dashboard/WebDetails/HomeSliders/HomeSliders'
import {AddHomeSliders} from '../pages/dashboard/WebDetails/HomeSliders/AddHomeSliders'
import {UpdateHomeSliders} from '../pages/dashboard/WebDetails/HomeSliders/UpdateHomeSliders'
import {AddDiscount} from '../pages/dashboard/Discount/AddDiscount'
import {UpdateDiscount} from '../pages/dashboard/Discount/UpdateDiscount'
import DiscountHome from '../pages/dashboard/Discount/DiscountHome'
import {MerchantProfile} from '../pages/dashboard/Merchants/MerchantProfile'
import {UpdateMerchantProducts} from '../pages/dashboard/Merchants/UpdateMerchantProducts'
import {AddMerchantProducts} from '../pages/dashboard/Merchants/AddMerchantProducts'
import {BitaqatyCategories} from '../pages/dashboard/Suppliers/Bitaqaty/BitaqatyCategories'
import {BitaqatyItems} from '../pages/dashboard/Suppliers/Bitaqaty/BitaqatyItems'
import {BitaqatyReport} from '../pages/dashboard/Suppliers/Bitaqaty/BitaqatyReport'
import BinanceProducts from '../pages/dashboard/Suppliers/Binance/BinanceProducts'
import {BinanceAddProducts} from '../pages/dashboard/Suppliers/Binance/BinanceAddProducts'
import {BinanceEditProducts} from '../pages/dashboard/Suppliers/Binance/BinanceEditProducts'
import BinanceReports from '../pages/dashboard/Suppliers/Binance/BinanceReports'
import LevelsHome from '../pages/dashboard/Levels/LevelCrud/LevelsHome'
import {AddLevels} from '../pages/dashboard/Levels/LevelCrud/AddLevels'
import {UpdateLevels} from '../pages/dashboard/Levels/LevelCrud/UpdateLevels'
import LevelRequestsHome from '../pages/dashboard/Levels/LevelRequests/LevelRequestsHome'
import {UpdateLevelRequests} from '../pages/dashboard/Levels/LevelRequests/UpdateLevelRequests'
import PendingOrdersHome from '../pages/dashboard/PendingOrders/PendingOrdersHome'
import {UpdatePendingOrders} from '../pages/dashboard/PendingOrders/UpdatePendingOrders'
import AuthContainer from '../pages/Auth/AuthContainer'
import ProductStocks from './../pages/dashboard/Stocks/ProductStocks'
import AddProductStok from '../pages/dashboard/Stocks/AddProductStok'
import RolesHome from '../pages/dashboard/Roles/Roles/RolesHome'
import {AddRoles} from '../pages/dashboard/Roles/Roles/AddRoles'
import {UpdateRoles} from '../pages/dashboard/Roles/Roles/UpdateRoles'
import PermissionsHome from '../pages/dashboard/Roles/Permission/PermissionsHome'
import {AddPermissions} from '../pages/dashboard/Roles/Permission/AddPermissions'
import {UpdatePermissions} from '../pages/dashboard/Roles/Permission/UpdatePermissions'
import PermissionGroupsHome from '../pages/dashboard/Roles/PermissionGroups/PermissionGroupsHome'
import {AddPermissionGroups} from '../pages/dashboard/Roles/PermissionGroups/AddPermissionGroups'
import {UpdatePermissionGroups} from '../pages/dashboard/Roles/PermissionGroups/UpdatePermissionGroups'
import RoleUsersHome from '../pages/dashboard/Roles/RoleUsers/RoleUsersHome'
import {AddRoleUsers} from '../pages/dashboard/Roles/RoleUsers/AddRoleUsers'
import {UpdateRoleUsers} from '../pages/dashboard/Roles/RoleUsers/UpdateRoleUsers'
import UserProfile from '../pages/dashboard/Users/UserProfile/UserProfile'
import BanHome from '../pages/dashboard/Ban/Bans/BanHome'
import {AddBans} from '../pages/dashboard/Ban/Bans/AddBans'
import {UpdateBan} from '../pages/dashboard/Ban/Bans/UpdateBan'
import BanUsersHome from '../pages/dashboard/Ban/UserBan/BanUsersHome'
import {AddBanUsers} from '../pages/dashboard/Ban/UserBan/AddBanUsers'
import {UpdateBanUsers} from '../pages/dashboard/Ban/UserBan/UpdateBanUsers'
import BansIp from '../pages/dashboard/Ban/Bans/BansIp/BansIp'
import AddIp from '../pages/dashboard/Ban/Bans/BansIp/AddBanIp'
import EditeIp from '../pages/dashboard/Ban/Bans/BansIp/EditeBanIp'
import BansDevice from '../pages/dashboard/Ban/Bans/BansDevice/BansDevice'
import EditeDevice from '../pages/dashboard/Ban/Bans/BansDevice/EditeBanDevice'
import AddDevice from '../pages/dashboard/Ban/Bans/BansDevice/AddBanDevice'
import {Signin} from '../pages/Auth/Auth_Pages/Signin'
import UserOtp from '../pages/Auth/Auth_Pages/UserOtp'
import {AddUsersCredit} from '../pages/dashboard/UsersCredit/AddUsersCredit'
import {UpdateUsersCredit} from '../pages/dashboard/UsersCredit/UpdateUsersCredit'
import UsersCreditHome from '../pages/dashboard/UsersCredit/UsersCreditHome'
import ConfigsHome from '../pages/dashboard/Configs/ConfigsHome'
import {AddConfigs} from '../pages/dashboard/Configs/AddConfigs'
import {UpdateConfigs} from '../pages/dashboard/Configs/UpdateConfigs'
import IntegrationsHome from '../pages/dashboard/Integrations/IntegrationsHome'
import {AddIntegrations} from '../pages/dashboard/Integrations/AddIntegrations'
import {UpdateIntegrations} from '../pages/dashboard/Integrations/UpdateIntegrations'
import CouponHome from '../pages/dashboard/Coupon/CouponHome'
import AddCoupon from '../pages/dashboard/Coupon/AddCoupon'
import UpdateCoupon from '../pages/dashboard/Coupon/UpdateCoupon'
import AnalyticsHome from '../pages/dashboard/Analytics/AnalyticsHome'

const AppRoutes = () => {
  const location = useLocation()

  const lang = useLang()
  useEffect(() => {
    if (lang === 'ar') {
      require('../../_metronic/assets/style/style_rtl.css')
      console.log('ar_ar')
    } else {
      require('../../_metronic/assets/style/style.css')
      console.log('en_en')
    }
  }, [location])

  function changeFavicon(newIconUrl) {
    var oldIcon = document.querySelector('link[rel="icon"]')
    if (oldIcon) {
      oldIcon.parentNode.removeChild(oldIcon)
    }

    var newIcon = document.createElement('link')
    newIcon.rel = 'icon'
    newIcon.href = newIconUrl

    document.head.appendChild(newIcon)
  }

  const img = process.env.REACT_APP_IMAGE_PATH + localStorage.getItem('web_img')
  useEffect(() => {
    changeFavicon(img)
    document.title = localStorage.getItem('web_name')
    document.description = localStorage.getItem('web_name')
  }, [])

  return (
    <AnimatePresence mode='wait' initial={false}>
      <Routes location={location} key={location.pathname}>
        <Route element={<App />}>
          <Route path='*' element={<Navigate to='/dashboard' />} />
          <Route path='error/*' element={<ErrorsPage />} />

          <Route element={<GuestLayout />}>
            <Route element={<AuthContainer />}>
              <Route path='/login' element={<Signin />} />
              <Route path='/user-otp' element={<UserOtp />} />
              <Route path='/forgot-password' element={<ForgotPassword />} />
              <Route path='/password-reset/:token' element={<ResetPassword />} />
            </Route>
          </Route>

          <Route element={<Verification />}>
            <Route path='/Email-Verification' element={<EmailVerification />} />
          </Route>

          <Route element={<AuthLayouy />}>
            <Route element={<MasterLayout />}>
              <Route path='dashboard' element={<DashboardWrapper />} />
              <Route path='profile' element={<Profile />} />

              {/* WebDetails */}
              <Route path='WebDetails/Banners' element={<BannersHome />} />
              <Route path='WebDetails/Add-Banners' element={<AddBanner />} />
              <Route path='WebDetails/Edit-Banners/:id' element={<UpdateBanners />} />

              <Route path='WebDetails/HomeSections' element={<HomeSctions />} />
              <Route path='WebDetails/Add-HomeSections' element={<AddHomeSection />} />
              <Route path='WebDetails/Edit-HomeSections/:id' element={<UpdateHomeSections />} />

              <Route path='WebDetails/HomeSliders' element={<HomeSliders />} />
              <Route path='WebDetails/Add-HomeSliders' element={<AddHomeSliders />} />
              <Route path='WebDetails/Edit-HomeSliders/:id' element={<UpdateHomeSliders />} />

              <Route path='WebDetails/WebSiteDetails' element={<WebDetails />} />

              {/* Users */}
              <Route path='Users/Users' element={<UsersHome />} />
              <Route path='Users/Add-Users' element={<AddUsers />} />
              <Route path='Users/Edit-Users/:id' element={<UserProfile />} />
              <Route path='Users/Edit-User-Discounts/:id' element={<MerchantProfile />} />

              {/* Merchant */}
              <Route path='Add-Merchant-Products/:id' element={<AddMerchantProducts />} />
              <Route path='Edit-Merchant-Products/:id' element={<UpdateMerchantProducts />} />

              {/* Countries */}
              <Route path='Countries' element={<CountriesHome />} />
              <Route path='Add-Countries' element={<AddCountries />} />
              <Route path='Edit-Countries/:id' element={<UpdateCountries />} />

              {/* Currencies */}
              <Route path='Currencies' element={<CurrenciesHome />} />
              <Route path='Add-Currencies' element={<AddCurrencies />} />
              <Route path='Edit-Currencies/:id' element={<UpdateCurrencies />} />

              {/* Suppliers */}
              <Route path='Suppliers' element={<SuppliersHome />} />
              <Route path='Suppliers/get-Categories/:id' element={<SupplerCategories />} />
              <Route path='Suppliers/get-Products/:type/:id' element={<SupplerItems />} />
              <Route path='Suppliers/Reports/:type' element={<SupplierReports />} />
              <Route path='Add-Suppliers' element={<AddSuppliers />} />
              <Route path='Edit-Suppliers/:id' element={<UpdateSuppliers />} />

              {/* Bitaqaty */}
              <Route
                path='Suppliers/bitaqaty/get-Categories/:id'
                element={<BitaqatyCategories />}
              />
              <Route path='Suppliers/bitaqaty/get-Products/:type/:id' element={<BitaqatyItems />} />
              <Route path='Suppliers/bitaqaty/Reports/:type' element={<BitaqatyReport />} />

              {/* Binance */}
              <Route path='Suppliers/binance/get-Categories/:id' element={<BinanceProducts />} />
              <Route path='Suppliers/binance/add-Products' element={<BinanceAddProducts />} />
              <Route path='Suppliers/binance/Edit-Products/:id' element={<BinanceEditProducts />} />
              <Route path='Suppliers/binance/Reports/:type' element={<BinanceReports />} />

              {/* Categories */}
              <Route path='Categories' element={<CategoriesHome />} />
              <Route path='Add-Categories' element={<AddCategories />} />
              <Route path='Edit-Categories/:id' element={<UpdateCategories />} />

              {/* Products */}
              <Route path='Products/Products' element={<ProductsHome />} />
              <Route path='Products/Add-Products' element={<AddProducts />} />
              <Route path='Products/Edit-Products/:id' element={<UpdateProducts />} />

              {/* Products Stocks */}
              <Route path='Stocks/Check-Stock' element={<ProductStocks />} />
              <Route path='Stocks/Add-Product-Stocks' element={<AddProductStok />} />

              {/* NewArrivals */}
              <Route path='Products/NewArrivals' element={<NewArrivalsHome />} />

              {/* TopSelling */}
              <Route path='Products/TopSelling' element={<TopSellingHome />} />

              {/* Shortcomings */}
              <Route path='Products/Shortcomings' element={<Shortcomings />} />

              {/* Orders */}
              <Route path='Orders' element={<OrderHome />} />
              <Route path='Order-details/:id' element={<OrderDetails />} />

              {/* PendingOrders */}
              <Route path='PendingOrders' element={<PendingOrdersHome />} />
              <Route path='PendingOrders-details/:id' element={<UpdatePendingOrders />} />

              {/* Discounts */}
              <Route path='Discounts/Discounts' element={<DiscountHome />} />
              <Route path='Discounts/Add-Discounts' element={<AddDiscount />} />
              <Route path='Discounts/Edit-Discounts/:id' element={<UpdateDiscount />} />

              {/* Levels */}
              <Route path='Levels/Levels' element={<LevelsHome />} />
              <Route path='Levels/Add-Levels' element={<AddLevels />} />
              <Route path='Levels/Edit-Levels/:id' element={<UpdateLevels />} />

              {/* Levels */}
              <Route path='Levels/Users-Requests' element={<LevelRequestsHome />} />
              <Route path='Levels/Edit-Levels-Requests/:id' element={<UpdateLevelRequests />} />

              {/* Roles */}
              <Route path='Roles/Roles' element={<RolesHome />} />
              <Route path='Roles/Add-Roles' element={<AddRoles />} />
              <Route path='Roles/Edit-Roles/:id' element={<UpdateRoles />} />

              <Route path='Roles/Permissions' element={<PermissionsHome />} />
              <Route path='Roles/Add-Permissions' element={<AddPermissions />} />
              <Route path='Roles/Edit-Permissions/:id' element={<UpdatePermissions />} />

              <Route path='Roles/PermissionGroups' element={<PermissionGroupsHome />} />
              <Route path='Roles/Add-PermissionGroups' element={<AddPermissionGroups />} />
              <Route path='Roles/Edit-PermissionGroups/:id' element={<UpdatePermissionGroups />} />

              <Route path='Roles/Users' element={<RoleUsersHome />} />
              <Route path='Roles/Add-Users' element={<AddRoleUsers />} />
              <Route path='Roles/Edit-Users/:id' element={<UpdateRoleUsers />} />

              {/* Bans */}
              <Route path='/Bans/Bans' element={<BanHome />} />
              <Route path='/Bans/Add-Bans' element={<AddBans />} />
              <Route path='/Bans/Edit-Bans/:id' element={<UpdateBan />} />

              {/* Users Bans */}
              <Route path='/Bans/Users-Bans' element={<BanUsersHome />} />
              <Route path='/Bans/add-Ban-Users' element={<AddBanUsers />} />
              <Route path='/Bans/Edit-Ban-Users/:id' element={<UpdateBanUsers />} />

              <Route path='/Bans-Ip' element={<BansIp />} />
              <Route path='/Bans-Add-Ip' element={<AddIp />} />
              <Route path='/Bans-Edite-Ip/:id' element={<EditeIp />} />

              <Route path='/Bans-Device' element={<BansDevice />} />
              <Route path='/Bans-Add-Device' element={<AddDevice />} />
              <Route path='/Bans-Edite-Device/:id' element={<EditeDevice />} />

              {/* Users Credit */}
              <Route path='Users-Credit' element={<UsersCreditHome />} />
              <Route path='Users/Add-Users-Credit' element={<AddUsersCredit />} />
              <Route path='Users/Edit-Users-Credit/:id' element={<UpdateUsersCredit />} />

              {/* integrations */}
              <Route path='/Developers/Integrations' element={<IntegrationsHome />} />
              <Route path='/Developers/Add-Integrations' element={<AddIntegrations />} />
              <Route path='/Developers/Edit-Integrations/:id' element={<UpdateIntegrations />} />

              {/* Payments */}
              <Route path='/Developers/Payments' element={<PaymentsHome />} />
              <Route path='/Developers/Add-Payments' element={<AddPayments />} />
              <Route path='/Developers/Edit-Payments/:id' element={<UpdatePayments />} />

              {/* Configs */}
              <Route path='/Developers/Configs' element={<ConfigsHome />} />
              <Route path='/Developers/Add-Configs' element={<AddConfigs />} />
              <Route path='/Developers/Edit-Configs/:id' element={<UpdateConfigs />} />

              {/* Coupons */}
              <Route path='Discounts/Coupons' element={<CouponHome />} />
              <Route path='Discounts/Add-Coupons' element={<AddCoupon />} />
              <Route path='Discounts/Edit-Coupons/:id' element={<UpdateCoupon />} />


              <Route path='Analytics' element={<AnalyticsHome />} />

            </Route>
          </Route>
        </Route>
      </Routes>
    </AnimatePresence>
    //admin-countries
  )
}

export default AppRoutes
