import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import axiosInstance from '../../../api/axios'
import Swal from 'sweetalert2'
import {KTSVG} from '../../../../_metronic/helpers'
import {Link, useNavigate} from 'react-router-dom'
import {motion} from 'framer-motion'
import ReactTable from '../../../../_metronic/partials/widgets/React_Table/ReactTable'
import {Skeleton, Pagination} from '@mui/material'
import useAuthContext from '../../../Auth/AuthContext'

const CouponHome = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [Items, setItems] = useState(null)
  const [searchTerm, setSearchTerm] = useState('') // Search term state

  const {check_role} = useAuthContext()
  const navigate = useNavigate()

  const [check, setCheck] = useState({
    browse: false,
    add: false,
    show: false,
    edit: false,
    delete: false,
  })

  const [currentPage, setCurrentPage] = useState(1) // State for current page
  const itemsPerPage = 10 // Set the number of items per page

  const CustomDiv = () => (
    <div className='row flex-end w-100'>
      {check.add && (
        <div className='col-auto'>
          <Link to='/Discounts/Add-Coupons' type='button' className='btn btn-primary'>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            {intl.formatMessage({id: 'Coupon.Create'})}
          </Link>
        </div>
      )}
    </div>
  )

  const get_data = async () => {
    setLoading(true)
    const {data} = await axiosInstance.get('/admin-coupons')
    setItems(data)
    setLoading(false)
  }

  const Delete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const {data} = await axiosInstance.delete(`/admin-coupons/${id}`)
          if (!data || data.length === 0) {
            throw new Error(JSON.stringify(data))
          }
          get_data()
        } catch (error) {
          Swal.showValidationMessage(`Request failed: ${error}`)
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Done!',
          icon: 'success',
        })
      }
    })
  }

  const edit_status = async (value, id) => {
    try {
      await axiosInstance.get(`/change-coupons-status/${id}/${value}`)
      get_data()
    } catch (error) {
      console.error(error)
    }
  }

  const columns = [
    {
      Header: intl.formatMessage({id: 'Table.ID'}),
      accessor: 'id',
      Cell: (row) => <div className=' w-100 flex-center'># {row.row.original.id}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.Name'}),
      accessor: 'name',
      Cell: (row) => <div className=' w-100 flex-center'>{row.row.original.name}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.Type'}),
      accessor: 'type',
      Cell: (row) => <div className=' w-100 flex-center'>{row.row.original.type === 'fixed' ? 'percentage %' : 'direct discount $'}</div>,
    },
    {
      Header: 'enable',
      accessor: 'status',
      Cell: (row) => (
        <div className='form-check mt-2 form-switch form-switch-sm form-check-custom form-check-solid flex-center'>
          {check.edit && (
            <input
              className='form-check-input'
              type='checkbox'
              defaultChecked={row.row.original.status}
              onClick={() => edit_status(!row.row.original.status, row.row.original.id)}
            />
          )}
        </div>
      ),
    },
    {
      Header: intl.formatMessage({id: 'Table.Action'}),
      Cell: (row) => (
        <div className='d-flex flex-center'>
          {check.edit && (
            <Link
              to={`/Discounts/Edit-Coupons/${row.row.original.id}`}
              className='btn me-2  btn-light-primary'
            >
              {intl.formatMessage({id: 'Table.Edit'})}
            </Link>
          )}
          {check.delete && (
            <button
              onClick={() => Delete(row.row.original.id)}
              className='btn me-2  btn-light-danger'
            >
              {intl.formatMessage({id: 'Table.Delete'})}
            </button>
          )}
        </div>
      ),
    },
  ]

  useEffect(() => {
    setCheck({
      browse: check_role('browse-coupons'),
      add: check_role('add-coupons'),
      show: check_role('show-coupons'),
      edit: check_role('edit-coupons'),
      delete: check_role('delete-coupons'),
    })

    if (!check_role('browse-coupons')) {
      navigate('/')
    }
    get_data()
  }, [])

  // Filter items based on the search term
  const filteredItems = Items?.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  )

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = filteredItems?.slice(indexOfFirstItem, indexOfLastItem)

  const handlePageChange = (event, value) => {
    setCurrentPage(value)
  }

  return (
    <motion.nav
      initial={{opacity: 0, x: 100}}
      animate={{opacity: 1, x: 0, transition: {duration: 0.3}}}
      exit={{opacity: 0, x: 100, transition: {duration: 0.2}}}
    >
      <div className='mb-5 mt-5 d-flex flex-stack'>{/* Page title remains the same */}</div>

      {/* Table for large screens */}
      <div className='d-none d-md-block'>
        {Items !== null && !loading ? (
          <ReactTable columns={columns} data={Items} customComponent={<CustomDiv />} />
        ) : (
          <Skeleton
            variant='rounded'
            animation='wave'
            sx={{width: '100%', height: '90vh', marginTop: '1vh'}}
          />
        )}
      </div>

      {/* Card View for small screens */}
      <div className='d-block d-md-none'>
        {/* Search input for card view */}
        <div className='mb-3'>
          <div className='mb-3'>
            <CustomDiv />
          </div>
          <input
            type='text'
            className='form-control'
            placeholder={intl.formatMessage({id: 'Search.Input'})}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        {currentItems && currentItems.length ? (
          <>
            {currentItems.map((item) => (
              <div className='card mb-3' key={item.id}>
                <div className='card-body'>
                  <h5 className='card-title'>ID: #{item.id}</h5>
                  <p className='card-text'>Name: {item.name}</p>
                  <p className='card-text'>Type: {item.type === 'fixed' ? 'percentage %' : 'direct discount $'}</p>
                  <div className='form-check form-switch'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      defaultChecked={item.status}
                      onClick={() => edit_status(!item.status, item.id)}
                    />
                    <label className='form-check-label'>
                      {item.status ? 'Enabled' : 'Disabled'}
                    </label>
                  </div>
                  <div className='d-flex gap-4 mt-6'>
                    {check.edit && (
                      <Link
                        to={`/Discounts/Edit-Coupons/${item.id}`}
                        className='btn btn-primary col-5'
                      >
                        {intl.formatMessage({id: 'Table.Edit'})}
                      </Link>
                    )}
                    {check.delete && (
                      <button onClick={() => Delete(item.id)} className='btn btn-danger col-5'>
                        {intl.formatMessage({id: 'Table.Delete'})}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ))}

            {/* Pagination for card view */}
            <div className='mt-6 d-flex justify-content-center'>
              <Pagination
                count={Math.ceil(filteredItems.length / itemsPerPage) || 1} // Ensure count is at least 1
                page={currentPage}
                onChange={handlePageChange}
                color='primary'
              />
            </div>
          </>
        ) : (
          <Skeleton
            variant='rounded'
            animation='wave'
            sx={{width: '100%', height: '90vh', marginTop: '1vh'}}
          />
        )}
      </div>
    </motion.nav>
  )
}

export default CouponHome
