import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import axiosInstance from '../../../api/axios'
import Swal from 'sweetalert2'
import {KTSVG} from '../../../../_metronic/helpers'
import {Link, useNavigate} from 'react-router-dom'
import {motion} from 'framer-motion'
import ReactTable from '../../../../_metronic/partials/widgets/React_Table/ReactTable'
import {Skeleton, Pagination} from '@mui/material'
import useAuthContext from '../../../Auth/AuthContext'

const PaymentsHome = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [Items, setItems] = useState(null)
  const [filteredItems, setFilteredItems] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')

  const {check_role} = useAuthContext()
  const navigate = useNavigate()

  const [check, setCheck] = useState({
    browse: false,
    add: false,
    show: false,
    edit: false,
    delete: false,
  })

  // Pagination state and logic
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage] = useState(10)

  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = filteredItems ? filteredItems.slice(indexOfFirstItem, indexOfLastItem) : []

  const handlePageChange = (event, value) => {
    setCurrentPage(value)
  }

  const CustomDiv = () => (
    <div className='row flex-end w-100'>
      {check.add && (
        <div className='col-auto'>
          <Link to='/Developers/Add-Payments' type='button' className='btn btn-primary'>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            {intl.formatMessage({id: 'Payments.Create'})}
          </Link>
        </div>
      )}
    </div>
  )

  const get_data = async () => {
    setLoading(true)
    const {data} = await axiosInstance.get('/admin-payments')
    setItems(data)
    setFilteredItems(data)
    setLoading(false)
  }

  const edit_status = async (value, id) => {
    let endpoint = `/change-payments-status/${id}/${value}`
    try {
      await axiosInstance.get(endpoint)
      get_data()
    } catch (error) {
      console.error(error)
    }
  }

  const Delete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const {data} = await axiosInstance.delete(`/admin-payments/${id}`)
          if (!data || data.length === 0) {
            throw new Error(JSON.stringify(data))
          }
          get_data()
        } catch (error) {
          Swal.showValidationMessage(`Request failed: ${error}`)
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Done!',
          icon: 'success',
        })
      }
    })
  }

  const handleSearch = (e) => {
    setSearchTerm(e.target.value)
    if (e.target.value === '') {
      setFilteredItems(Items)
    } else {
      const filtered = Items.filter(
        (item) =>
          item.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
          item.id.toString().includes(e.target.value)
      )
      setFilteredItems(filtered)
    }
  }

  const columns = [
    {Header: intl.formatMessage({id: 'Table.ID'}), accessor: 'id'},
    {Header: intl.formatMessage({id: 'Table.Name'}), accessor: 'name'},
    {
      Header: 'Enable',
      accessor: 'status',
      Cell: ({row}) => (
        <div className='form-check mt-2 form-switch form-switch-sm form-check-custom form-check-solid flex-center'>
          {check.edit && (
            <input
              className='form-check-input'
              type='checkbox'
              defaultChecked={row.original.status}
              onClick={() => edit_status(!row.original.status, row.original.id)}
            />
          )}
        </div>
      ),
    },
    {
      Header: intl.formatMessage({id: 'Table.Action'}),
      Cell: ({row}) => (
        <div className='d-flex flex-center'>
          {check.edit && (
            <Link
              to={`/Developers/Edit-Payments/${row.original.id}`}
              className='btn me-2  btn-light-primary'
            >
              {intl.formatMessage({id: 'Table.Edit'})}
            </Link>
          )}
          {check.delete && (
            <button onClick={() => Delete(row.original.id)} className='btn me-2  btn-light-danger'>
              {intl.formatMessage({id: 'Table.Delete'})}
            </button>
          )}
        </div>
      ),
    },
  ]

  useEffect(() => {
    setCheck({
      browse: check_role('browse-payments'),
      add: check_role('add-payments'),
      show: check_role('show-payments'),
      edit: check_role('edit-payments'),
      delete: check_role('delete-payments'),
    })

    if (!check_role('browse-payments')) {
      navigate('/')
    }
    get_data()
  }, [])

  return (
    <motion.nav
      initial={{opacity: 0, x: 100}}
      animate={{opacity: 1, x: 0, transition: {duration: 0.3}}}
      exit={{opacity: 0, x: 100, transition: {duration: 0.2}}}
    >
      <div className='mb-5 mt-5 d-flex flex-stack'>
        <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
          <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
            {intl.formatMessage({id: 'Menu.Payments'})}
          </h1>
        </div>
      </div>

      {/* Table for large screens */}
      <div className='d-none d-lg-block'>
        {Items !== null && !loading ? (
          <ReactTable columns={columns} data={Items} customComponent={<CustomDiv />} />
        ) : (
          <Skeleton
            variant='rounded'
            animation='wave'
            sx={{width: '100%', height: '90vh', marginTop: '1vh'}}
          />
        )}
      </div>

      {/* Card view for small screens */}
      <div className='d-block d-lg-none'>
        {/* Search input for card view */}
        <div className='mb-3'>
          <div className='mb-3'>
            <CustomDiv />
          </div>
          <input
            type='text'
            className='form-control'
            placeholder={intl.formatMessage({id: 'Search.Input'})}
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>

        {currentItems !== null && !loading ? (
          currentItems.map((item) => (
            <div className='card mb-3' key={item.id}>
              <div className='card-body'>
                <h5 className='card-title'># {item.id}</h5>
                <p className='card-text'>{item.name}</p>
                <div className='form-check mt-2 form-switch form-switch-sm form-check-custom form-check-solid'>
                  {check.edit && (
                    <input
                      className='form-check-input'
                      type='checkbox'
                      defaultChecked={item.status}
                      onClick={() => edit_status(!item.status, item.id)}
                    />
                  )}
                </div>
                <div className='mt-3'>
                  {check.edit && (
                    <Link
                      to={`/Developers/Edit-Payments/${item.id}`}
                      className='btn btn-light-primary'
                    >
                      {intl.formatMessage({id: 'Table.Edit'})}
                    </Link>
                  )}
                  {check.delete && (
                    <button onClick={() => Delete(item.id)} className='btn btn-light-danger'>
                      {intl.formatMessage({id: 'Table.Delete'})}
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))
        ) : (
          <Skeleton
            variant='rounded'
            animation='wave'
            sx={{width: '100%', height: '90vh', marginTop: '1vh'}}
          />
        )}

        {/* Pagination for card view */}
        <div className='mt-6 d-flex justify-content-center'>
          <Pagination
            count={Math.ceil(filteredItems?.length / itemsPerPage) || 0}
            page={currentPage}
            onChange={handlePageChange}
            color='primary'
          />
        </div>
      </div>
    </motion.nav>
  )
}

export default PaymentsHome
