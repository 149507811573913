import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../../../api/axios';
import Swal from 'sweetalert2';
import { motion } from 'framer-motion';
import { KTSVG } from '../../../../../_metronic/helpers';
import ReactTable from '../../../../../_metronic/partials/widgets/React_Table/ReactTable';
import UsersAutoComplete from '../../../../../_metronic/partials/widgets/AutoComplete/UsersAutoComplete';
import useAuthContext from '../../../../Auth/AuthContext';

export const UpdateRoleUsers = () => {
    const intl = useIntl()
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState([]);
    const [fileName, setFileName] = useState("No selected file")
    const navigate = useNavigate();
    const [imported, setImported] = useState([]);
    const [Items, setItems] = useState([]);
    const { id } = useParams()
    const [user_value, setUSER_Value] = useState(null);
    const { check_role } = useAuthContext()



    const handleEdit_Add_submit = async (event) => {
        setLoading(true)
        event.preventDefault()
        const formData = new FormData()
        formData.append('enctype', 'multipart/form-data');
        formData.append('data', JSON.stringify(imported));
        formData.append('user_id', user_value.id);
        formData.append('_method', 'Patch');

        try {
            await axiosInstance.post(`/admin-role-users/${id}`, formData).then(response => {
                Swal.fire({
                    title: "Success",
                    icon: "success",
                    confirmButtonText: "OK",
                });
                setLoading(false)
                setErrors([])
                navigate(-1)
            })
        } catch (e) {
            console.log(e.response.data.errors);
            if (e.response.status === 422) {
                setLoading(false)
                setErrors(e.response.data.errors)
            }
        }
    }

    // const get_data = () => {
    //     setItems([])
    //     axiosInstance.get('/admin-roles').then(response => {
    //         setItems(response.data)
    //     })
    // }

    const get_data = async () => {
        setItems([])
        const [getData, get_Roles] = await Promise.all([
            axiosInstance.get(`/admin-role-users/${id}`),
            axiosInstance.get('/admin-roles')
        ]);

        const get_Date = getData.data;
        setUSER_Value(get_Date);

        if (get_Date.roles) {
            const allItems = get_Date.roles.map(item => ({
                id: item.id,
                name_en: item.name_en,
                name_ar: item.name_ar,
            }));
            setImported(allItems);
        }

        setItems(prevItems => [
            ...prevItems,
            ...get_Roles.data,
        ]);

    };


    const add_item = (item) => {
        const rowsInput = {
            id: item.id,
            name_en: item.name_en,
            name_ar: item.name_ar,
        };
        setImported((prevImported) => [...prevImported, rowsInput]);
    };

    const remove_item = (item) => {
        setImported((prevImported) => prevImported.filter(importedItem => importedItem.id !== item.id));
    };


    const handle_change = (e, item) => {
        if (e.target.checked) {
            add_item(item);
        } else {
            remove_item(item);
        }
    };

    const isChecked = (id) => {
        return imported.some(importedItem => importedItem.id === id);
    };

    const columns = [
        {
            Header: intl.formatMessage({ id: 'Table.ID' }), accessor: 'productID',
            Cell: row => (
                <div className="w-100 flex-center">
                    <div className='form-check form-check-sm form-check-custom form-check-solid flex-center'>
                        <input
                            className='form-check-input widget-9-check me-2'
                            onChange={(e) => { handle_change(e, row.row.original) }}
                            type='checkbox'
                            checked={isChecked(row.row.original.id)}
                        />
                        # {row.row.original.id}
                    </div>
                </div>
            )
        },
        {
            Header: intl.formatMessage({ id: 'Table.DescriptionEN' }),
            accessor: 'name_en',
            Cell: ({ row }) => (
                <div className="w-100 flex-center">
                    {row.original.name_en}
                </div>
            )
        },
        {
            Header: intl.formatMessage({ id: 'Table.DescriptionAR' }),
            accessor: 'name_ar',
            Cell: ({ row }) => (
                <div className="w-100 flex-center">
                    {row.original.name_ar}
                </div>
            )
        },
    ];
    useEffect(() => {


        if (!check_role('show-users-roles')) {
            navigate('/')
        }
        get_data();
    }, [])

    return (
        <motion.nav
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0, transition: { duration: 0.3 } }}
            exit={{ opacity: 0, x: 100, transition: { duration: 0.2 } }} >
            <div className=" mb-5 mt-5 d-flex flex-stack">
                <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">{intl.formatMessage({ id: 'Roles.Edit' })}</h1>
                    <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                        <li className="breadcrumb-item text-muted">
                            <span className="text-muted text-hover-primary"> {intl.formatMessage({ id: 'Menu.Home' })}</span>
                        </li>
                        <li className="breadcrumb-item">
                            <span className="bullet bg-gray-400 w-5px h-2px"></span>
                        </li>
                        <li className="breadcrumb-item text-muted">{intl.formatMessage({ id: 'Menu.Roles' })}</li>
                    </ul>
                </div>
                <div>
                    <button onClick={(e) => navigate(-1)} type='button' className='btn btn-primary' >
                        <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon-2' />
                        {intl.formatMessage({ id: 'Form.GoBack' })}
                    </button>
                </div>
            </div>
            <div id="kt_app_content_container" className="app-container container-xxl">
                <form onSubmit={handleEdit_Add_submit} id="kt_ecommerce_add_category_form" className="form d-flex flex-column flex-lg-row fv-plugins-bootstrap5 fv-plugins-framework" data-kt-redirect="../../demo1/dist/apps/ecommerce/catalog/categories.html">
                    <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
                        <div className="card card-flush py-4">
                            <div className="card-header">
                                <div className="card-title">
                                    <h2>{intl.formatMessage({ id: 'Form.GeneralINFO' })} </h2>
                                </div>
                            </div>

                            <div className="card-body pt-0">
                                {user_value ?
                                    <div className="row flex-between">
                                        <div className="mb-3 col-12 fv-row fv-plugins-icon-container">
                                            <div className="card my-4">
                                                <div className='card-body p-9'>
                                                    <div className='row mb-7'>
                                                        <label className='col-lg-4 fw-bold text-muted'>{intl.formatMessage({ id: 'Table.Id' })}</label>
                                                        <div className='col-lg-8'>
                                                            <span className='fw-bolder fs-6 text-dark'>{user_value.id}</span>
                                                        </div>
                                                    </div>
                                                    <div className='row mb-7'>
                                                        <label className='col-lg-4 fw-bold text-muted'>{intl.formatMessage({ id: 'Table.User' })}</label>
                                                        <div className='col-lg-8'>
                                                            <span className='fw-bolder fs-6 text-dark'>{user_value.name}</span>
                                                        </div>
                                                    </div>
                                                    <div className='row mb-7'>
                                                        <label className='col-lg-4 fw-bold text-muted'>{intl.formatMessage({ id: 'Table.Email' })}</label>
                                                        <div className='col-lg-8'>
                                                            <span className='fw-bolder fs-6 text-dark'>{user_value.email}</span>
                                                        </div>
                                                    </div>
                                                    <div className='row mb-7'>
                                                        <label className='col-lg-4 fw-bold text-muted'>{intl.formatMessage({ id: 'Table.Phone' })}</label>
                                                        <div className='col-lg-8'>
                                                            <span className='fw-bolder fs-6 text-dark'>{user_value.phone}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div>Loading</div>
                                }

                            </div>
                        </div>
                        <div className="card my-2">
                            <ReactTable columns={columns} data={Items} customComponent={<></>} />
                        </div>
                        <div className="d-flex justify-content-end ">
                            {check_role('edit-users-roles') && (
                                <button type="submit" className="btn btn-primary">
                                    {!loading && <span className='indicator-label'>{intl.formatMessage({ id: 'Form.Edit' })} </span>}
                                    {loading && (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            {intl.formatMessage({ id: 'Form.Pleasewait' })}{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </button>
                            )}

                        </div>
                    </div>
                </form>
            </div>

        </motion.nav>
    )
}
