import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Images } from '../../../../_metronic/partials/widgets/React_Table/Images'
import ReactTable from '../../../../_metronic/partials/widgets/React_Table/ReactTable'
import { Skeleton } from '@mui/material'
import { useIntl } from 'react-intl'
import useAuthContext from '../../../Auth/AuthContext'
import moment from 'moment'

export const TopUsersByOrders = ({ data }) => {
    const [loading, setLoading] = useState(true)
    const intl = useIntl()
    const { formatNumber } = useAuthContext()
    const columns = [
        {
            Header: intl.formatMessage({ id: 'Table.User' }),
            accessor: (row) => `${row.user?.name} ${row.user?.id}`,
            Cell: (row) =>
                row.row.original.user && (
                    <Link to={`/Users/Edit-Users/${row.row.original.user.id}`}>
                        <div className='d-flex align-items-center ps-3'>
                            <Images img={row.row.original.user.img} name={row.row.original.user.name} />
                            <div className='flex-grow-1'>
                                <div className='text-dark fw-bold text-hover-primary text-start fs-6'>
                                    {row.row.original.user.name}
                                </div>
                                <span className='text-muted d-block fw-semibold text-start'>
                                    #{row.row.original.user.id}
                                </span>
                            </div>
                        </div>
                    </Link>
                ),
            // Define a custom filter function
            filter: (rows, id, filterValue) => {
                return rows.filter((row) => {
                    const user = row.values[id] // id will be 'user' which is the accessor
                    return user.toLowerCase().includes(filterValue.toLowerCase()) // Search on concatenated string
                })
            },
        },
        {
            Header: intl.formatMessage({ id: 'Table.OrderCount' }),
            accessor: 'order_count',
            Cell: ({ row }) => <div className='w-100 flex-center'>{row.original.order_count}</div>,
        },
        {
            Header: intl.formatMessage({ id: 'Table.Total' }),
            accessor: 'total_sum',
            Cell: ({ row }) => <div className='w-100 flex-center'>{formatNumber(row.original.total_sum)}</div>,
        },
        {
            Header: intl.formatMessage({ id: 'Table.created_at' }),
            accessor: 'created_at',
            Cell: ({ row }) => <div className='w-100 flex-center'> {moment(row.original.created_at).format("YYYY-MM-DD hh:mm:ss A")} </div>,
        },
    ]
    useEffect(() => {
        if (data) {
            setLoading(false)
        }
    }, [data])
    console.log('loading', loading);
    console.log('data',data);
    return data !== null && !loading ?
        <ReactTable pageSize={5} columns={columns} data={data} customComponent={null} />
        :
        <div className="row">
            <div className="col-12">
                <Skeleton variant="rounded" animation="wave" sx={{ width: '100%', height: '90vh', marginTop: '1vh' }} />
            </div>
        </div>

}
