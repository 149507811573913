import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import axiosInstance from '../../../../api/axios'
import Swal from 'sweetalert2'
import { KTSVG, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { Link, useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'
import ReactTable from '../../../../../_metronic/partials/widgets/React_Table/ReactTable'
import { Skeleton, Pagination } from '@mui/material'
import useAuthContext from '../../../../Auth/AuthContext'

const LevelsHome = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState([])
  const [search, setSearch] = useState('')
  const { check_role } = useAuthContext()
  const navigate = useNavigate()

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 5

  const [check, setCheck] = useState({
    browse: false,
    add: false,
    show: false,
    edit: false,
    delete: false,
  })

  const CustomDiv = () => (
    <div className='row flex-end w-100'>
      {check.add && (
        <div className='col-auto'>
          <Link to='/Levels/Add-Levels' type='button' className='btn btn-primary'>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            {intl.formatMessage({ id: 'Level.Create' })}
          </Link>
        </div>
      )}
    </div>
  )

  const getData = async () => {
    setLoading(true)
    try {
      const { data } = await axiosInstance.get('/admin-levels')
      setItems(data)
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false)
    }
  }

  const deleteItem = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const { data } = await axiosInstance.delete(`/admin-levels/${id}`)
          if (!data || data.length === 0) {
            throw new Error(JSON.stringify(data))
          }
          getData()
        } catch (error) {
          Swal.showValidationMessage(`Request failed: ${error}`)
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Done!',
          icon: 'success',
        })
      }
    })
  }

  const columns = [
    {
      Header: intl.formatMessage({ id: 'Table.ID' }),
      accessor: 'id',
      Cell: ({ row }) => <div className='w-100 flex-center'># {row.original.id}</div>,
    },
    {
      Header: intl.formatMessage({ id: 'Table.File' }),
      accessor: 'img',
      Cell: ({ row }) => (
        <div className='symbol symbol-50px'>
          <img
            src={
              row.original.img
                ? process.env.REACT_APP_IMAGE_PATH + row.original.img
                : toAbsoluteUrl('media/avatars/300-6.jpg')
            }
            alt=''
          />
        </div>
      ),
    },
    {
      Header: intl.formatMessage({ id: 'Table.DescriptionEN' }),
      accessor: 'name_en',
      Cell: ({ row }) => <div className='w-100 flex-center'>{row.original.name_en}</div>,
    },
    {
      Header: intl.formatMessage({ id: 'Table.DescriptionAR' }),
      accessor: 'name_ar',
      Cell: ({ row }) => <div className='w-100 flex-center'>{row.original.name_ar}</div>,
    },
    {
      Header: intl.formatMessage({ id: 'Table.DailyLimit' }),
      accessor: 'daily_limit',
      Cell: ({ row }) => <div className='w-100 flex-center'>{row.original.daily_limit}</div>,
    },
    {
      Header: intl.formatMessage({ id: 'Table.MonthlyLimit' }),
      accessor: 'monthly_limit',
      Cell: ({ row }) => <div className='w-100 flex-center'>{row.original.monthly_limit}</div>,
    },
    {
      Header: intl.formatMessage({ id: 'Table.Action' }),
      Cell: ({ row }) => (
        <div className='d-flex flex-center'>
          {check.edit && (
            <Link
              to={`/Levels/Edit-Levels/${row.original.id}`}
              className='btn me-2 btn-light-primary'
            >
              {intl.formatMessage({ id: 'Table.Edit' })}
            </Link>
          )}
          {check.delete && (
            <button
              onClick={() => deleteItem(row.original.id)}
              className='btn me-2 btn-light-danger'
            >
              {intl.formatMessage({ id: 'Table.Delete' })}
            </button>
          )}
        </div>
      ),
    },
  ]

  useEffect(() => {
    setCheck({
      browse: check_role('browse-levels'),
      add: check_role('add-levels'),
      show: check_role('show-levels'),
      edit: check_role('edit-levels'),
      delete: check_role('delete-levels'),
    })

    if (!check_role('browse-levels')) {
      navigate('/')
    }

    getData()
  }, [])

  // Search and Pagination
  const filteredItems = items.filter(
    (item) =>
      item.name_en.toLowerCase().includes(search.toLowerCase()) ||
      item.name_ar.toLowerCase().includes(search.toLowerCase()) ||
      item.type?.toLowerCase().includes(search.toLowerCase())
  )

  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem)

  const totalPages = Math.ceil(filteredItems.length / itemsPerPage)

  return (
    <motion.nav
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0, transition: { duration: 0.3 } }}
      exit={{ opacity: 0, x: 100, transition: { duration: 0.2 } }}
    >
      <div className='mb-5 mt-5 d-flex flex-stack'>
        <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
          <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
            {intl.formatMessage({ id: 'Menu.Levels' })}
          </h1>
          <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
            <li className='breadcrumb-item text-muted'>{intl.formatMessage({ id: 'Menu.Home' })}</li>
            <li className='breadcrumb-item'>
              <span className='bullet bg-gray-400 w-5px h-2px'></span>
            </li>
            <li className='breadcrumb-item text-muted'>
              {intl.formatMessage({ id: 'Menu.Levels' })}
            </li>
          </ul>
        </div>
      </div>

      {/* Table View for large screens */}
      <div className='d-none d-md-block'>
        {!loading ? (
          <ReactTable columns={columns} data={currentItems} customComponent={<CustomDiv />} />
        ) : (
          <div className='row'>
            <div className='col-12'>
              <Skeleton
                variant='rounded'
                animation='wave'
                sx={{ width: '100%', height: '90vh', marginTop: '1vh' }}
              />
            </div>
          </div>
        )}
      </div>

      {/* Card View for small screens */}
      <div className='d-block d-md-none'>
        {/* Create Button and Search Input */}
        <CustomDiv />

        <div className='my-3'>
          <input
            type='text'
            className='form-control'
            placeholder={intl.formatMessage({ id: 'Search.Input' })}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        {/* Display Cards */}
        {currentItems.map((item) => (
          <div key={item.id} className='card mb-4'>
            <div className='card-body'>
              <h5 className='card-title'>#{item.id}</h5>
              <div className='mb-2'>
                <strong>{intl.formatMessage({ id: 'Table.DescriptionEN' })}: </strong>
                {item.name_en}
              </div>
              <div className='mb-2'>
                <strong>{intl.formatMessage({ id: 'Table.DescriptionAR' })}: </strong>
                {item.name_ar}
              </div>
              <div className='mb-2'>
                <strong>{intl.formatMessage({ id: 'Table.DailyLimit' })}: </strong>
                {item.daily_limit}
              </div>
              <div className='mb-2'>
                <strong>{intl.formatMessage({ id: 'Table.MonthlyLimit' })}: </strong>
                {item.monthly_limit}
              </div>
              <div className='d-flex justify-content-between'>
                <div className='symbol symbol-50px'>
                  <img
                    src={
                      item.img
                        ? process.env.REACT_APP_IMAGE_PATH + item.img
                        : toAbsoluteUrl('media/avatars/300-6.jpg')
                    }
                    alt='img'
                    className='object-fit-cover rounded-circle'
                    style={{ width: '50px', height: '50px' }}
                  />
                </div>
                <div>
                  {check.edit && (
                    <Link to={`/Levels/Edit-Levels/${item.id}`} className='btn btn-light-primary'>
                      {intl.formatMessage({ id: 'Table.Edit' })}
                    </Link>
                  )}
                  {check.delete && (
                    <button onClick={() => deleteItem(item.id)} className='btn btn-light-danger'>
                      {intl.formatMessage({ id: 'Table.Delete' })}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}

        {/* MUI Pagination */}
        <div className='mt-6 d-flex justify-content-center'>
          {filteredItems.length > 0 && (
            <Pagination
              count={totalPages} // Total number of pages
              page={currentPage} // Current page
              onChange={(event, value) => setCurrentPage(value)} // Handle page change
              color='primary'
            />
          )}
        </div>
      </div>
    </motion.nav>
  )
}

export default LevelsHome
