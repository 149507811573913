import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import axiosInstance from '../../../../api/axios'
import Swal from 'sweetalert2'
import {KTSVG} from '../../../../../_metronic/helpers'
import {Link, useNavigate} from 'react-router-dom'
import {motion} from 'framer-motion'
import ReactTable from '../../../../../_metronic/partials/widgets/React_Table/ReactTable'
import {Skeleton} from '@mui/material'
import {Images} from '../../../../../_metronic/partials/widgets/React_Table/Images'
import useAuthContext from '../../../../Auth/AuthContext'
import Pagination from '@mui/material/Pagination'

const BanUsersHome = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState([])
  const [filteredItems, setFilteredItems] = useState([])
  const {check_role} = useAuthContext()
  const navigate = useNavigate()
  const [searchTerm, setSearchTerm] = useState('')
  
  // Pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 10 // Number of items per page

  const [check, setCheck] = useState({
    browse: false,
    add: false,
    show: false,
    edit: false,
    delete: false,
  })

  const CustomDiv = () => (
    <div className='row flex-end w-100'>
      <div className='col-auto'>
        {check.add && (
          <Link to='/Bans/add-Ban-Users' type='button' className='btn btn-primary'>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            {intl.formatMessage({id: 'UserRole.Create'})}
          </Link>
        )}
      </div>
    </div>
  )

  const getData = async () => {
    setLoading(true)
    const {data} = await axiosInstance.get('/Admin-ban-users')
    setItems(data)
    setFilteredItems(data)
    setLoading(false)
  }

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase()
    setSearchTerm(value)
    const filtered = items.filter((item) => item.name.toLowerCase().includes(value))
    setFilteredItems(filtered)
  }

  const deleteItem = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const {data} = await axiosInstance.delete(`/Admin-ban-users/${id}`)
          if (!data || data.length === 0) {
            throw new Error(JSON.stringify(data))
          }
          getData()
        } catch (error) {
          Swal.showValidationMessage(`Request failed: ${error}`)
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Done!',
          icon: 'success',
        })
      }
    })
  }

  const columns = [
    {
      Header: intl.formatMessage({id: 'Table.User'}),
      accessor: 'name',
      Cell: (row) => (
        <div className='d-flex align-items-center ps-3'>
          <Images img={row.row.original.img} name={row.row.original.name} />
          <div className='flex-grow-1'>
            <div className='text-dark fw-bold text-hover-primary text-start fs-6'>
              {row.row.original.name}
            </div>
            <span className='text-muted d-block fw-semibold text-start'>
              #{row.row.original.id}
            </span>
          </div>
        </div>
      ),
    },
    {
      Header: intl.formatMessage({id: 'Table.Bans'}),
      Cell: (row) => (
        <div className='d-flex flex-center'>
          {row.row.original.bans.map((item) => (
            <span className='badge badge-light-success mx-2 fs-8 fw-bold'>{item.name}</span>
          ))}
        </div>
      ),
    },
    {
      Header: intl.formatMessage({id: 'Table.Action'}),
      Cell: ({row}) => (
        <div className='d-flex flex-center'>
          {check.edit && (
            <Link
              to={`/Bans/Edit-Ban-Users/${row.original.id}`}
              className='btn me-2 btn-light-primary'
            >
              {intl.formatMessage({id: 'Table.Edit'})}
            </Link>
          )}
          {check.delete && (
            <button
              onClick={() => deleteItem(row.original.id)}
              className='btn me-2 btn-light-danger'
            >
              {intl.formatMessage({id: 'Table.Delete'})}
            </button>
          )}
        </div>
      ),
    },
  ]

  useEffect(() => {
    setCheck({
      browse: check_role('browse-ban-users'),
      add: check_role('add-ban-users'),
      show: check_role('show-ban-users'),
      edit: check_role('edit-ban-users'),
      delete: check_role('delete-ban-users'),
    })

    if (!check_role('browse-ban-users')) {
      navigate('/')
    }
    getData()
  }, [])

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem)

  const handlePageChange = (event, value) => {
    setCurrentPage(value)
  }

  return (
    <motion.nav
      initial={{opacity: 0, x: 100}}
      animate={{opacity: 1, x: 0, transition: {duration: 0.3}}}
      exit={{opacity: 0, x: 100, transition: {duration: 0.2}}}
    >
      <div className='mb-5 mt-5 d-flex flex-stack'>
        <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
          <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
            {intl.formatMessage({id: 'Menu.UsersRole'})}
          </h1>
          <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
            <li className='breadcrumb-item text-muted'>{intl.formatMessage({id: 'Menu.Home'})}</li>
            <li className='breadcrumb-item'>
              <span className='bullet bg-gray-400 w-5px h-2px'></span>
            </li>
            <li className='breadcrumb-item text-muted'>{intl.formatMessage({id: 'Menu.Roles'})}</li>
          </ul>
        </div>
      </div>

      {/* Table View for Large Screens */}
      <div className='d-none d-lg-block'>
        {!loading ? (
          <ReactTable columns={columns} data={filteredItems} customComponent={<CustomDiv />} />
        ) : (
          <div className='row'>
            <div className='col-12'>
              <Skeleton
                variant='rounded'
                animation='wave'
                sx={{width: '100%', height: '90vh', marginTop: '1vh'}}
              />
            </div>
          </div>
        )}
      </div>

      {/* Card View for Small Screens */}
      <div className='d-lg-none'>
        {/* Search Input */}
        <div className='mb-4'>
          <div className="mb-3">
            <CustomDiv />
          </div>
          <input
            type='text'
            className='form-control'
            placeholder={intl.formatMessage({id: 'Search.Input'})}
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>

        {!loading ? (
          <div className='row'>
            {currentItems.map((item) => (
              <div className='col-12 mb-3' key={item.id}>
                <div className='card'>
                  <div className='card-body'>
                    <div className='d-flex'>
                      <Images img={item.img} name={item.name} />
                      <div className='ms-3'>
                        <h5 className='card-title'>{item.name}</h5>
                        <p className='card-text'>ID: #{item.id}</p>
                        <div className='d-flex flex-wrap'>
                          {item.bans.map((ban, index) => (
                            <span
                              key={index}
                              className='badge badge-light-success mx-2 fs-8 fw-bold'
                            >
                              {ban.name}
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className='mt-3'>
                      {check.edit && (
                        <Link
                          to={`/Bans/Edit-Ban-Users/${item.id}`}
                          className='btn btn-light-primary me-2 col-5'
                        >
                          {intl.formatMessage({id: 'Table.Edit'})}
                        </Link>
                      )}
                      {check.delete && (
                        <button
                          onClick={() => deleteItem(item.id)}
                          className='btn btn-light-danger col-5'
                        >
                          {intl.formatMessage({id: 'Table.Delete'})}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className='row'>
            <div className='col-12'>
              <Skeleton
                variant='rounded'
                animation='wave'
                sx={{width: '100%', height: '90vh', marginTop: '1vh'}}
              />
            </div>
          </div>
        )}

        {/* Pagination */}
        <div className='mt-6 d-flex justify-content-center'>
          <Pagination
            count={Math.ceil(filteredItems.length / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            color='primary'
          />
        </div>
      </div>
    </motion.nav>
  )
}

export default BanUsersHome
