import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import axiosInstance from '../../../api/axios'
import Swal from 'sweetalert2'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Link, useNavigate} from 'react-router-dom'
import {motion} from 'framer-motion'
import ReactTable from '../../../../_metronic/partials/widgets/React_Table/ReactTable'
import {Pagination, Skeleton} from '@mui/material'
import useAuthContext from '../../../Auth/AuthContext'
import {Images} from '../../../../_metronic/partials/widgets/React_Table/Images'

const UsersCreditHome = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [Items, setItems] = useState(null)

  const navigate = useNavigate()
  const {check_role} = useAuthContext()

  const [searchTerm, setSearchTerm] = useState('') // State to track the search input
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage] = useState(10) // Items per page

  const [check, setCheck] = useState({
    browse: false,
    add: false,
    show: false,
    edit: false,
    delete: false,
  })

  const CustomDiv = () => (
    <div className='row flex-end w-100'>
      {check.add && (
        <div className='col-auto'>
          <Link to='/Users/Add-Users-Credit' type='button' className='btn btn-primary'>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            {intl.formatMessage({id: 'Create.Credit.Users'})}
          </Link>
        </div>
      )}
    </div>
  )
  const get_data = async () => {
    setLoading(true)
    const {data} = await axiosInstance.get('/Admin-user-credit')
    console.log(data)
    setItems(data)
    setLoading(false)
  }
  const edit_status = async (value, id) => {
    let endpoint
    endpoint = `/change-Admin-user-credit-status/${id}/${value}`
    try {
      await axiosInstance.get(endpoint)
      get_data()
    } catch (error) {
      console.error(error)
    }
  }
  const Delete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      showLoaderOnConfirm: true,
      preConfirm: async (login) => {
        try {
          const {data} = await axiosInstance.delete(`/Admin-user-credit/${id}`)
          if (!data || data.length === 0) {
            throw new Error(JSON.stringify(data))
          }
          get_data()
        } catch (error) {
          Swal.showValidationMessage(`
                  Request failed: ${error}
              `)
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Done!',
          icon: 'success',
        })
      }
    })
  }

  const columns = [
    {
      Header: intl.formatMessage({id: 'Table.User'}),
      accessor: (row) => `${row.user?.name} ${row.user?.id}`,
      Cell: (row) =>
        row.row.original.user && (
          <div className='d-flex align-items-center ps-3'>
            <Images img={row.row.original.user.img} name={row.row.original.user.name} />
            <div className='flex-grow-1'>
              <div className='text-dark fw-bold text-hover-primary text-start fs-6'>
                {row.row.original.user.name}
              </div>
              <span className='text-muted d-block fw-semibold text-start'>
                #{row.row.original.user.id}
              </span>
            </div>
          </div>
        ),
      // Define a custom filter function
      filter: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const user = row.values[id] // id will be 'user' which is the accessor
          return user.toLowerCase().includes(filterValue.toLowerCase()) // Search on concatenated string
        })
      },
    },
    {
      Header: intl.formatMessage({id: 'Table.credit_number'}),
      accessor: 'credit_number',
      Cell: (row) => <div className=' w-100 flex-center'>{row.row.original.credit_number}</div>,
    },
    {
      Header: 'enable',
      accessor: 'status',
      Cell: (row) => (
        <div className='form-check mt-2 form-switch form-switch-sm form-check-custom form-check-solid flex-center'>
          {check.edit && (
            <input
              className='form-check-input'
              type='checkbox'
              value={row.row.original.status}
              name='notifications'
              defaultChecked={row.row.original.status}
              onClick={() => edit_status(!row.row.original.status, row.row.original.id)}
            />
          )}
        </div>
      ),
    },
    {
      Header: intl.formatMessage({id: 'Table.Action'}),
      Cell: (row) => (
        <div className='d-flex flex-center'>
          {check.edit && (
            <Link
              to={`/Users/Edit-Users-Credit/${row.row.original.id}`}
              className='btn me-2  btn-light-primary'
            >
              {' '}
              {intl.formatMessage({id: 'Table.Edit'})}{' '}
            </Link>
          )}
          {check.delete && (
            <button
              onClick={(e) => Delete(row.row.original.id)}
              className='btn me-2  btn-light-danger'
            >
              {' '}
              {intl.formatMessage({id: 'Table.Delete'})}{' '}
            </button>
          )}
        </div>
      ),
    },
  ]

  useEffect(() => {
    setCheck({
      browse: check_role('browse-user-credits'),
      add: check_role('add-user-credits'),
      show: check_role('show-user-credits'),
      edit: check_role('edit-user-credits'),
      delete: check_role('delete-user-credits'),
    })

    if (!check_role('browse-user-credits')) {
      navigate('/')
    }
    get_data()
  }, [])

  // Filter items based on search term
  const filteredItems = Items
    ? Items.filter((item) => item.user?.name.toLowerCase().includes(searchTerm.toLowerCase()))
    : []

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem)

  const handlePageChange = (event, value) => {
    setCurrentPage(value)
  }

  return (
    <motion.nav
      initial={{opacity: 0, x: 100}}
      animate={{opacity: 1, x: 0, transition: {duration: 0.3}}}
      exit={{opacity: 0, x: 100, transition: {duration: 0.2}}}
    >
      <div className=' mb-5 mt-5 d-flex flex-stack'>
        <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
          <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
            {intl.formatMessage({id: 'Menu.Credits'})}
          </h1>
          <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
            <li className='breadcrumb-item text-muted'>
              <li className='breadcrumb-item text-muted'>
                {intl.formatMessage({id: 'Menu.Home'})}
              </li>
            </li>
            <li className='breadcrumb-item'>
              <span className='bullet bg-gray-400 w-5px h-2px'></span>
            </li>
            <li className='breadcrumb-item text-muted'>
              {intl.formatMessage({id: 'Menu.Users-Credit'})}
            </li>
          </ul>
        </div>
      </div>
      <div className='d-none d-lg-block'>
        {Items !== null && !loading ? (
          <ReactTable columns={columns} data={Items} customComponent={<CustomDiv />} />
        ) : (
          <Skeleton
            variant='rounded'
            animation='wave'
            sx={{width: '100%', height: '90vh', marginTop: '1vh'}}
          />
        )}
      </div>

      <div className='d-block d-lg-none'>
        <div className='d-block d-lg-none mb-4'>
          <div className='mb-3'>
            <CustomDiv />
          </div>
          <input
            type='text'
            className='form-control'
            placeholder={intl.formatMessage({id: 'Search.Input'})}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        {currentItems !== null && !loading ? (
          <div className='row'>
            {currentItems.map((item) => (
              <div key={item.id} className='col-12 mb-4'>
                <div className='card'>
                  <div className='card-body d-flex align-items-center'>
                    <div className='flex-grow-1 ps-3'>
                      {/* Display User Name and ID */}
                      <div className='d-flex align-items-center mb-6'>
                        <Images img={item.user?.img} name={item.user?.name} />
                        <h5 className='card-title'>{item.user?.name}</h5>
                      </div>

                      <p className='card-text'>
                        {intl.formatMessage({id: 'Table.User'})}: {item.user?.name} (ID:{' '}
                        {item.user?.id})
                      </p>

                      {/* Display Credit Number */}
                      <p className='card-text'>
                        {intl.formatMessage({id: 'Table.credit_number'})}: {item.credit_number}
                      </p>

                      {/* Display Status */}
                      <div className='form-check mt-2 form-switch form-switch-sm form-check-custom form-check-solid'>
                        {check.edit && (
                          <input
                            className='form-check-input'
                            type='checkbox'
                            value={item.status}
                            name='notifications'
                            defaultChecked={item.status}
                            onClick={() => edit_status(!item.status, item.id)}
                          />
                        )}
                      </div>

                      {/* Action Buttons */}
                      <div className='d-flex justify-content-between mt-3'>
                        {check.edit && (
                          <Link
                            to={`/Users/Edit-Users-Credit/${item.id}`}
                            className='btn btn-light-primary'
                          >
                            {intl.formatMessage({id: 'Table.Edit'})}
                          </Link>
                        )}
                        {check.delete && (
                          <button onClick={() => Delete(item.id)} className='btn btn-light-danger'>
                            {intl.formatMessage({id: 'Table.Delete'})}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            <div className='mt-6 d-flex justify-content-center'>
              <Pagination
                count={Math.ceil(filteredItems.length / itemsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                color='primary'
              />
            </div>
          </div>
        ) : (
          <Skeleton
            variant='rounded'
            animation='wave'
            sx={{width: '100%', height: '90vh', marginTop: '1vh'}}
          />
        )}
      </div>
    </motion.nav>
  )
}
//get-banners
export default UsersCreditHome
