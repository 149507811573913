import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import axiosInstance from '../../../../api/axios'
import Swal from 'sweetalert2'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {Link, useNavigate} from 'react-router-dom'
import {motion} from 'framer-motion'
import ReactTable from '../../../../../_metronic/partials/widgets/React_Table/ReactTable'
import {Pagination, Skeleton} from '@mui/material'
import {Images} from '../../../../../_metronic/partials/widgets/React_Table/Images'
import useAuthContext from '../../../../Auth/AuthContext'

const RoleUsersHome = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const {check_role} = useAuthContext()
  const navigate = useNavigate()

  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 10 // Number of users per page

  const [check, setCheck] = useState({
    browse: false,
    add: false,
    show: false,
    edit: false,
    delete: false,
  })

  const CustomDiv = () => (
    <div className='row flex-end w-100'>
      <div className='col-auto'>
        {check.add && (
          <Link to='/Roles/Add-Users' type='button' className='btn btn-primary'>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            {intl.formatMessage({id: 'UserRole.Create'})}
          </Link>
        )}
      </div>
    </div>
  )

  const getData = async () => {
    setLoading(true)
    const {data} = await axiosInstance.get('/admin-role-users')
    setItems(data)
    setLoading(false)
  }

  const deleteItem = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const {data} = await axiosInstance.delete(`/admin-role-users/${id}`)
          if (!data || data.length === 0) {
            throw new Error(JSON.stringify(data))
          }
          getData()
        } catch (error) {
          Swal.showValidationMessage(`Request failed: ${error}`)
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Done!',
          icon: 'success',
        })
      }
    })
  }

  const columns = [
    {
      Header: intl.formatMessage({id: 'Table.User'}),
      accessor: (row) => `${row.user?.name} ${row.user?.id}`,
      Cell: (row) =>
        row.row.original.user && (
          <div className='d-flex align-items-center ps-3'>
            <Images img={row.row.original.user.img} name={row.row.original.user.name} />
            <div className='flex-grow-1'>
              <div className='text-dark fw-bold text-hover-primary text-start fs-6'>
                {row.row.original.user.name}
              </div>
              <span className='text-muted d-block fw-semibold text-start'>
                #{row.row.original.user.id}
              </span>
            </div>
          </div>
        ),
    },
    {
      Header: intl.formatMessage({id: 'Table.Roles'}),
      Cell: (row) => (
        <div className='d-flex flex-center'>
          {row.row.original.roles.map((item) => (
            <span className='badge badge-light-success mx-2 fs-8 fw-bold'>{item.name_en}</span>
          ))}
        </div>
      ),
    },
    {
      Header: intl.formatMessage({id: 'Table.Action'}),
      Cell: ({row}) => (
        <div className='d-flex flex-center'>
          {check.edit && (
            <Link
              to={`/Roles/Edit-Users/${row.original.user.id}`}
              className='btn me-2 btn-light-primary'
            >
              {intl.formatMessage({id: 'Table.Edit'})}
            </Link>
          )}
          {check.delete && (
            <button
              onClick={() => deleteItem(row.original.user.id)}
              className='btn me-2 btn-light-danger'
            >
              {intl.formatMessage({id: 'Table.Delete'})}
            </button>
          )}
        </div>
      ),
    },
  ]

  useEffect(() => {
    setCheck({
      browse: check_role('browse-users-roles'),
      add: check_role('add-users-roles'),
      show: check_role('show-users-roles'),
      edit: check_role('edit-users-roles'),
      delete: check_role('delete-users-roles'),
    })

    if (!check_role('browse-users-roles')) {
      navigate('/')
    }
    getData()
  }, [])

  const filteredItems = items.filter((item) =>
    item.user.name.toLowerCase().includes(searchTerm.toLowerCase())
  )

  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem)

  const handlePageChange = (event, value) => {
    setCurrentPage(value)
  }

  return (
    <motion.nav
      initial={{opacity: 0, x: 100}}
      animate={{opacity: 1, x: 0, transition: {duration: 0.3}}}
      exit={{opacity: 0, x: 100, transition: {duration: 0.2}}}
    >
      <div className='mb-5 mt-5 d-flex flex-stack'>
        <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
          <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
            {intl.formatMessage({id: 'Menu.UsersRole'})}
          </h1>
          <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
            <li className='breadcrumb-item text-muted'>{intl.formatMessage({id: 'Menu.Home'})}</li>
            <li className='breadcrumb-item'>
              <span className='bullet bg-gray-400 w-5px h-2px'></span>
            </li>
            <li className='breadcrumb-item text-muted'>{intl.formatMessage({id: 'Menu.Roles'})}</li>
          </ul>
        </div>
      </div>

      {!loading ? (
        <div className='container'>
          <div className='d-none d-lg-block'>
            {/* Table view for large screens */}
            <ReactTable columns={columns} data={items} customComponent={<CustomDiv />} />
          </div>

          <div className='d-lg-none'>
            {/* Search input for small screens */}
            <div className='mb-3'>
              <CustomDiv />
            </div>
            <div className='mb-3'>
              <input
                type='text'
                className='form-control'
                placeholder={intl.formatMessage({id: 'Search.Input'})}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>

            {/* Card view for small screens */}
            <div className='row'>
              {currentItems.map((item) => (
                <div key={item.user.id} className='col-12 mb-4'>
                  <div className='card'>
                    <div className='card-body d-flex'>
                      <Images
                        img={item.user.img}
                        name={item.user.name}
                        style={{objectFit: 'cover', width: '50px', height: '50px'}}  
                      />

                      <div className='ms-3'>
                        {/* User Name and ID */}
                        <h5 className='card-title'>{item.user.name}</h5>
                        <p className='card-text'>ID: #{item.user.id}</p>

                        {/* Roles */}
                        <div className='d-flex flex-wrap'>
                          {item.roles.map((role) => (
                            <span key={role.id} className='badge badge-light-success mx-1'>
                              {role.name_en}
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className='card-footer'>
                      {/* Actions */}
                      {check.edit && (
                        <Link
                          to={`/Roles/Edit-Users/${item.user.id}`}
                          className='btn btn-light-primary me-2 col-5'
                        >
                          {intl.formatMessage({id: 'Table.Edit'})}
                        </Link>
                      )}
                      {check.delete && (
                        <button
                          onClick={() => deleteItem(item.user.id)}
                          className='btn btn-light-danger col-5'
                        >
                          {intl.formatMessage({id: 'Table.Delete'})}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Pagination */}
            <div className='mt-6 d-flex justify-content-center'>
              <Pagination
                count={Math.ceil(filteredItems.length / itemsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                color='primary'
              />
            </div>
          </div>
        </div>
      ) : (
        <div className='row'>
          <div className='col-12'>
            <Skeleton
              variant='rounded'
              animation='wave'
              sx={{width: '100%', height: '90vh', marginTop: '1vh'}}
            />
          </div>
        </div>
      )}
    </motion.nav>
  )
}

export default RoleUsersHome
