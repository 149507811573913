import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import axiosInstance from '../../../api/axios'
import Swal from 'sweetalert2'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { Link, useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion';
import ReactTable from '../../../../_metronic/partials/widgets/React_Table/ReactTable'
import { Skeleton } from '@mui/material'
import { FaCopy } from "react-icons/fa";
import ProductsAutoComplete from '../../../../_metronic/partials/widgets/AutoComplete/ProductsAutoComplete'
import { EditProductStock } from './EditProductStock'
import moment from 'moment'
import useAuthContext from '../../../Auth/AuthContext'

const ProductStocks = () => {
    const intl = useIntl()
    const [loading, setLoading] = useState(false)
    const [Items, setItems] = useState(null)
    const [Edit, setEdit] = useState(false)
    const [Stock_id, setStock_id] = useState(null);
    const navigate = useNavigate()

    const { check_role } = useAuthContext()
    const [check, setCheck] = useState({
        'browse': false,
        'add': false,
        'show': false,
        'edit': false,
        'delete': false,
    })


    const [user_value, setUSER_Value] = useState(null);
    const get_data = async () => {
        setLoading(true)
        const { data } = await axiosInstance.get('/admin-stocks', {
            params: {
                id: user_value.id
            }
        })
        console.log(data.stock);
        setItems(data.stock)
        setLoading(false)

    }

    const Delete = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert ",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            showLoaderOnConfirm: true,
            preConfirm: async (login) => {
                try {
                    const { data } = await axiosInstance.delete(`/admin-stocks/${id}`);
                    if (!data || data.length === 0) {
                        throw new Error(JSON.stringify(data));
                    }
                    get_data()
                } catch (error) {
                    Swal.showValidationMessage(`
                  Request failed: ${error}
              `);
                }
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "Done!",
                    icon: "success"
                });
            }
        });

    }



    const columns = [
        {
            Header: intl.formatMessage({ id: 'Table.Code' }), accessor: 'pin',
            Cell: row => (
                <div className="w-100 flex-center">
                    {row.row.original.pin}
                </div>
            )
        },
        {
            Header: intl.formatMessage({ id: 'Table.Refnumber' }), accessor: 'code',
            Cell: row => (
                <div className="w-100 flex-center">
                    {row.row.original.code}
                </div>
            )
        },
        {
            Header: intl.formatMessage({ id: 'Table.Date' }),
            accessor: 'created_at',
            Cell: ({ row }) => (
                <div className="w-100 flex-center">
                    {moment(row.original.created_at).format("YYYY-MM-DD hh:mm:ss A")}
                </div>
            )
        },
        {
            Header: intl.formatMessage({ id: 'Table.Action' }),
            Cell: row => (
                <div className="d-flex flex-center my-2">
                    {check.edit && (
                        <button onClick={e => [setStock_id(row.row.original.id), setEdit(true)]} className='btn me-2 w-100  btn-light-primary'> {intl.formatMessage({ id: 'Table.Edit' })} </button>
                    )}
                    {check.delete && (
                        <button onClick={(e) => Delete(row.row.original.id)} className='btn me-2  w-100 btn-light-danger'> {intl.formatMessage({ id: 'Table.Delete' })}  </button>
                    )}
                </div>
            ),
        },
    ];

    useEffect(() => {
        setCheck({
            'browse': check_role('browse-Stocks'),
            'add': check_role('add-Stocks'),
            'show': check_role('show-Stocks'),
            'edit': check_role('edit-Stocks'),
            'delete': check_role('delete-Stocks'),
        })

        if (!check_role('browse-Stocks')) {
            navigate('/')
        }
    }, [])


    return (
        <motion.nav
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0, transition: { duration: 0.3 } }}
            exit={{ opacity: 0, x: 100, transition: { duration: 0.2 } }} >
            <div className=" mb-5 mt-5 d-flex flex-stack">
                <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">{intl.formatMessage({ id: 'Menu.CheckStock' })}</h1>
                    <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                        <li className="breadcrumb-item text-muted">
                            <li className="breadcrumb-item text-muted">{intl.formatMessage({ id: 'Menu.Home' })}</li>
                        </li>
                        <li className="breadcrumb-item">
                            <span className="bullet bg-gray-400 w-5px h-2px"></span>
                        </li>
                        <li className="breadcrumb-item text-muted">{intl.formatMessage({ id: 'Menu.Stocks' })}</li>
                    </ul>
                </div>
            </div>
            <div className="card my-3 p-5">
                <div className="row flex-between align-items-end">
                    <div className="col-6">
                        <div className=" fv-row fv-plugins-icon-container">
                            <ProductsAutoComplete value={user_value} setValue={setUSER_Value} />
                            <div className="fv-plugins-message-container invalid-feedback" />
                        </div>
                    </div>
                    <div className="col-3">
                        <button onClick={get_data} type='button' className='btn w-100 btn-light-primary py-5' >
                            {!loading &&
                                <span className='indicator-label'>
                                    {intl.formatMessage({ id: 'Table.Search' })}
                                    <KTSVG path='/media/icons/duotune/general/gen004.svg' className='svg-icon-2 ms-3' />
                                </span>}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    {intl.formatMessage({ id: 'Form.Pleasewait' })}{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </div>
            </div>
            {Items !== null && !loading &&
                <ReactTable columns={columns} data={Items} customComponent={<></>} />
            }
            <EditProductStock handleClose={setEdit} get_data={get_data} show={Edit} id={Stock_id} />

        </motion.nav>
    )
}
//get-banners
export default ProductStocks