import React, { useState } from 'react'
import {Button, Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import axiosInstance from '../../../../../../api/axios'
import Swal from 'sweetalert2'

export const AddCreditUserModel = ({handleClose, show, get_data, user_id}) => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([]);

  const [data, setData] = useState({
    credit_number: '',
    status: false,
    user_id: user_id,
  })

  const handleChange = (e) => {
    const {name, value} = e.target
    setData((prevData) => ({...prevData, [name]: value}))
  }

  const handleEdit_Add_submit = async (event) => {
    event.preventDefault()
    setLoading(true)
    const formData = new FormData()
    Object.entries(data).forEach(([key, value]) => formData.append(key, value))
    try {
      await axiosInstance.post(`/Admin-user-credit`, formData)
      Swal.fire({
        title: intl.formatMessage({id: 'Success'}),
        icon: 'success',
        confirmButtonText: 'OK',
      })
      get_data()
      handleClose()
    } catch (error) {
        setErrors(error.response)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal show={show} onHide={handleClose} size='lg'>
      <Modal.Header closeButton></Modal.Header>
      <form onSubmit={handleEdit_Add_submit}>
        <Modal.Body>
          <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
            {/* Status Toggle */}
            <div className='card card-flush py-4'>
              <div className='card-header'>
              
                <div className='card-toolbar'>
                  <div
                    className='rounded-circle w-15px h-15px bg-primary'
                    id='kt_ecommerce_add_product_status'
                  />
                </div>
              </div>
              <div className='card-body py-0'>
                <div className='form-check form-switch form-check-custom form-check-solid'>
                  <input
                    className='form-check-input h-30px w-50px'
                    onChange={(e) =>
                      handleChange({target: {name: 'status', value: e.target.checked}})
                    }
                    type='checkbox'
                    checked={data.status || false}
                    id='flexSwitch30x50'
                    name='status'
                  />
                  <label className='form-check-label' htmlFor='flexSwitch30x50'>
                    {intl.formatMessage({id: 'Form.status'})}{' '}
                    {intl.formatMessage({
                      id: data.status ? 'Form.Enabled' : 'Form.Disabled',
                    })}
                  </label>
                </div>
              </div>
            </div>

            <div className='card card-flush py-4'>
              <div className='card-header'>
              
              </div>
              <div className='card-body pt-0'>
                <div className='my-3 fv-row'>
                  <label className='required form-label'>
                    {intl.formatMessage({id: 'Form.credit_number'})}
                  </label>
                  <input
                    type='number'
                    name='credit_number'
                    className='form-control mb-2'
                    value={data.credit_number}
                    onChange={handleChange}
                    placeholder={intl.formatMessage({id: 'Form.credit_number'})}
                  />
                  {errors.credit_number && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert' className='fv-help-block'>
                        {errors.credit_number}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={(e) => handleClose(false)}>
            Close
          </Button>
          <button type='submit' className='btn btn-primary'>
            {!loading && (
              <span className='indicator-label'>{intl.formatMessage({id: 'Form.Create'})} </span>
            )}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                {intl.formatMessage({id: 'Form.Pleasewait'})}{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}
