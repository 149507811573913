import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import Swal from 'sweetalert2'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {Link, useNavigate} from 'react-router-dom'
import {motion} from 'framer-motion'
import ReactTable from '../../../../../../_metronic/partials/widgets/React_Table/ReactTable'
import {Skeleton, Pagination} from '@mui/material'
import useAuthContext from '../../../../../Auth/AuthContext'
import {Images} from '../../../../../../_metronic/partials/widgets/React_Table/Images'

const Referral = ({referral}) => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [Items, setItems] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage] = useState(10)
  const {check_role} = useAuthContext()
  const navigate = useNavigate()
  const [check, setCheck] = useState({
    browse: false,
    add: false,
    show: false,
    delete: false,
  })


  // const Delete = (id) => {
  //   Swal.fire({
  //     title: 'Are you sure?',
  //     text: "You won't be able to revert ",
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'Yes, delete it!',
  //     showLoaderOnConfirm: true,
  //     preConfirm: async () => {
  //       try {
  //         Swal.fire({title: 'Done!', icon: 'success'})
  //       } catch (error) {
  //         Swal.showValidationMessage(`Request failed: ${error}`)
  //       }
  //     },
  //     allowOutsideClick: () => !Swal.isLoading(),
  //   })
  // }

  const columns = [
    {
      Header: intl.formatMessage({id: 'Table.ID'}),
      accessor: 'id',
      Cell: (row) => <div className=' w-100 flex-center'># {row.row.original.id}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.User'}),
      accessor: (row) => `${row.name} ${row.email}`,
      Cell: (row) => (
        <Link to={`/Users/Edit-Users/${row.row.original.id}`}>
          <div className='d-flex align-items-center ps-3'>
            <Images img={row.row.original.img} name={row.row.original.name} />
            <div className='flex-grow-1'>
              <div className='text-dark fw-bold text-hover-primary text-start fs-6'>
                {row.row.original.name ? row.row.original.name : '---'}
              </div>
              <span className='text-muted d-block fw-semibold text-start'>
                {row.row.original.email}
              </span>
            </div>
          </div>
        </Link>
      ),
    },
    {
      Header: intl.formatMessage({id: 'Table.Phone'}),
      accessor: 'phone',
      Cell: (row) => <div className=' w-100 flex-center'>{row.row.original.phone}</div>,
    },


    // {
    //   Header: intl.formatMessage({id: 'Table.Action'}),
    //   Cell: (row) => (
    //     <div className='d-flex flex-center'>
    //       {check.delete && (
    //         <button
    //           onClick={() => Delete(row.row.original.id)}
    //           className='btn me-2  btn-light-danger'
    //         >
    //           {intl.formatMessage({id: 'Table.Delete'})}
    //         </button>
    //       )}
    //     </div>
    //   ),
    // },
  ]

  // Filter items based on the search term by name
  const filteredItems = referral?.filter((item) => {
    return item.name?.toLowerCase().includes(searchTerm.toLowerCase())
  })

  // Get current items based on pagination
  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = filteredItems?.slice(indexOfFirstItem, indexOfLastItem)

  const handlePageChange = (event, value) => {
    setCurrentPage(value)
  }

  // Card view
  const CardView = ({item}) => {
    return (
      <div className='card mb-3 shadow-sm' style={{borderRadius: '10px', padding: '20px'}}>
        <div className='card-body'>
          <Link to={`/Users/Edit-Users/${item?.id}`}>
            <div className='d-flex align-items-center mb-3'>
              <Images
                img={item.img}
                name={item.name}
                className='me-3'
                style={{width: '80px', height: '80px', borderRadius: '50%'}}
              />
              <div className='ms-3'>
                <h5 className='card-title text-dark fw-bold mb-1'>
                  {item.name ? item.name : '---'}
                </h5>
                <p className='card-text text-muted mb-0'>
                  <strong>{intl.formatMessage({id: 'Email'})}: </strong>
                  {item.email}
                </p>
              </div>
            </div>
          </Link>

          <hr />

          <p className='card-text mb-2'>
            <strong>{intl.formatMessage({id: 'Table.ID'})}: </strong> {item.id}
          </p>
          <p className='card-text mb-2'>
            <strong>{intl.formatMessage({id: 'Table.Phone'})}: </strong> {item.phone}
          </p>

          {/* Delete button */}

          {/* <div className='d-flex justify-content-start mt-3'>
            {check.delete && (
              <button onClick={() => Delete(item.id)} className='btn btn-light-danger me-2 col-12'>
                {intl.formatMessage({id: 'Table.Delete'})}
              </button>
            )}
          </div> */}

        </div>
      </div>
    )
  }

  useEffect(() => {
    setItems(referral)

    setCheck({
      browse: check_role('browse-users'),
      add: check_role('add-users'),
      show: check_role('show-users'),
      delete: check_role('delete-users'),
    })

    if (!check_role('browse-users')) {
      navigate('/')
    }
  }, [referral])

  return (
    <motion.nav
      initial={{opacity: 0, x: 100}}
      animate={{opacity: 1, x: 0, transition: {duration: 0.3}}}
      exit={{opacity: 0, x: 100, transition: {duration: 0.2}}}
    >
      <div className='d-block d-lg-none mb-3'>
        <input
          type='text'
          className='form-control'
          placeholder={intl.formatMessage({id: 'Search'})}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {Items !== null && !loading ? (
        <div>
          {/* Table */}
          <div className='d-none d-lg-block'>
            <ReactTable columns={columns} data={filteredItems} />
          </div>

          {/* Cards */}
          <div className='d-block d-lg-none'>
            {currentItems.map((item) => (
              <CardView key={item.id} item={item} />
            ))}
          </div>

          {/* Pagination */}
          <div className='d-block d-lg-none mt-6 d-flex justify-content-center'>
            <Pagination
              count={Math.ceil(filteredItems?.length / itemsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
              color='primary'
            />
          </div>
        </div>
      ) : (
        <div className='row'>
          <div className='col-12'>
            <Skeleton
              variant='rounded'
              animation='wave'
              sx={{width: '100%', height: '90vh', marginTop: '1vh'}}
            />
          </div>
        </div>
      )}
    </motion.nav>
  )
}

export default Referral
