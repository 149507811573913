import React from 'react'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../../helpers'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'
import useAuthContext from '../../../../../app/Auth/AuthContext'
import { Navigate, useNavigate } from 'react-router-dom'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const { pending_order } = useAuthContext()
  const { check_role } = useAuthContext()

  return (
    <div>
      {check_role('browse-analytics') && (
        <SidebarMenuItem
          title={intl.formatMessage({ id: 'Menu.analytics' })}
          to='/analytics'
          icon='/media/icons/duotune/graphs/gra004.svg'
          fontIcon='bi-layers'
        />
      )}
      {check_role('browse-countries') && (
        <SidebarMenuItem
          title={intl.formatMessage({ id: 'Menu.Countries' })}
          to='/Countries'
          icon='/media/icons/duotune/maps/map008.svg'
          fontIcon='bi-layers'
        />
      )}

      {check_role('browse-currencies') && (
        <SidebarMenuItem
          title={intl.formatMessage({ id: 'Menu.Currencies' })}
          to='/Currencies'
          icon='/media/icons/duotune/finance/fin010.svg'
          fontIcon='bi-layers'
        />
      )}

      {(check_role('browse-levels') || check_role('browse-levels-requests')) && (
        <SidebarMenuItemWithSub
          to='/Levels'
          title={intl.formatMessage({ id: 'Menu.Levels' })}
          icon='/media/icons/duotune/general/gen003.svg'
          hasBullet={false}
        >
          {check_role('browse-levels') && (
            <SidebarMenuItem
              to='/Levels/Levels'
              title={intl.formatMessage({ id: 'Menu.Levels' })}
              hasBullet={true}
            />
          )}
          {check_role('browse-levels-requests') && (
            <SidebarMenuItem
              to='/Levels/Users-Requests'
              title={intl.formatMessage({ id: 'Menu.LevelsRequests' })}
              hasBullet={true}
            />
          )}
        </SidebarMenuItemWithSub>
      )}

      {check_role('browse-users') && (
        <SidebarMenuItem
          title={intl.formatMessage({ id: 'Menu.Users' })}
          to='/Users/Users'
          icon='/media/icons/duotune/communication/com006.svg'
          fontIcon='bi-layers'
        />
      )}

      {/* Archives */}
      {check_role('archive-users') && (
        <SidebarMenuItem
          title={intl.formatMessage({id: 'Menu.Archived'})}
          to='/Archives'
          icon='media/icons/duotune/general/gen027.svg'
          fontIcon='bi-layers'
        />
      )}

      {check_role('browse-users') && (
        <SidebarMenuItem
          title={intl.formatMessage({ id: 'Menu.Users-Credit' })}
          to='/Users-Credit'
          icon='/media/icons/duotune/communication/com006.svg'
          fontIcon='bi-layers'
        />
      )}

      {(check_role('browse-roles') ||
        check_role('browse-permissions') ||
        check_role('browse-permissions-groups') ||
        check_role('browse-users-roles')) && (
          <SidebarMenuItemWithSub
            to='/Roles'
            title={intl.formatMessage({ id: 'Menu.Roles' })}
            icon='/media/icons/duotune/communication/com001.svg'
            hasBullet={false}
          >
            {check_role('browse-permissions') && (
              <SidebarMenuItem
                to='/Roles/Permissions'
                title={intl.formatMessage({ id: 'Menu.Permissions' })}
                hasBullet={true}
              />
            )}
            {check_role('browse-permissions-groups') && (
              <SidebarMenuItem
                to='/Roles/PermissionGroups'
                title={intl.formatMessage({ id: 'Menu.PermissionGroups' })}
                hasBullet={true}
              />
            )}
            {check_role('browse-roles') && (
              <SidebarMenuItem
                to='/Roles/Roles'
                title={intl.formatMessage({ id: 'Menu.Roles' })}
                hasBullet={true}
              />
            )}
            {check_role('browse-users-roles') && (
              <SidebarMenuItem
                to='/Roles/Users'
                title={intl.formatMessage({ id: 'Menu.UsersRole' })}
                hasBullet={true}
              />
            )}
          </SidebarMenuItemWithSub>
        )}
      {/* Bans */}

      {(check_role('browse-ban') ||
        check_role('browse-ban-users') ||
        check_role('browse-ban-ip') ||
        check_role('browse-ban-devices')) && (
          <SidebarMenuItemWithSub
            to='/Bans'
            title={intl.formatMessage({ id: 'Menu.Bans' })}
            icon='/media/icons/duotune/coding/cod001.svg'
            hasBullet={false}
          >
            {check_role('browse-ban') && (
              <SidebarMenuItem
                to='/Bans/Bans'
                title={intl.formatMessage({ id: 'Menu.Bans' })}
                hasBullet={true}
              />
            )}

            {check_role('browse-ban-users') && (
              <SidebarMenuItem
                to='/Bans/Users-Bans'
                title={intl.formatMessage({ id: 'Menu.UserBans' })}
                hasBullet={true}
              />
            )}

            {check_role('browse-ban-ip') && (
              <SidebarMenuItem
                to='/Bans-Ip'
                title={intl.formatMessage({ id: 'Menu.BansIp' })}
                hasBullet={true}
              />
            )}

            {check_role('browse-ban-devices') && (
              <SidebarMenuItem
                to='/Bans-Device'
                title={intl.formatMessage({ id: 'Menu.BansDevice' })}
                hasBullet={true}
              />
            )}
          </SidebarMenuItemWithSub>
        )}

      {check_role('browse-webDetails') && (
        <SidebarMenuItemWithSub
          to='/WebDetails'
          title={intl.formatMessage({ id: 'Menu.WebDetails' })}
          icon='/media/icons/duotune/electronics/elc001.svg'
          hasBullet={false}
        >
          <SidebarMenuItem
            to='/WebDetails/Banners'
            title={intl.formatMessage({ id: 'Menu.Banners' })}
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/WebDetails/HomeSections'
            title={intl.formatMessage({ id: 'Menu.HomeSections' })}
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/WebDetails/HomeSliders'
            title={intl.formatMessage({ id: 'Menu.HomeSliders' })}
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/WebDetails/WebSiteDetails'
            title={intl.formatMessage({ id: 'Menu.WebSiteDetails' })}
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>
      )}

      {/* <div className='menu-item'>
        <div className='menu-content pt-2 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            {' '}
            {intl.formatMessage({id: 'Menu.Integerations'})}{' '}
          </span>
        </div>
      </div> */}

      {/* Developers */}
      {(check_role('browse-integrations') ||
        check_role('browse-payments') ||
        check_role('browse-env-configs')) && (
          <SidebarMenuItemWithSub
            to='/Developers'
            title={intl.formatMessage({ id: 'Menu.Developer' })}
            icon='/media/icons/duotune/coding/cod001.svg'
            hasBullet={false}
          >
            {check_role('browse-env-configs') && (
              <SidebarMenuItem
                title={intl.formatMessage({ id: 'Menu.Configs' })}
                to='/Developers/Configs'
                hasBullet={true}
              />
            )}
            {check_role('browse-integrations') && (
              <SidebarMenuItem
                title={intl.formatMessage({ id: 'Menu.integrations' })}
                to='/Developers/Integrations'
                hasBullet={true}
              />
            )}
            {check_role('browse-payments') && (
              <SidebarMenuItem
                title={intl.formatMessage({ id: 'Menu.Payments' })}
                to='/Developers/Payments'
                hasBullet={true}
              />
            )}
          </SidebarMenuItemWithSub>
        )}

      {check_role('browse-suppliers') && (
        <SidebarMenuItem
          title={intl.formatMessage({ id: 'Menu.Suppliers' })}
          to='/Suppliers'
          icon='/media/icons/duotune/general/gen001.svg'
          fontIcon='bi-layers'
        />
      )}

      {/* <div className='menu-item'>
        <div className='menu-content pt-2 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            {' '}
            {intl.formatMessage({id: 'Menu.Products'})}{' '}
          </span>
        </div>
      </div> */}

      {check_role('browse-categories') && (
        <SidebarMenuItem
          title={intl.formatMessage({ id: 'Menu.Categories' })}
          to='/Categories'
          icon='/media/icons/duotune/abstract/abs015.svg'
          fontIcon='bi-layers'
        />
      )}

      {/* Discounts // Coupons */}
      {(check_role('browse-discounts') || check_role('browse-coupons')) && (
        <SidebarMenuItemWithSub
          to='/Discounts'
          title={intl.formatMessage({ id: 'Menu.Discounts' })}
          icon='/media/icons/duotune/coding/cod001.svg'
          hasBullet={false}
        >
          {check_role('browse-discounts') && (
            <SidebarMenuItem
              title={intl.formatMessage({ id: 'Menu.Discounts' })}
              to='/Discounts/Discounts'
              icon='/media/icons/duotune/ecommerce/ecm003.svg'
              fontIcon='bi-layers'
            />
          )}
          {check_role('browse-coupons') && (
            <SidebarMenuItem
              title={intl.formatMessage({ id: 'Menu.Coupons' })}
              to='/Discounts/Coupons'
              icon='/media/icons/duotune/ecommerce/ecm003.svg'
              fontIcon='bi-layers'
            />
          )}
        </SidebarMenuItemWithSub>
      )}

      {check_role('browse-orders') && (
        <SidebarMenuItem
          title={intl.formatMessage({ id: 'Menu.Orders' })}
          to='/Orders'
          icon='/media/icons/duotune/ecommerce/ecm005.svg'
          fontIcon='bi-layers'
        />
      )}
      {check_role('browse-pending-orders') && (
        <SidebarMenuItem
          title={intl.formatMessage({ id: 'Menu.PendingOrders' })}
          to='/PendingOrders'
          icon='/media/icons/duotune/ecommerce/ecm005.svg'
          fontIcon='bi-layers'
          count={pending_order.toString()}
        />
      )}

      {(check_role('browse-products') ||
        check_role('browse-shortcomings') ||
        check_role('browse-new-arrivals') ||
        check_role('browse-top-selling')) && (
          <SidebarMenuItemWithSub
            to='/Products'
            title={intl.formatMessage({ id: 'Menu.Products' })}
            icon='/media/icons/duotune/ecommerce/ecm007.svg'
            hasBullet={false}
          >
            {check_role('browse-products') && (
              <SidebarMenuItem
                to='/Products/Products'
                title={intl.formatMessage({ id: 'Menu.Products' })}
                hasBullet={true}
              />
            )}
            {check_role('browse-shortcomings') && (
              <SidebarMenuItem
                to='/Products/Shortcomings'
                title={intl.formatMessage({ id: 'Menu.Shortcomings' })}
                hasBullet={true}
              />
            )}
            {check_role('browse-new-arrivals') && (
              <SidebarMenuItem
                to='/Products/NewArrivals'
                title={intl.formatMessage({ id: 'Menu.NewArrivals' })}
                hasBullet={true}
              />
            )}
            {check_role('browse-top-selling') && (
              <SidebarMenuItem
                to='/Products/TopSelling'
                title={intl.formatMessage({ id: 'Menu.TopSelling' })}
                hasBullet={true}
              />
            )}
          </SidebarMenuItemWithSub>
        )}

      {(check_role('browse-stocks') || check_role('add-stocks')) && (
        <SidebarMenuItemWithSub
          to='/Stocks'
          title={intl.formatMessage({ id: 'Menu.Stocks' })}
          icon='/media/icons/duotune/abstract/abs007.svg'
          hasBullet={false}
        >
          {check_role('add-stocks') && (
            <SidebarMenuItem
              to='/Stocks/Add-Product-Stocks'
              title={intl.formatMessage({ id: 'Menu.AddStock' })}
              hasBullet={true}
            />
          )}
          {check_role('browse-stocks') && (
            <SidebarMenuItem
              to='/Stocks/Check-Stock'
              title={intl.formatMessage({ id: 'Menu.CheckStock' })}
              hasBullet={true}
            />
          )}
        </SidebarMenuItemWithSub>
      )}
    </div>
  )
}

export { SidebarMenuMain }
