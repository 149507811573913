import axios from 'axios'
import FingerprintJS from '@fingerprintjs/fingerprintjs'

const getAccessTokenFromLocalStorage = () => {
  return localStorage.getItem('access_token')
}

const getDeviceId = async () => {
  const fp = await FingerprintJS.load()
  const result = await fp.get()

  // Log
  console.log('FingerprintJS details:', result)

  return result.visitorId
}

let ipAddress = null // Store IP address globally

const fetchIpAddress = async () => {
  if (!ipAddress) {
    try {
      const response = await axios.get('https://api.ipify.org?format=json')
      ipAddress = response.data.ip
    } catch (error) {
      console.error('Error fetching IP address:', error)
      ipAddress = null // Handle error and set a fallback
    }
  }
  return ipAddress
}

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
  },
})

axiosInstance.interceptors.request.use(
  async (config) => {
    const accessToken = getAccessTokenFromLocalStorage()
    const deviceId = await getDeviceId()
    const ipAddress = await fetchIpAddress() // Fetch IP address before sending request

    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`
    }

    if (deviceId) {
      config.headers['device_id'] = deviceId
    }

    if (ipAddress) {
      config.headers['user_ip'] = ipAddress
      console.log('AXIOS HEADER', config.headers)
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default axiosInstance
