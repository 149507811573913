import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import axiosInstance from '../../../api/axios'
import Swal from 'sweetalert2'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Link, useNavigate} from 'react-router-dom'
import {motion} from 'framer-motion'
import ReactTable from '../../../../_metronic/partials/widgets/React_Table/ReactTable'
import {Pagination, Skeleton} from '@mui/material'
import {FaCopy} from 'react-icons/fa'
import {SortModel} from '../../../../_metronic/partials/widgets/React_Table/SortModel'
import {AddTopSelling} from './AddTopSelling'
import useAuthContext from '../../../Auth/AuthContext'


const TopSellingHome = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [Items, setItems] = useState(null)
  const [Show_model, setShow_model] = useState(false)
  const [Sort_model, setSort_model] = useState(false)
  const [Item, setItem] = useState({
    id: 0,
    number: 0,
    name: '',
    end_point: '',
  })

  const {check_role} = useAuthContext()
  const navigate = useNavigate()

  const [searchQuery, setSearchQuery] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 10


  const [check, setCheck] = useState({
    browse: false,
    add: false,
    show: false,
    edit: false,
    delete: false,
    sync: false,
  })
  const CustomDiv = () => (
    <div className='row justify-content-between align-items-center w-100'>
      <div className='col-12 col-sm-6 mb-2 mb-sm-0 text-center text-sm-start'>
        {check.add && (
          <button
            onClick={(e) => setShow_model(true)}
            type='button'
            className='btn btn-primary py-2 py-sm-3 px-3 w-100 w-sm-auto'
          >
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            {intl.formatMessage({id: 'Form.Add'})}
          </button>
        )}
      </div>
  
      <div className='col-12 col-sm-6 text-center text-sm-end'>
        {check.edit && (
          <button
            onClick={HanldeSortDefault}
            type='button'
            className='btn btn-light-success py-2 py-sm-3 px-3 w-100 w-sm-auto'
          >
            <KTSVG path='/media/icons/duotune/coding/cod001.svg' className='svg-icon-2' />
            {intl.formatMessage({id: 'Table.DefaultSort'})}
          </button>
        )}
      </div>
    </div>
  )
  

  const HanldeSortDefault = (e) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert Changes ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Look up',
      showLoaderOnConfirm: true,
      preConfirm: async (login) => {
        try {
          const {data} = await axiosInstance.get(`/admin-Sort-topSelling`)
          if (!data || data.length === 0) {
            throw new Error(JSON.stringify(data))
          }
          get_data()
        } catch (error) {
          Swal.showValidationMessage(`
                        Request failed: ${error}
                    `)
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Done!',
          icon: 'success',
        })
      }
    })
  }

  const HanldeSort = (e) => {
    setItem({
      id: e.id,
      name: e.name,
      number: e.sort,
      end_point: '/admin-topSelling/' + e.id,
      method: 'PATCH',
    })
    setSort_model(true)
  }

  const TruncateString = ({text}) => {
    const truncatedText = text ? text.slice(0, 50) : ''
    return truncatedText
  }

  const get_data = async () => {
    setLoading(true)
    const {data} = await axiosInstance.get('/admin-topSelling')
    setItems(data)
    setLoading(false)
  }

  const Delete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      showLoaderOnConfirm: true,
      preConfirm: async (login) => {
        try {
          const {data} = await axiosInstance.delete(`/admin-topSelling/${id}`)
          if (!data || data.length === 0) {
            throw new Error(JSON.stringify(data))
          }
          get_data()
        } catch (error) {
          Swal.showValidationMessage(`
                  Request failed: ${error}
              `)
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Done!',
          icon: 'success',
        })
      }
    })
  }

  const sync = (id) => {
    Swal.fire({
      title: 'LookUp',
      text: "You won't be able to revert ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, sync it!',
      showLoaderOnConfirm: true,
      preConfirm: async (login) => {
        try {
          const {data} = await axiosInstance.get(`/admin-Shortcomings/${id}`)
          if (!data || data.length === 0) {
            throw new Error(JSON.stringify(data))
          }
          get_data()
        } catch (error) {
          Swal.showValidationMessage(`
                  Request failed: ${error}
              `)
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Done!',
          icon: 'success',
        })
      }
    })
  }

  const columns = [
    {
      Header: '#',
      accessor: 'sort',
      Cell: (row) => <div className='w-100 flex-center '>{row.row.original.sort}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.ID'}),
      accessor: 'item.id',
      Cell: (row) => <div className='w-100 flex-center '># {row.row.original.item.id}</div>,
    },

    {
      Header: intl.formatMessage({id: 'Table.Description'}),
      accessor: 'item.name_en',
      Cell: (row) => (
        <div className='w-300 flex-center fs-12 f-bold'>
          <div>
            <TruncateString text={row.row.original.item.name_en} />
          </div>
          <div>
            <TruncateString text={row.row.original.item.name_ar} />
          </div>
        </div>
      ),
    },
    {
      Header: intl.formatMessage({id: 'Table.File'}),
      accessor: 'File',
      Cell: (row) => (
        <div className='symbol symbol-50px '>
          <img
            src={
              row.row.original.item.img
                ? process.env.REACT_APP_IMAGE_PATH + row.row.original.item.img
                : toAbsoluteUrl('media/avatars/300-6.jpg')
            }
            alt=''
          />
        </div>
      ),
    },

    {
      Header: intl.formatMessage({id: 'Table.Available'}),
      accessor: 'item.available',
      Cell: (row) => (
        <div className='w-100 flex-center'>
          {row.row.original.item.available ? (
            <span className='badge badge-light-success'>
              {intl.formatMessage({id: 'Table.Available'})}
            </span>
          ) : (
            <span className='badge badge-light-danger'>
              {intl.formatMessage({id: 'Table.notAvailable'})}
            </span>
          )}
        </div>
      ),
    },
    {
      Header: intl.formatMessage({id: 'Table.General_Inofrmation'}),
      accessor: 'item.price',
      Cell: (row) => (
        <div className=' flex-center w-200'>
          <div className='row flex-between'>
            <div className='col-7 text-start'>
              <div className='my-2 fs-12'>{intl.formatMessage({id: 'Table.Price'})}</div>
              <div className='my-2 fs-12'>{intl.formatMessage({id: 'Table.Stock'})}</div>
              <div className='my-2 fs-12'>{intl.formatMessage({id: 'Table.MiniStock'})}</div>
              <div className='my-2 fs-12'>{intl.formatMessage({id: 'Table.SalePriority'})}</div>
            </div>
            <div className='col-5 text-start'>
              <div className='my-2 fs-12'>{': ' + row.row.original.item.price.toFixed(1) + ' AED'}</div>
              <div className='my-2 fs-12'>{': ' + row.row.original.item.stock}</div>
              <div className='my-2 fs-12'>{': ' + row.row.original.item.min_stock}</div>
              <div className='my-2 fs-12'>{': ' + row.row.original.item.sale_priority}</div>
            </div>
          </div>
        </div>
      ),
    },

    {
      Header: intl.formatMessage({id: 'Table.Action'}),
      Cell: (row) => (
        <>
          <div className='d-flex flex-center my-2'>
            {check.edit && (
              <Link
                to={`/Products/Edit-Products/${row.row.original.item.id}`}
                className='btn me-2 w-100  btn-light-primary'
              >
                {' '}
                {intl.formatMessage({id: 'Table.Edit'})}{' '}
              </Link>
            )}
            {check.delete && (
              <button
                onClick={(e) => Delete(row.row.original.id)}
                className='btn me-2  w-100 btn-light-danger'
              >
                {' '}
                {intl.formatMessage({id: 'Table.Delete'})}{' '}
              </button>
            )}
          </div>
          {/* <div className="d-flex flex-center my-2">
                        {check.sync && (
                            <button onClick={(e) => sync(row.row.original.item.id)} className='btn me-2 w-100  btn-primary'> {intl.formatMessage({ id: 'Table.Sync' })}  </button>
                        )}
                        {check.edit && (
                            <button onClick={(e) => HanldeSort(row.row.original)} className='btn me-2 w-100  btn-dark'> {intl.formatMessage({ id: 'Table.Sort' })}  </button>
                        )}
                    </div> */}
        </>
      ),
    },
  ]

  useEffect(() => {
    setCheck({
      add: check_role('add-TopSelling'),
      browse: check_role('browse-TopSelling'),
      delete: check_role('delete-TopSelling'),
      edit: check_role('edit-TopSelling'),
      show: check_role('show-TopSelling'),
      sync: check_role('sync-products'),
    })

    if (!check_role('browse-TopSelling')) {
      navigate('/')
    }
    get_data()
  }, [])

  const filteredItems = Items
    ? Items.filter((item) => item.item.name_en.toLowerCase().includes(searchQuery.toLowerCase()))
    : []

    const indexOfLastItem = currentPage * itemsPerPage
    const indexOfFirstItem = indexOfLastItem - itemsPerPage
    const currentItems = filteredItems?.slice(indexOfFirstItem, indexOfLastItem)
  
    // تغيير الصفحة
    const handlePageChange = (event, value) => {
      setCurrentPage(value)
    }
  

  return (
    <motion.nav
      initial={{opacity: 0, x: 100}}
      animate={{opacity: 1, x: 0, transition: {duration: 0.3}}}
      exit={{opacity: 0, x: 100, transition: {duration: 0.2}}}
    >
      <div className=' mb-5 mt-5 d-flex flex-stack'>
        <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
          <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
            {intl.formatMessage({id: 'Menu.TopSelling'})}
          </h1>
          <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
            <li className='breadcrumb-item text-muted'>
              <li className='breadcrumb-item text-muted'>
                {intl.formatMessage({id: 'Menu.Home'})}
              </li>
            </li>
            <li className='breadcrumb-item'>
              <span className='bullet bg-gray-400 w-5px h-2px'></span>
            </li>
            <li className='breadcrumb-item text-muted'>
              {intl.formatMessage({id: 'Menu.Products'})}
            </li>
          </ul>
        </div>
      </div>

      {/*  table */}
      <div className='d-none d-lg-block'>
        {Items !== null && !loading ? (
          <ReactTable columns={columns} data={Items} customComponent={<CustomDiv />} />
        ) : (
          <div className='row'>
            <div className='col-12'>
              <Skeleton
                variant='rounded'
                animation='wave'
                sx={{width: '100%', height: '90vh', marginTop: '1vh'}}
              />
            </div>
          </div>
        )}
      </div>
      
      <div className='d-block d-lg-none'>
        <div className='d-block d-lg-none mb-4'>
          <div className='mb-3 d-flex align-items-center'>
            <CustomDiv />
          </div>
          <input
            type='text'
            placeholder={intl.formatMessage({id: 'Search.Input'})}
            className='form-control'
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        {filteredItems !== null && !loading ? (
          <div className='row'>
            {filteredItems.map((item, index) => (
              <div className='col-12 mb-4' key={item.item.id}>
                <div className='card'>
                  <div className='card-body'>
                    <h5 className='card-title'>#{item.sort}</h5>
                    <p className='card-text'>
                      <strong>{intl.formatMessage({id: 'Table.ID'})}: </strong># {item.item.id}
                    </p>
                    <p className='card-text'>
                      <strong>{intl.formatMessage({id: 'Table.Description'})}: </strong>
                      <TruncateString text={item.item.name_en} />
                      <br />
                      <TruncateString text={item.item.name_ar} />
                    </p>
                    <div className='symbol symbol-50px '>
                      <img
                        src={
                          item.item.img
                            ? process.env.REACT_APP_IMAGE_PATH + item.item.img
                            : toAbsoluteUrl('media/avatars/300-6.jpg')
                        }
                        alt=''
                      />
                    </div>
                    <p className='card-text'>
                      <strong>{intl.formatMessage({id: 'Table.Available'})}: </strong>
                      {item.item.available ? (
                        <span className='badge badge-light-success'>
                          {intl.formatMessage({id: 'Table.Available'})}
                        </span>
                      ) : (
                        <span className='badge badge-light-danger'>
                          {intl.formatMessage({id: 'Table.notAvailable'})}
                        </span>
                      )}
                    </p>
                    <p className='card-text'>
                      <strong>{intl.formatMessage({id: 'Table.General_Inofrmation'})}:</strong>
                      <br />
                      {intl.formatMessage({id: 'Table.Price'})}: {item.item.price.toFixed(1)} AED
                      <br />
                      {intl.formatMessage({id: 'Table.Stock'})}: {item.item.stock}
                      <br />
                      {intl.formatMessage({id: 'Table.MiniStock'})}: {item.item.min_stock}
                      <br />
                      {intl.formatMessage({id: 'Table.SalePriority'})}: {item.item.sale_priority}
                    </p>
                    <div className='d-flex justify-content-between'>
                      {check.edit && (
                        <Link
                          to={`/Products/Edit-Products/${item.item.id}`}
                          className='btn btn-light-primary col-5'
                        >
                          {intl.formatMessage({id: 'Table.Edit'})}
                        </Link>
                      )}
                      {check.delete && (
                        <button
                          onClick={() => Delete(item.id)}
                          className='btn btn-light-danger col-5'
                        >
                          {intl.formatMessage({id: 'Table.Delete'})}
                        </button>
                      )}
                    </div>
                    
                  </div>
                </div>
              </div>
            ))}
          {/* Pagination */}
          <div className='mt-6 d-flex justify-content-center'>
            {filteredItems.length > 0 && (
              <Pagination
                count={Math.ceil(filteredItems.length / itemsPerPage)} // total number of pages
                page={currentPage} // current page
                onChange={handlePageChange}
                color='primary'
              />
            )}
          </div>
          </div>
        ) : (
          <div className='row'>
            <div className='col-12'>
              <Skeleton
                variant='rounded'
                animation='wave'
                sx={{width: '100%', height: '90vh', marginTop: '1vh'}}
              />
            </div>
          </div>
        )}
      </div>
      <AddTopSelling handleClose={setShow_model} get_data={get_data} show={Show_model} />
      <SortModel item={Item} handleClose={setSort_model} get_data={get_data} show={Sort_model} />
    </motion.nav>
  )
}
//get-banners
export default TopSellingHome
