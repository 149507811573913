import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import axiosInstance from '../../../api/axios'
import Swal from 'sweetalert2'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Link, useNavigate} from 'react-router-dom'
import {motion} from 'framer-motion'
import ReactTable from '../../../../_metronic/partials/widgets/React_Table/ReactTable'
import {FormControl, MenuItem, Pagination, Select, Skeleton} from '@mui/material'
import {FaCopy} from 'react-icons/fa'
import {ImportProducts} from './ImportProducts'
import {ExportProduct} from './ExportProduct'
import useAuthContext from '../../../Auth/AuthContext'

const ProductsHome = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [Items, setItems] = useState(null)
  const [Import, setImport] = useState(null)
  const [Export, setExport] = useState(null)
  const [Cats, setCats] = useState([])
  const [cat_id, setCat_id] = useState('all')
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 10

  const {check_role} = useAuthContext()
  const navigate = useNavigate()

  const [searchQuery, setSearchQuery] = useState('')

  const [check, setCheck] = useState({
    browse: false,
    add: false,
    show: false,
    edit: false,
    delete: false,
    sync: false,
    import: false,
    export: false,
  })

  const CustomDiv = () => (
    <div className='row justify-content-between align-items-center w-100'>
      <div className='col-12 col-md-3 mb-3 mb-md-0'>
        <FormControl fullWidth>
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            onChange={(e) => setCat_id(e.target.value)}
            value={cat_id}
          >
            <MenuItem value='all'>All</MenuItem>
            {Cats.map((row, key) => (
              <MenuItem key={key} value={row.id}>
                {row.name_en}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div className='col-12 col-md-3 mb-3 mb-md-0 text-center text-md-start'>
        {check.import && (
          <button
            onClick={(e) => setImport(true)}
            type='button'
            className='btn py-3 py-md-5 btn-light-primary'
          >
            <KTSVG path='/media/icons/duotune/files/fil022.svg' className='svg-icon-2' />
            {intl.formatMessage({id: 'Table.Import'})}
          </button>
        )}
      </div>

      <div className='col-12 col-md-3 mb-3 mb-md-0 text-center text-md-start'>
        {check.export && (
          <button
            onClick={(e) => setExport(true)}
            type='button'
            className='btn py-3 py-md-5 btn-light-primary'
          >
            <KTSVG path='/media/icons/duotune/files/fil021.svg' className='svg-icon-2' />
            {intl.formatMessage({id: 'Table.Export'})}
          </button>
        )}
      </div>

      <div className='col-12 col-md-3 text-center text-md-start'>
        {check.add && (
          <Link
            to='/Products/Add-Products'
            type='button'
            className='btn py-3 py-md-4 mt-1 btn-primary'

          >
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            {intl.formatMessage({id: 'Products.Create'})}
          </Link>
        )}
      </div>
    </div>
  )

  const TruncateString = ({text}) => {
    const truncatedText = text ? text.slice(0, 50) : ''
    return truncatedText
  }

  const get_data = async () => {
    await axiosInstance.get('/admin-cats').then((res) => setCats(res.data))

    setLoading(true)
    const {data} = await axiosInstance.get('/Get-All-Items', {
      params: {cat_id: cat_id},
    })
    setItems(data)
    setLoading(false)
  }

  const Delete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      showLoaderOnConfirm: true,
      preConfirm: async (login) => {
        try {
          const {data} = await axiosInstance.delete(`/admin-items/${id}`)
          if (!data || data.length === 0) {
            throw new Error(JSON.stringify(data))
          }
          get_data()
        } catch (error) {
          Swal.showValidationMessage(`
                  Request failed: ${error}
              `)
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Done!',
          icon: 'success',
        })
      }
    })
  }

  const sync = (id) => {
    Swal.fire({
      title: 'LookUp',
      text: "You won't be able to revert ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, sync it!',
      showLoaderOnConfirm: true,
      preConfirm: async (login) => {
        try {
          const {data} = await axiosInstance.get(`/admin-Shortcomings/${id}`)
          if (!data || data.length === 0) {
            throw new Error(JSON.stringify(data))
          }
          get_data()
        } catch (error) {
          Swal.showValidationMessage(`
                  Request failed: ${error}
              `)
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Done!',
          icon: 'success',
        })
      }
    })
  }

  const edit_status = async (value, id) => {
    let endpoint
    endpoint = `/change-item-status/${id}/${value}`
    try {
      await axiosInstance.get(endpoint)
      get_data()
    } catch (error) {
      console.error(error)
    }
  }

  const columns = [
    {
      Header: intl.formatMessage({id: 'Table.ID'}),
      accessor: 'id',
      Cell: (row) => (
        <div className='w-100 flex-center '>
          <div># {row.row.original.id}</div>
          <div># {row.row.original.product_id}</div>
        </div>
      ),
    },
    {
      Header: intl.formatMessage({id: 'Table.Supplier'}),
      accessor: 'type',
      Cell: (row) => <div className=' w-100 flex-center'>{row.row.original.type ?? '---'}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.Description'}),
      accessor: 'name_en',
      Cell: (row) => (
        <div className='w-300 flex-center fs-12 f-bold'>
          <div>
            <TruncateString text={row.row.original.name_en} />
          </div>
          <div>
            <TruncateString text={row.row.original.name_ar} />
          </div>
        </div>
      ),
    },
    {
      Header: intl.formatMessage({id: 'Table.File'}),
      accessor: 'File',
      Cell: (row) => (
        <div className='symbol symbol-50px '>
          <img
            src={
              row.row.original.img
                ? process.env.REACT_APP_IMAGE_PATH + row.row.original.img
                : toAbsoluteUrl('media/avatars/300-6.jpg')
            }
            alt=''
          />
        </div>
      ),
    },
    {
      Header: 'enable',
      accessor: 'status',
      Cell: (row) => (
        <div className='form-check mt-2 form-switch form-switch-sm form-check-custom form-check-solid flex-center'>
          {check.edit && (
            <input
              className='form-check-input'
              type='checkbox'
              value={row.row.original.status}
              name='notifications'
              defaultChecked={row.row.original.status}
              onClick={() => edit_status(!row.row.original.status, row.row.original.id)}
            />
          )}
        </div>
      ),
    },
    {
      Header: intl.formatMessage({id: 'Table.Available'}),
      accessor: 'available',
      Cell: (row) => (
        <div className='w-100 flex-center'>
          {row.row.original.available ? (
            <span className='badge badge-light-success'>
              {intl.formatMessage({id: 'Table.Available'})}
            </span>
          ) : (
            <span className='badge badge-light-danger'>
              {intl.formatMessage({id: 'Table.notAvailable'})}
            </span>
          )}
        </div>
      ),
    },
    {
      Header: intl.formatMessage({id: 'Table.General_Inofrmation'}),
      accessor: 'General_Inofrmation',
      Cell: (row) => (
        <div className=' flex-center w-200'>
          <div className='row flex-between'>
            <div className='col-7 text-start'>
              <div className='my-2 fs-12'>{intl.formatMessage({id: 'Table.Price'})}</div>
              <div className='my-2 fs-12'>{intl.formatMessage({id: 'Table.Stock'})}</div>
              <div className='my-2 fs-12'>{intl.formatMessage({id: 'Table.MiniStock'})}</div>
              <div className='my-2 fs-12'>{intl.formatMessage({id: 'Table.SalePriority'})}</div>
            </div>
            <div className='col-5 text-start'>
              <div className='my-2 fs-12'>{': ' + row.row.original.price.toFixed(1)+' AED'}</div>
              <div className='my-2 fs-12'>{': ' + row.row.original.stock}</div>
              <div className='my-2 fs-12'>{': ' + row.row.original.min_stock}</div>
              <div className='my-2 fs-12'>{': ' + row.row.original.sale_priority}</div>
            </div>
          </div>
        </div>
      ),
    },

    {
      Header: intl.formatMessage({id: 'Table.Action'}),
      Cell: (row) => (
        <>
          <div className='d-flex flex-center my-2'>
            {check.edit && (
              <Link
                to={`/Products/Edit-Products/${row.row.original.id}`}
                className='btn me-2 w-100  btn-light-primary'
              >
                {' '}
                {intl.formatMessage({id: 'Table.Edit'})}{' '}
              </Link>
            )}
            {check.delete && (
              <button
                onClick={(e) => Delete(row.row.original.id)}
                className='btn me-2  w-100 btn-light-danger'
              >
                {' '}
                {intl.formatMessage({id: 'Table.Delete'})}{' '}
              </button>
            )}
          </div>
          <div className='d-flex flex-center my-2'>
            {check.sync && (
              <button
                onClick={(e) => sync(row.row.original.id)}
                className='btn me-2 w-100  btn-light-dark'
              >
                {' '}
                {intl.formatMessage({id: 'Table.Sync'})}{' '}
              </button>
            )}
          </div>
        </>
      ),
    },
  ]

  useEffect(() => {
    setCheck({
      browse: check_role('browse-products'),
      add: check_role('add-products'),
      show: check_role('show-products'),
      edit: check_role('edit-products'),
      delete: check_role('delete-products'),
      sync: check_role('sync-products'),
      export: check_role('export-products'),
      import: check_role('import-products'),
    })

    if (!check_role('browse-products')) {
      navigate('/')
    }
    get_data()
  }, [cat_id])
  const filteredItems = Items
    ? Items.filter(
        (item) =>
          item.name_en.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.name_ar.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : []

  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = filteredItems?.slice(indexOfFirstItem, indexOfLastItem)

  // تغيير الصفحة
  const handlePageChange = (event, value) => {
    setCurrentPage(value)
  }

  return (
    <motion.nav
      initial={{opacity: 0, x: 100}}
      animate={{opacity: 1, x: 0, transition: {duration: 0.3}}}
      exit={{opacity: 0, x: 100, transition: {duration: 0.2}}}
    >
      <div className='mb-5 mt-5 d-flex flex-stack'>
        <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
          <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
            {intl.formatMessage({id: 'Menu.Products'})}
          </h1>
          <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
            <li className='breadcrumb-item text-muted'>
              <li className='breadcrumb-item text-muted'>
                {intl.formatMessage({id: 'Menu.Home'})}
              </li>
            </li>
            <li className='breadcrumb-item'>
              <span className='bullet bg-gray-400 w-5px h-2px'></span>
            </li>
            <li className='breadcrumb-item text-muted'>
              {intl.formatMessage({id: 'Menu.Products'})}
            </li>
          </ul>
        </div>
      </div>

      {/*  table */}
      <div className='d-none d-lg-block'>
        {Items !== null && !loading ? (
          <ReactTable columns={columns} data={Items} customComponent={<CustomDiv />} />
        ) : (
          <div className='row'>
            <div className='col-12'>
              <Skeleton
                variant='rounded'
                animation='wave'
                sx={{width: '100%', height: '90vh', marginTop: '1vh'}}
              />
            </div>
          </div>
        )}
      </div>
      {/* Card */}
      <div className='d-block d-lg-none'>
        <div className='row mb-3'>
          <div className='col-12'>
            <div className='mb-5'>
              <CustomDiv />
            </div>
            <input
              type='text'
              className='form-control'
              placeholder={intl.formatMessage({id: 'Search.Input'})}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>

        <div className='row'>
          {currentItems.length > 0 ? (
            currentItems.map((item, index) => (
              <div className='col-12 mb-4' key={index}>
                <div className='card shadow-sm'>
                  <div className='card-body'>
                    <div className='d-flex justify-content-between align-items-center mb-3'>
                      <h5 className='card-title m-0'>
                        #{item.id} / {item.product_id}
                      </h5>
                      <div className='symbol symbol-50px'>
                        <img
                          src={
                            item.img
                              ? process.env.REACT_APP_IMAGE_PATH + item.img
                              : toAbsoluteUrl('media/avatars/300-6.jpg')
                          }
                          alt=''
                          className='img-fluid rounded'
                        />
                      </div>
                    </div>

                    {/* Product Details */}
                    <div className='mb-2'>
                      <p className='card-text m-0'>
                        <strong>
                          {item.name_en} / {item.name_ar}
                        </strong>
                      </p>
                      <p className='card-text m-0'>Type: {item.type ?? '---'}</p>
                      <p className='card-text m-0'>Price: {item.price.toFixed(1)} AED</p>
                      <p className='card-text m-0'>Stock: {item.stock}</p>
                      <p className='card-text m-0'>Min Stock: {item.min_stock}</p>
                      <p className='card-text m-0'>Sale Priority: {item.sale_priority}</p>
                    </div>

                    {/* Enable Switch */}
                    <div className='d-flex justify-content-between align-items-center'>
                      <span className='form-label'>Enable</span>
                      <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                        {check.edit && (
                          <input
                            className='form-check-input'
                            type='checkbox'
                            value={item.status}
                            name='notifications'
                            defaultChecked={item.status}
                            onClick={() => edit_status(!item.status, item.id)}
                          />
                        )}
                      </div>
                    </div>

                    {/* Availability Badge */}
                    <div className='mt-3'>
                      <span
                        className={`badge ${item.available ? 'badge-success' : 'badge-danger'}`}
                      >
                        {item.available ? 'Available' : 'Not Available'}
                      </span>
                    </div>

                    {/* Action Buttons */}
                    <div className='mt-4 d-flex justify-content-between'>
                      {check.edit && (
                        <Link to={`/Products/Edit-Products/${item.id}`} className='btn btn-primary'>
                          {intl.formatMessage({id: 'Table.Edit'})}
                        </Link>
                      )}
                      {check.delete && (
                        <button onClick={() => Delete(item.id)} className='btn btn-danger'>
                          {intl.formatMessage({id: 'Table.Delete'})}
                        </button>
                      )}
                      {check.sync && (
                        <button onClick={() => sync(item.id)} className='btn btn-dark'>
                          {intl.formatMessage({id: 'Table.Sync'})}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className='text-center my-5'>
            {/* Spinner for loading effect */}
            <div className='spinner-border text-primary mb-3' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>

            {/* No Data message */}
            <p className='text-muted fs-4'>
              <i className='fas fa-exclamation-circle me-2'></i>
              {intl.formatMessage({id: 'Table.NoData'})}
            </p>
          </div>          )}

          {/* Pagination */}
          <div className='mt-6 d-flex justify-content-center'>
            {filteredItems.length > itemsPerPage && (
              <Pagination
                count={Math.ceil(filteredItems.length / itemsPerPage)} // حساب عدد الصفحات
                page={currentPage}
                onChange={handlePageChange}
                color='primary'
              />
            )}
          </div>
        </div>
      </div>

      <ImportProducts handleClose={setImport} get_data={get_data} show={Import} />
      <ExportProduct handleClose={setExport} get_data={get_data} show={Export} />
    </motion.nav>
  )
}
export default ProductsHome
