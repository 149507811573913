import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

export const OwnerChartMonth = ({ names, numbers }) => {
    const [state, setState] = useState({
        options: {
          chart: {
            id: 'basic-area',
            type: 'area',
            height: 10,
            zoom: {
              enabled: false
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'smooth'
          },
          xaxis: {
            categories: names
          },
          yaxis: {
            opposite: true
          },
          legend: {
            horizontalAlign: 'left'
          },
          grid: {
            padding: {
              top: 10,
              right: 20,
              bottom: 10,
              left: 20
            }
          }
        },
        series: [
          {
            name: 'Orders',
            data: numbers
          }
        ]
      });

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      options: {
        ...prevState.options,
        xaxis: {
          categories: names
        }
      },
      series: [
        {
          name: 'Orders',
          data: numbers
        }
      ]
    }));
  }, [names, numbers]);

  return (
    <div>
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="area"
        width="100%"
        height={500}
      />
    </div>
  );
};
