import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import axiosInstance from '../../../../api/axios'
import Swal from 'sweetalert2'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {Link, useNavigate} from 'react-router-dom'
import ReactTable from '../../../../../_metronic/partials/widgets/React_Table/ReactTable'
import {Skeleton, Pagination} from '@mui/material'
import useAuthContext from '../../../../Auth/AuthContext'

const BannersHome = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [Items, setItems] = useState([])
  const [filteredItems, setFilteredItems] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  
  const {check_role} = useAuthContext()
  const navigate = useNavigate()

  const [check, setCheck] = useState({
    browse: false,
    add: false,
    show: false,
    edit: false,
    delete: false,
  })

  const [currentPage, setCurrentPage] = useState(1) // State for current page
  const itemsPerPage = 10 // Number of items per page

  const CustomDiv = () => (
    <div className='row flex-end w-100'>
      {check.add && (
        <div className='col-auto'>
          <Link to='/WebDetails/Add-Banners' type='button' className='btn btn-primary'>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            {intl.formatMessage({id: 'Banner.Create'})}
          </Link>
        </div>
      )}
    </div>
  )

  const get_data = async () => {
    try {
      setLoading(true)
      const {data} = await axiosInstance.get('/get-banners')
      setItems(data)
      setFilteredItems(data) // Initially set all items as filtered
    } catch (error) {
      console.error('Error fetching data', error)
    } finally {
      setLoading(false)
    }
  }

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase()
    setSearchTerm(value)
    const filteredData = Items.filter(
      (item) =>
        item.name_en.toLowerCase().includes(value) ||
        item.name_ar.toLowerCase().includes(value) ||
        item.type.toLowerCase().includes(value)
    )
    setFilteredItems(filteredData)
  }

  const Delete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          await axiosInstance.delete(`/admin-details/${id}`)
          get_data()
        } catch (error) {
          Swal.showValidationMessage(`Request failed: ${error.message}`)
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire('Deleted!', 'Your file has been deleted.', 'success')
      }
    })
  }

  const columns = [
    {
      Header: intl.formatMessage({id: 'Table.ID'}),
      accessor: 'id',
      Cell: (row) => <div className='w-100 flex-center'># {row.row.original.id}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.DescriptionEN'}),
      accessor: 'name_en',
      Cell: (row) => <div className='w-100 flex-center'>{row.row.original.name_en}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.DescriptionAR'}),
      accessor: 'name_ar',
      Cell: (row) => <div className='w-100 flex-center'>{row.row.original.name_ar}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.Type'}),
      accessor: 'type',
      Cell: (row) => <div className='w-100 flex-center'>{row.row.original.type}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.File'}),
      accessor: 'img',
      Cell: (row) => (
        <div className='symbol symbol-50px'>
          <img
            src={
              row.row.original.img
                ? `${process.env.REACT_APP_IMAGE_PATH}${row.row.original.img}`
                : toAbsoluteUrl('media/avatars/300-6.jpg')
            }
            alt=''
            className='img-fluid'
          />
        </div>
      ),
    },
    {
      Header: intl.formatMessage({id: 'Table.Action'}),
      Cell: (row) => (
        <div className='d-flex flex-center'>
          {check.edit && (
            <Link
              to={`/WebDetails/Edit-Banners/${row.row.original.id}`}
              className='btn me-2 btn-light-primary'
            >
              {intl.formatMessage({id: 'Table.Edit'})}
            </Link>
          )}
          {check.delete && (
            <button
              onClick={() => Delete(row.row.original.id)}
              className='btn me-2 btn-light-danger'
            >
              {intl.formatMessage({id: 'Table.Delete'})}
            </button>
          )}
        </div>
      ),
    },
  ]

  useEffect(() => {
    setCheck({
      browse: check_role('browse-webDetails'),
      add: check_role('add-webDetails'),
      show: check_role('show-webDetails'),
      edit: check_role('edit-webDetails'),
      delete: check_role('delete-webDetails'),
    })

    if (!check_role('browse-webDetails')) {
      navigate('/')
    } else {
      get_data()
    }
  }, [])

  // Pagination logic for Card View only
  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = filteredItems ? filteredItems.slice(indexOfFirstItem, indexOfLastItem) : []

  const handlePageChange = (event, value) => {
    setCurrentPage(value)
  }

  return (
    <nav>
      <div className='mb-5 mt-5 d-flex flex-stack'>
        <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
          <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
            {intl.formatMessage({id: 'Menu.Banners'})}
          </h1>
          <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
            <li className='breadcrumb-item text-muted'>{intl.formatMessage({id: 'Menu.Home'})}</li>
            <li className='breadcrumb-item'>
              <span className='bullet bg-gray-400 w-5px h-2px'></span>
            </li>
            <li className='breadcrumb-item text-muted'>
              {intl.formatMessage({id: 'Menu.Banners'})}
            </li>
          </ul>
        </div>
      </div>

      {/* Large Screen Table */}
      <div className='d-none d-md-block'>
        {!loading ? (
          <ReactTable columns={columns} data={filteredItems} customComponent={<CustomDiv />} />
        ) : (
          <Skeleton variant='rounded' animation='wave' sx={{width: '100%', height: '90vh'}} />
        )}
      </div>

      <div className='d-block d-md-none'>
        {!loading ? (
          <>
            <div className='mb-4'>
              <div className="mb-3">
                <CustomDiv />
              </div>
              <input
                type='text'
                className='form-control'
                placeholder={intl.formatMessage({id: 'Search.Input'})}
                value={searchTerm}
                onChange={handleSearch}
              />
            </div>

            <div className='row'>
              {currentItems.map((item) => (
                <div className='col-12 mb-4' key={item.id}>
                  <div className='card'>
                    <div className='card-body'>
                      <h5 className='card-title'>{`# ${item.id}`}</h5>
                      <p className='card-text'>
                        <strong>{intl.formatMessage({id: 'Table.DescriptionEN'})}:</strong>{' '}
                        {item.name_en}
                      </p>
                      <p className='card-text'>
                        <strong>{intl.formatMessage({id: 'Table.DescriptionAR'})}:</strong>{' '}
                        {item.name_ar}
                      </p>
                      <p className='card-text'>
                        <strong>{intl.formatMessage({id: 'Table.Type'})}:</strong> {item.type}
                      </p>
                      <div className='symbol symbol-50px'>
                        <img
                          src={
                            item.img
                              ? `${process.env.REACT_APP_IMAGE_PATH}${item.img}`
                              : toAbsoluteUrl('media/avatars/300-6.jpg')
                          }
                          alt=''
                          className='img-fluid'
                        />
                      </div>
                      <div className='d-flex justify-content-center mt-3'>
                        {check.edit && (
                          <Link
                            to={`/WebDetails/Edit-Banners/${item.id}`}
                            className='btn btn-light-primary me-2 col-5'
                          >
                            {intl.formatMessage({id: 'Table.Edit'})}
                          </Link>
                        )}
                        {check.delete && (
                          <button
                            onClick={() => Delete(item.id)}
                            className='btn btn-light-danger col-5'
                          >
                            {intl.formatMessage({id: 'Table.Delete'})}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Pagination for Cards */}
            <div className='mt-6 d-flex justify-content-center'>
              <Pagination
                count={Math.ceil(filteredItems.length / itemsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                color='primary'
              />
            </div>
          </>
        ) : (
          <Skeleton variant='rounded' animation='wave' sx={{width: '100%', height: '90vh'}} />
        )}
      </div>
    </nav>
  )
}

export default BannersHome
