import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import axiosInstance from '../../../api/axios'
import Swal from 'sweetalert2'
import {KTSVG} from '../../../../_metronic/helpers'
import {Link, useNavigate} from 'react-router-dom'
import {motion} from 'framer-motion'
import ReactTable from '../../../../_metronic/partials/widgets/React_Table/ReactTable'
import {Skeleton} from '@mui/material'
import Pagination from '@mui/material/Pagination' 
import useAuthContext from '../../../Auth/AuthContext'

const ConfigsHome = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [Items, setItems] = useState(null)
  const [searchTerm, setSearchTerm] = useState('') 
  const [currentPage, setCurrentPage] = useState(1) 
  const [itemsPerPage] = useState(10) 

  const navigate = useNavigate()
  const {check_role} = useAuthContext()

  const [check, setCheck] = useState({
    'browse-env': false,
    'add-env': false,
    'show-env': false,
    'edit-env': false,
    'delete-env': false,
  })

  const CustomDiv = () => (
    <div className='row flex-end w-100'>
      {check.add && (
        <div className='col-auto'>
          <Link to='/Developers/Add-Configs' type='button' className='btn btn-primary'>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            {intl.formatMessage({id: 'Configs.Create'})}
          </Link>
        </div>
      )}
      {check.edit && (
        <div className='col-3'>
          <button onClick={sync} type='button' className='btn btn-primary w-100'>
            {intl.formatMessage({id: 'Table.Sync'})}
          </button>
        </div>
      )}
    </div>
  )

  const get_data = async () => {
    setLoading(true)
    const {data} = await axiosInstance.get('/admin-configs')
    setItems(data)
    setLoading(false)
  }

  const Delete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      showLoaderOnConfirm: true,
      preConfirm: async (login) => {
        try {
          const {data} = await axiosInstance.delete(`/admin-configs/${id}`)
          get_data()
        } catch (error) {
          Swal.showValidationMessage(`Request failed: ${error}`)
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({title: 'Done!', icon: 'success'})
      }
    })
  }

  const sync = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Sync it!',
      showLoaderOnConfirm: true,
      preConfirm: async (login) => {
        try {
          setLoading(true)
          const {data} = await axiosInstance.get(`/admin-sync-config`)
          get_data()
        } catch (error) {
          setLoading(false)
          Swal.showValidationMessage(`Request failed: ${error}`)
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({title: 'Done!', icon: 'success'})
      }
    })
  }

  const columns = [
    {
      Header: intl.formatMessage({id: 'Table.Key'}),
      accessor: 'key',
      Cell: (row) => <div className='w-100 flex-center'>{row.row.original?.key}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.Value'}),
      accessor: 'value',
      Cell: (row) => <div className='w-100 flex-center'>{row.row.original?.value}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.Action'}),
      Cell: (row) => (
        <div className='d-flex flex-center'>
          {check.edit && (
            <Link
              to={`/Developers/Edit-Configs/${row.row.original.id}`}
              className='btn me-2 btn-light-primary'
            >
              {intl.formatMessage({id: 'Table.Edit'})}
            </Link>
          )}
          {check.delete && (
            <button
              onClick={() => Delete(row.row.original?.id)}
              className='btn me-2 btn-light-danger'
            >
              {intl.formatMessage({id: 'Table.Delete'})}
            </button>
          )}
        </div>
      ),
    },
  ]

  useEffect(() => {
    setCheck({
      browse: check_role('browse-configs'),
      add: check_role('add-configs'),
      show: check_role('show-configs'),
      edit: check_role('edit-configs'),
      delete: check_role('delete-configs'),
    })

    if (!check_role('browse-configs')) {
      navigate('/')
    }
    get_data()
  }, [])

  const filteredItems = Items
    ? Items.filter((item) => item.key.toLowerCase().includes(searchTerm.toLowerCase()))
    : []

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem)

  const handlePageChange = (event, value) => {
    setCurrentPage(value)
  }

  return (
    <motion.nav
      initial={{opacity: 0, x: 100}}
      animate={{opacity: 1, x: 0, transition: {duration: 0.3}}}
      exit={{opacity: 0, x: 100, transition: {duration: 0.2}}}
    >
      <div className='mb-5 mt-5 d-flex flex-stack'>
        <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
          <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
            {intl.formatMessage({id: 'Menu.Configs'})}
          </h1>
          <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
            <li className='breadcrumb-item text-muted'>{intl.formatMessage({id: 'Menu.Home'})}</li>
            <li className='breadcrumb-item'>
              <span className='bullet bg-gray-400 w-5px h-2px'></span>
            </li>
            <li className='breadcrumb-item text-muted'>
              {intl.formatMessage({id: 'Menu.Configs'})}
            </li>
          </ul>
        </div>
      </div>

      {/* Large screen table view */}
      <div className='d-none d-lg-block'>
        {Items !== null && !loading ? (
          <ReactTable columns={columns} data={Items} customComponent={<CustomDiv />} />
        ) : (
          <div className='row'>
            <div className='col-12'>
              <Skeleton
                variant='rounded'
                animation='wave'
                sx={{width: '100%', height: '90vh', marginTop: '1vh'}}
              />
            </div>
          </div>
        )}
      </div>

      {/* Small screen card view */}
      <div className='d-lg-none'>
        {/* Search Input */}
        <div className='row mb-3'>
          <div className="mb-3">
            <CustomDiv />
          </div>
          <div className='col-12'>
            <input
              type='text'
              className='form-control'
              placeholder={intl.formatMessage({id: 'Table.Search'})}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>

        {currentItems.length > 0 && !loading ? (
          <div className='row'>
            {currentItems.map((item) => (
              <div className='col-12 mb-3' key={item.id}>
                <div className='card'>
                  <div className='card-body'>
                    <h5 className='card-title'>{item.key}</h5>
                    <p className='card-text'>{item.value}</p>
                    <div className='d-flex flex-center'>
                      {check.edit && (
                        <Link
                          to={`/Developers/Edit-Configs/${item.id}`}
                          className='btn me-2 btn-light-primary col-5'
                        >
                          {intl.formatMessage({id: 'Table.Edit'})}
                        </Link>
                      )}
                      {check.delete && (
                        <button
                          onClick={() => Delete(item.id)}
                          className='btn me-2 btn-light-danger col-5'
                        >
                          {intl.formatMessage({id: 'Table.Delete'})}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className='row'>
            <div className='col-12'>
              <Skeleton
                variant='rounded'
                animation='wave'
                sx={{width: '100%', height: '90vh', marginTop: '1vh'}}
              />
            </div>
          </div>
        )}

        {/* Pagination for Cards */}
        <div className='mt-6 d-flex justify-content-center'>
          <Pagination
            count={Math.ceil(filteredItems.length / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            color='primary'
          />
        </div>
      </div>
    </motion.nav>
  )
}

export default ConfigsHome
