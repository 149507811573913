import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Link, useNavigate, useParams} from 'react-router-dom'
import axiosInstance from '../../../../api/axios'
import Swal from 'sweetalert2'
import {motion} from 'framer-motion'
import {KTSVG} from '../../../../../_metronic/helpers'
import ReactTable from '../../../../../_metronic/partials/widgets/React_Table/ReactTable'
import {FormControl, MenuItem, Select} from '@mui/material'
import useAuthContext from '../../../../Auth/AuthContext'

export const UpdateRoles = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [fileName, setFileName] = useState('No selected file')
  const navigate = useNavigate()
  const [imported, setImported] = useState([])
  const [Items, setItems] = useState([])
  const {id} = useParams()
  const [category_id, setCategory_ID] = useState('all')
  const [Cats, setCats] = useState([])

  const {check_role} = useAuthContext()
  const [data, setData] = useState({
    name_en: null,
    name_ar: null,
  })

  const handleChange = (e) => {
    const {name, value} = e.target
    setData((prevData) => ({...prevData, [name]: value}))
  }

  const handleEdit_Add_submit = async (event) => {
    setLoading(true)
    event.preventDefault()
    const formData = new FormData()
    formData.append('enctype', 'multipart/form-data')
    formData.append('_method', 'Patch')

    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value)
    })
    formData.append('data', JSON.stringify(imported))
    try {
      await axiosInstance.post(`/admin-roles/${id}`, formData).then((response) => {
        Swal.fire({
          title: 'Success',
          icon: 'success',
          confirmButtonText: 'OK',
        })
        setLoading(false)
        setErrors([])
        navigate(-1)
      })
    } catch (e) {
      console.log(e.response.data.errors)
      if (e.response.status === 422) {
        setLoading(false)
        setErrors(e.response.data.errors)
      }
    }
  }

  const get_data = async () => {
    setItems([]) // Clear previous items
    const [getRoles, get_permissions, get_categories] = await Promise.all([
      axiosInstance.get(`/admin-roles/${id}`),
      axiosInstance.get('/admin-permissions', {
        params: {type: category_id},
      }),
      axiosInstance.get('/admin-permissions-categories'),
    ])
  
    const get_roles = getRoles.data
    setData({
      name_en: get_roles.name_en,
      name_ar: get_roles.name_ar,
    })
  
    if (get_roles.permissions) {
      const allItems = get_roles.permissions.map((item) => ({
        id: item.id,
        name_en: item.name_en,
        name_ar: item.name_ar,
      }))
      setImported(allItems)
      setItems((prevItems) => [...prevItems, ...allItems])
    }
    
    setItems((prevItems) => {
      const newPermissions = get_permissions.data.filter(item => !prevItems.some(prevItem => prevItem.id === item.id))
      return [...prevItems, ...newPermissions]
    })
      
    setCats([{id: 'all', name_en: 'All'}])
    setCats((prevItems) => [...prevItems, ...get_categories.data])
  }
  


  const add_item = (item) => {
    const rowsInput = {
      id: item.id,
      name_en: item.name_en,
      name_ar: item.name_ar,
    }
    setImported((prevImported) => [...prevImported, rowsInput])
  }

  const remove_item = (item) => {
    setImported((prevImported) =>
      prevImported.filter((importedItem) => importedItem.id !== item.id)
    )
  }

  const handle_change = (e, item) => {
    if (e.target.checked) {
      add_item(item)
    } else {
      remove_item(item)
    }
  }

  const isChecked = (id) => {
    return imported.some((importedItem) => importedItem.id === id)
  }

  const CustomDiv = () => (
    <div className="row justify-content-between align-items-center w-100">
      <div className="col-12 col-md-5 mb-3 mb-md-0">
        <FormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            onChange={(e) => setCategory_ID(e.target.value)}
            value={category_id}
          >
            {Cats.map((row, key) => (
              <MenuItem key={key} value={row.id}>
                {row.name_en}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="col-12 col-md-auto">
        <Link to="/Roles/Add-Permissions" type="button" className="btn btn-primary w-100 w-md-auto py-4">
          <KTSVG path="/media/icons/duotune/arrows/arr075.svg" className="svg-icon-2" />
          {intl.formatMessage({ id: 'Permissions.Create' })}
        </Link>
      </div>
    </div>
  );
  

  const columns = [
    {
      Header: intl.formatMessage({id: 'Table.ID'}),
      accessor: 'productID',
      Cell: (row) => (
        <div className='w-100 flex-center'>
          <div className='form-check form-check-sm form-check-custom form-check-solid flex-center'>
            <input
              className='form-check-input widget-9-check me-2'
              onChange={(e) => {
                handle_change(e, row.row.original)
              }}
              type='checkbox'
              checked={isChecked(row.row.original.id)}
            />
            # {row.row.original.id}
          </div>
        </div>
      ),
    },
    {
      Header: intl.formatMessage({id: 'Table.DescriptionEN'}),
      accessor: 'name_en',
      Cell: ({row}) => <div className='w-100 flex-center'>{row.original.name_en}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.DescriptionAR'}),
      accessor: 'name_ar',
      Cell: ({row}) => <div className='w-100 flex-center'>{row.original.name_ar}</div>,
    },
  ]

  useEffect(() => {
    if (!check_role('show-roles')) {
      navigate('/')
    }

    get_data()
  }, [category_id])

  return (
    <motion.nav
      initial={{opacity: 0, x: 100}}
      animate={{opacity: 1, x: 0, transition: {duration: 0.3}}}
      exit={{opacity: 0, x: 100, transition: {duration: 0.2}}}
    >
      <div className=' mb-5 mt-5 d-flex flex-stack'>
        <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
          <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
            {intl.formatMessage({id: 'Roles.Edit'})}
          </h1>
          <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
            <li className='breadcrumb-item text-muted'>
              <span className='text-muted text-hover-primary'>
                {' '}
                {intl.formatMessage({id: 'Menu.Home'})}
              </span>
            </li>
            <li className='breadcrumb-item'>
              <span className='bullet bg-gray-400 w-5px h-2px'></span>
            </li>
            <li className='breadcrumb-item text-muted'>{intl.formatMessage({id: 'Menu.Roles'})}</li>
          </ul>
        </div>
        <div>
          <button onClick={(e) => navigate(-1)} type='button' className='btn btn-primary'>
            <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon-2' />
            {intl.formatMessage({id: 'Form.GoBack'})}
          </button>
        </div>
      </div>
      <div id='kt_app_content_container' className='app-container container-xxl'>
        <form
          onSubmit={handleEdit_Add_submit}
          id='kt_ecommerce_add_category_form'
          className='form d-flex flex-column flex-lg-row fv-plugins-bootstrap5 fv-plugins-framework'
          data-kt-redirect='../../demo1/dist/apps/ecommerce/catalog/categories.html'
        >
          <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
            <div className='card card-flush py-4'>
              <div className='card-header'>
                <div className='card-title'>
                  <h2>{intl.formatMessage({id: 'Form.General'})} </h2>
                </div>
              </div>

              <div className='card-body pt-0'>
                <div className='row flex-between'>
                  <div className='mb-3 col-6 fv-row fv-plugins-icon-container'>
                    <label className='required form-label'>
                      {' '}
                      {intl.formatMessage({id: 'Form.NameEN'})}{' '}
                    </label>
                    <input
                      type='text'
                      name='name_en'
                      className='form-control mb-2'
                      value={data.name_en}
                      onChange={handleChange}
                      placeholder={intl.formatMessage({id: 'Form.NameEN'})}
                    />
                    <div className='fv-plugins-message-container invalid-feedback' />
                    {errors.name_en && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{errors.name_en}</span>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className='mb-3 col-6 fv-row fv-plugins-icon-container'>
                    <label className='required form-label'>
                      {' '}
                      {intl.formatMessage({id: 'Form.NameAR'})}
                    </label>
                    <input
                      type='text'
                      name='name_ar'
                      className='form-control mb-2'
                      value={data.name_ar}
                      onChange={handleChange}
                      placeholder={intl.formatMessage({id: 'Form.NameAR'})}
                    />
                    <div className='fv-plugins-message-container invalid-feedback' />
                    {errors.name_ar && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{errors.name_ar}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='card my-2'>
              <ReactTable columns={columns} data={Items} customComponent={<CustomDiv />} />
            </div>
            <div className='d-flex justify-content-end '>
              {check_role('edit-roles') && (
                <button type='submit' className='btn btn-primary'>
                  {!loading && (
                    <span className='indicator-label'>
                      {intl.formatMessage({id: 'Form.Edit'})}{' '}
                    </span>
                  )}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      {intl.formatMessage({id: 'Form.Pleasewait'})}{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </motion.nav>
  )
}
