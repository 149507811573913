import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import axiosInstance from '../../../api/axios'
import {Link, useNavigate} from 'react-router-dom'
import {motion} from 'framer-motion'
import ReactTable from '../../../../_metronic/partials/widgets/React_Table/ReactTable'
import {Skeleton} from '@mui/material'
import {Images} from '../../../../_metronic/partials/widgets/React_Table/Images'
import ReactTableWithPagention from '../../../../_metronic/partials/widgets/React_Table/ReactTableWithPagention'
import useAuthContext from '../../../Auth/AuthContext'

const PendingOrdersHome = () => {
  const {pending_order, playSound, setPenidng_orders, check_role} = useAuthContext()
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [inputValue, setInputValue] = useState(null)

  const navigate = useNavigate()

  const [check, setCheck] = useState({
    show: false,
  })

  const getData = async () => {
    const {data} = await axiosInstance.get(`/admin-pending-orders?page=${currentPage}`, {
      params: {name: inputValue},
    })
    setItems(data.data)
    setTotalPages(data.meta.last_page)
  }

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1)
  }

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1)
  }
  useEffect(() => {
    getData()
  }, [inputValue, currentPage, pending_order])

  const columns = [
    {
      Header: intl.formatMessage({id: 'Table.ID'}),
      accessor: 'id',
      Cell: ({row}) => <div className='w-100 flex-center'># {row.original.id}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.User'}),
      accessor: (row) => `${row.user?.name} ${row.user?.id}`,
      Cell: (row) =>
        row.row.original.user && (
          <Link to={`/Users/Edit-Users/${row.row.original.user.id}`}>
            <div className='d-flex align-items-center ps-3'>
              <Images img={row.row.original.user.img} name={row.row.original.user.name} />
              <div className='flex-grow-1'>
                <div className='text-dark fw-bold text-hover-primary text-start fs-6'>
                  {row.row.original.user.name}
                </div>
                <span className='text-muted d-block fw-semibold text-start'>
                  #{row.row.original.user.id}
                </span>
              </div>
            </div>
          </Link>
        ),
      // Define a custom filter function
      filter: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const user = row.values[id] 
          return user.toLowerCase().includes(filterValue.toLowerCase())
        })
      },
    },
    {
      Header: intl.formatMessage({id: 'Table.Total'}),
      accessor: 'total',
      Cell: ({row}) => <div className='w-100 flex-center'>{row.original.total}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.Status'}),
      accessor: 'status',
      Cell: ({row}) => (
        <div className='w-100 flex-center'>
          {row.original.status === 'failed' && (
            <span className='badge badge-light-danger'>{row.original.status}</span>
          )}

          {row.original.status === 'pending' && (
            <span className='badge badge-light-warning'>{row.original.status}</span>
          )}

          {row.original.status === 'completed' && (
            <span className='badge badge-light-success'>{row.original.status}</span>
          )}
        </div>
      ),
    },
    {
      Header: intl.formatMessage({id: 'Table.AdminApproved'}),
      accessor: 'admin_approved',
      Cell: ({row}) => (
        <div className='w-100 flex-center'>
          {row.original.admin_approved === 'refused' && (
            <span className='badge badge-light-danger'>{row.original.admin_approved}</span>
          )}

          {row.original.admin_approved === 'pending' && (
            <span className='badge badge-light-warning'>{row.original.admin_approved}</span>
          )}

          {row.original.admin_approved === 'approved' && (
            <span className='badge badge-light-success'>{row.original.admin_approved}</span>
          )}
        </div>
      ),
    },
    {
      Header: intl.formatMessage({id: 'Table.payment_type'}),
      accessor: 'payment_type',
      Cell: ({row}) => <div className='w-100 flex-center'>{row.original.payment_type}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.Date'}),
      accessor: 'created_at',
      Cell: ({row}) => <div className='w-100 flex-center'>{row.original.created_at}</div>,
    },

    {
      Header: intl.formatMessage({id: 'Table.Action'}),
      Cell: ({row}) => (
        <div className='d-flex flex-center'>
          {check.show && (
            <Link
              to={`/PendingOrders-details/${row.original.id}`}
              className='btn me-2 btn-light-primary'
            >
              {intl.formatMessage({id: 'Table.View'})}
            </Link>
          )}
        </div>
      ),
    },
  ]

  useEffect(() => {
    setCheck({
      show: check_role('show-pending-orders'),
    })

    if (!check_role('browse-pending-orders')) {
      navigate('/')
    }
    getData()
    setPenidng_orders(0)
  }, [])

  return (
    <motion.nav
      initial={{opacity: 0, x: 100}}
      animate={{opacity: 1, x: 0, transition: {duration: 0.3}}}
      exit={{opacity: 0, x: 100, transition: {duration: 0.2}}}
    >
      <div className='mb-5 mt-5 d-flex flex-stack'>
        <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
          <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
            {intl.formatMessage({id: 'Menu.PendingOrders'})}
          </h1>
          <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
            <li className='breadcrumb-item text-muted'>{intl.formatMessage({id: 'Menu.Home'})}</li>
            <li className='breadcrumb-item'>
              <span className='bullet bg-gray-400 w-5px h-2px'></span>
            </li>
            <li className='breadcrumb-item text-muted'>
              {intl.formatMessage({id: 'Menu.Orders'})}
            </li>
          </ul>
        </div>
      </div>

      {!loading ? (
        <div className='d-none d-lg-block'>
          <ReactTableWithPagention
            columns={columns}
            data={items}
            currentPage={currentPage}
            totalPages={totalPages}
            handlePrevPage={handlePrevPage}
            handleNextPage={handleNextPage}
            inputValue={inputValue}
            setInputValue={setInputValue}
            customComponent={<></>}
          />
        </div>
      ) : (
        <div className='row'>
          <div className='col-12'>
            <Skeleton
              variant='rounded'
              animation='wave'
              sx={{width: '100%', height: '90vh', marginTop: '1vh'}}
            />
          </div>
        </div>
      )}
      {/* Card View for Small Screens */}
      <div className='d-block d-lg-none'>
        <div className='mb-4'>
          <input
            type='text'
            className='form-control'
            placeholder={intl.formatMessage({id: 'Search.Input'})}
            value={inputValue || ''}
            onChange={(e) => setInputValue(e.target.value)}
          />
        </div>

        {items.length > 0 ? (
          <>
            {items.map((row) => (
              <div className='card mb-3' key={row.id}>
                <div className='card-body'>
                  {/* ID */}
                  <h5 className='card-title'>
                    {intl.formatMessage({id: 'Table.ID'})}: # {row.id}
                  </h5>

                  {/* User */}
                  <p className='card-text'>
                    <strong>{intl.formatMessage({id: 'Table.User'})}:</strong>
                    <Link to={`/Users/Edit-Users/${row.user?.id}`}>
                      {row.user?.name} #{row.user?.id}
                    </Link>
                  </p>

                  {/* Total */}
                  <p className='card-text'>
                    <strong>{intl.formatMessage({id: 'Table.Total'})}:</strong> {row.total}
                  </p>

                  {/* Status */}
                  <p className='card-text'>
                    <strong>{intl.formatMessage({id: 'Table.Status'})}:</strong>
                    {row.status === 'failed' && (
                      <span className='badge badge-light-danger'>{row.status}</span>
                    )}
                    {row.status === 'pending' && (
                      <span className='badge badge-light-warning'>{row.status}</span>
                    )}
                    {row.status === 'completed' && (
                      <span className='badge badge-light-success'>{row.status}</span>
                    )}
                  </p>

                  {/* Admin Approved */}
                  <p className='card-text'>
                    <strong>{intl.formatMessage({id: 'Table.AdminApproved'})}:</strong>
                    {row.admin_approved === 'refused' && (
                      <span className='badge badge-light-danger'>{row.admin_approved}</span>
                    )}
                    {row.admin_approved === 'pending' && (
                      <span className='badge badge-light-warning'>{row.admin_approved}</span>
                    )}
                    {row.admin_approved === 'approved' && (
                      <span className='badge badge-light-success'>{row.admin_approved}</span>
                    )}
                  </p>

                  {/* Payment Type */}
                  <p className='card-text'>
                    <strong>{intl.formatMessage({id: 'Table.payment_type'})}:</strong>{' '}
                    {row.payment_type}
                  </p>

                  {/* Date */}
                  <p className='card-text'>
                    <strong>{intl.formatMessage({id: 'Table.Date'})}:</strong> {row.created_at}
                  </p>

                  {/* Custom Fields or Additional Data */}
                  <p className='card-text'>
                    <strong>{intl.formatMessage({id: 'Table.AdditionalInfo'})}:</strong>{' '}
                    {row.additionalInfo}
                  </p>

                  {/* Action Button */}
                  <div className='d-flex justify-content-start'>
                    {check.show && (
                      <Link
                        to={`/PendingOrders-details/${row.id}`}
                        className='btn btn-light-primary'
                      >
                        {intl.formatMessage({id: 'Table.View'})}
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            ))}

            {/* Pagination Controls for Card View */}
            <div className='d-flex justify-content-center align-items-center mt-3'>
              <button
                className='btn btn-light-primary me-2'
                onClick={handlePrevPage}
                disabled={currentPage === 1}
              >
                {intl.formatMessage({id: 'Table.Prev'})}
              </button>

              {/* Pagination numbers */}
              <div className='d-flex'>
                {currentPage > 2 && (
                  <button className='btn btn-light mx-1' onClick={() => setCurrentPage(1)}>
                    1
                  </button>
                )}

                {currentPage > 3 && <span className='mx-2'>...</span>}

                {[...Array(totalPages)].map((_, index) => {
                  const pageNumber = index + 1

                  // Only show the current page and surrounding pages
                  if (
                    pageNumber === currentPage ||
                    (pageNumber >= currentPage - 1 && pageNumber <= currentPage + 1)
                  ) {
                    return (
                      <button
                        key={index}
                        className={`btn ${
                          currentPage === pageNumber ? 'btn-primary' : 'btn-light'
                        } mx-1`}
                        onClick={() => setCurrentPage(pageNumber)}
                      >
                        {pageNumber}
                      </button>
                    )
                  }

                  return null
                })}

                {currentPage < totalPages - 2 && <span className='mx-2'>...</span>}

                {currentPage < totalPages - 1 && (
                  <button className='btn btn-light mx-1' onClick={() => setCurrentPage(totalPages)}>
                    {totalPages}
                  </button>
                )}
              </div>

              <button
                className='btn btn-light-primary ms-2'
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                {intl.formatMessage({id: 'Table.Next'})}
              </button>
            </div>
          </>
        ) : (
          <div className='text-center my-5'>
            {/* Spinner for loading effect */}
            <div className='spinner-border text-primary mb-3' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>

            {/* No Data message */}
            <p className='text-muted fs-4'>
              <i className='fas fa-exclamation-circle me-2'></i>
              {intl.formatMessage({id: 'Table.NoData'})}
            </p>
          </div>
        )}
      </div>
    </motion.nav>
  )
}

export default PendingOrdersHome
