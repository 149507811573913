import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import axiosInstance from '../../../../../../api/axios'
import Swal from 'sweetalert2'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {useNavigate, useParams} from 'react-router-dom'
import {motion} from 'framer-motion'
import ReactTable from '../../../../../../../_metronic/partials/widgets/React_Table/ReactTable'
import {Skeleton} from '@mui/material'
import useAuthContext from '../../../../../../Auth/AuthContext'
import { AddCreditUserModel } from './AddCreditUserModel'
import { EditCreditUserModel } from './EditCreditUserModel'

const Credit = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [Items, setItems] = useState(null)
  const {id} = useParams()
  const navigate = useNavigate()
  const {check_role} = useAuthContext()
  const [check, setCheck] = useState({
    browse: false,
    add: false,
    show: false,
    edit: false,
    delete: false,
  })
  const [openModal, setOpenModal] = useState(false) 
  const [openModalEdit, setOpenModalEdit] = useState(false) 

  const CustomDiv = () => (
    <div className='row flex-end w-100'>
      {check.add && (
        <div className='col-auto'>
          <button type='button' className='btn btn-primary' onClick={() => setOpenModal(true)}>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            {intl.formatMessage({id: 'Create.Credit.Users'})}
          </button>
        </div>
      )}
    </div>
  )

  const get_data = async () => {
    setLoading(true)
    const {data} = await axiosInstance.get(`/user-credit/${id}`)
    setItems(data)
    setLoading(false)
  }

  const edit_status = async (value, id) => {
    try {
      await axiosInstance.get(`/change-Admin-user-credit-status/${id}/${value}`)
      get_data()
    } catch (error) {
      console.error(error)
    }
  }

  const Delete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const {data} = await axiosInstance.delete(`/Admin-user-credit/${id}`)
          get_data()
        } catch (error) {
          Swal.showValidationMessage(`Request failed: ${error}`)
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({title: 'Done!', icon: 'success'})
      }
    })
  }

  const columns = [
    {
      Header: intl.formatMessage({id: 'Table.credit_number'}),
      accessor: 'credit_number',
      Cell: (row) => <div className=' w-100 flex-center'>{row.row.original.credit_number}</div>,
    },
    {
      Header: 'enable',
      accessor: 'status',
      Cell: (row) => (
        <div className='form-check mt-2 form-switch form-switch-sm form-check-custom form-check-solid flex-center'>
          {check.edit && (
            <input
              className='form-check-input'
              type='checkbox'
              defaultChecked={row.row.original.status}
              onClick={() => edit_status(!row.row.original.status, row.row.original.id)}
            />
          )}
        </div>
      ),
    },
    {
      Header: intl.formatMessage({id: 'Table.Action'}),
      Cell: (row) => (
        <div className='d-flex flex-center'>
          {/* {check.edit && (
            <button
            onClick={() => setOpenModalEdit(true)}
              className='btn me-2 btn-light-primary'
            >
              {intl.formatMessage({id: 'Table.Edit'})}
            </button>
          )} */}
          {check.delete && (
            <button onClick={() => Delete(row.row.original.id)} className='btn me-2 btn-light-danger'>
              {intl.formatMessage({id: 'Table.Delete'})}
            </button>
          )}
        </div>
      ),
    },
  ]

  useEffect(() => {
    setCheck({
      browse: check_role('browse-user-credits'),
      add: check_role('add-user-credits'),
      show: check_role('show-user-credits'),
      edit: check_role('edit-user-credits'),
      delete: check_role('delete-user-credits'),
    })

    if (!check_role('browse-user-credits')) {
      navigate('/')
    }
    get_data()
  }, [])

  return (
    <motion.nav
      initial={{opacity: 0, x: 100}}
      animate={{opacity: 1, x: 0, transition: {duration: 0.3}}}
      exit={{opacity: 0, x: 100, transition: {duration: 0.2}}}
    >
      {Items !== null && !loading ? (
        <ReactTable columns={columns} data={Items} customComponent={<CustomDiv />} />
      ) : (
        <div className='row'>
          <div className='col-12'>
            <Skeleton
              variant='rounded'
              animation='wave'
              sx={{width: '100%', height: '90vh', marginTop: '1vh'}}
            />
          </div>
        </div>
      )}

      {/* Modal for creating credit */}
      <AddCreditUserModel user_id={id} get_Date={get_data} handleClose={setOpenModal} get_data={get_data} show={openModal} />
      {/* <EditCreditUserModel user_id={id} get_Date={get_data} handleClose={setOpenModalEdit} get_data={get_data} show={openModalEdit} /> */}
    </motion.nav>
  )
}

export default Credit
