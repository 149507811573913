import React from "react";
import EmailInput from "./components/EmailInput";
import { AnimatePresence, motion } from "framer-motion";
import { MdOutlineEmail } from "react-icons/md";

export function Signin() {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center min-vw-100 min-vh-100">
      <div className="signin-card bg-white shadow-lg py-4 px-12 col-4 d-flex flex-column align-items-center gap-3 rounded-3">
        <h2 className="fs-4 pt-4">Sign in to enter your email</h2>

        <div className="signin-options w-100 d-flex flex-column align-items-center">
          {/* <div className="signin-button-container d-flex rounded overflow-hidden">
            <motion.div
              className="signin-button px-2 py-1 d-flex align-items-center position-relative"
              initial={{ backgroundColor: "#007bff" }}
              animate={{ backgroundColor: "#007bff" }}
              whileHover={{ scale: 1.05 }}
            >
              <button className="btn d-flex align-items-center gap-1 text-white">
                <MdOutlineEmail size={18} />
                <span>Email</span>
              </button>
              <motion.div
                layoutId="card-animation"
                className="highlight-animation position-absolute w-100 h-100 top-0 start-0"
              ></motion.div>
            </motion.div>
          </div> */}

          <div className="input-container w-100 position-relative mt-1">
            <AnimatePresence mode="wait">
              <EmailInput />
            </AnimatePresence>
          </div>
        </div>
      </div>
    </div>
  );
}
