import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Link, useNavigate, useParams } from 'react-router-dom'
import axiosInstance from '../../../api/axios'
import Swal from 'sweetalert2'
import { motion } from 'framer-motion'
import { KTSVG } from '../../../../_metronic/helpers'
import useAuthContext from '../../../Auth/AuthContext'

export const UpdatePayments = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const navigate = useNavigate()
  const { id } = useParams()
  const { check_role } = useAuthContext()

  const [data, setData] = useState({
    name: null,
    value: null,
    App_key: null,
    value: null,
  })

  const [rowsData, setRowsData] = useState([
    {
      App_key: '',
      value: '',
    },
  ])

  const addTableRows = () => {
    const rowsInput = {
      App_key: '',
      value: '',
    }
    setRowsData([...rowsData, rowsInput])
  }
  const deleteTableRows = (index) => {
    const rows = [...rowsData]
    rows.splice(index, 1)
    setRowsData(rows)
  }

  const handleChange2 = (index, evnt) => {
    const { name, value } = evnt.target
    const rowsInput = [...rowsData]
    rowsInput[index][name] = value
    setRowsData(rowsInput)
    console.log(rowsData)
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setData((prevData) => ({ ...prevData, [name]: value }))
  }

  const handleEdit_Add_submit = async (event) => {
    setLoading(true)
    event.preventDefault()
    const formData = new FormData()
    formData.append('enctype', 'multipart/form-data')

    formData.append('_method', 'PATCH')

    rowsData.forEach((row, index) => {
      formData.append(`data[${index}][App_key]`, row.App_key)
      formData.append(`data[${index}][value]`, row.value)
    })

    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value)
    })

    try {
      await axiosInstance.post(`/admin-payments/${id}`, formData).then((response) => {
        Swal.fire({
          title: 'Success',
          icon: 'success',
          confirmButtonText: 'OK',
        })
        setLoading(false)
        setErrors([])
        navigate(-1)
      })
    } catch (e) {
      console.log(e.response.data.errors)
      if (e.response.status === 422) {
        setLoading(false)
        setErrors(e.response.data.errors)
      }
    }
  }

  const get_data = async () => {
    await axiosInstance.get(`/admin-payments/${id}`).then(function (res) {
      const response = res.data
      console.log()
      // console.log(response);

      setData({
        name: response.name !== 'null' ? response.name : null,
        value: response.value !== 'null' ? response.value : null,
        app_key: response.app_key !== 'null' ? response.app_key : null,
        app_screet: response.app_screet !== 'null' ? response.app_screet : null,
        user_name: response.user_name !== 'null' ? response.user_name : null,
        password: response.password !== 'null' ? response.password : null,
      })
      if (response.data) {
        const newRowsData = Object.entries(JSON.parse(response.data)).map(([key, value]) => {
          return {
            App_key: key,
            value: value,
          };
        });
        setRowsData(newRowsData);
      }
    })
  }

  useEffect(() => {
    if (!check_role('show-payments')) {
      navigate('/')
    }

    get_data()
  }, [])
  return (
    <motion.nav
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0, transition: { duration: 0.3 } }}
      exit={{ opacity: 0, x: 100, transition: { duration: 0.2 } }}
    >
      <div className=' mb-5 mt-5 d-flex flex-stack'>
        <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
          <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
            {intl.formatMessage({ id: 'Payments.Edit' })}
          </h1>
          <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
            <li className='breadcrumb-item text-muted'>
              <span className='text-muted text-hover-primary'>
                {' '}
                {intl.formatMessage({ id: 'Menu.Home' })}
              </span>
            </li>
            <li className='breadcrumb-item'>
              <span className='bullet bg-gray-400 w-5px h-2px'></span>
            </li>
            <li className='breadcrumb-item text-muted'>
              {intl.formatMessage({ id: 'Menu.Payments' })}
            </li>
          </ul>
        </div>
        <div>
          <button onClick={() => navigate(-1)} type='button' className='btn btn-primary'>
            <KTSVG path='/media/icons/duotune/arrows/arr002.svg' className='svg-icon-2' />
            {intl.formatMessage({ id: 'Form.GoBack' })}
          </button>
        </div>
      </div>
      <div id='kt_app_content_container' className='app-container container-xxl'>
        <form
          onSubmit={handleEdit_Add_submit}
          id='kt_ecommerce_add_category_form'
          className='form d-flex flex-column flex-lg-row fv-plugins-bootstrap5 fv-plugins-framework'
          data-kt-redirect='../../demo1/dist/apps/ecommerce/catalog/categories.html'
        >
          <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
            <div className='card card-flush py-4'>
              <div className='card-header'>
                <div className='card-title'>
                  <h2>{intl.formatMessage({ id: 'Form.General' })} </h2>
                </div>
              </div>

              <div className='card-body pt-0 d-flex gap-7'>
                <div className='mb-3 fv-row fv-plugins-icon-container w-100'>
                  <label className='required form-label'>
                    {' '}
                    {intl.formatMessage({ id: 'Form.Name' })}{' '}
                  </label>
                  <input
                    type='text'
                    name='name'
                    className='form-control mb-2'
                    value={data.name}
                    onChange={handleChange}
                    placeholder={intl.formatMessage({ id: 'Form.Name' })}
                  />
                  <div className='fv-plugins-message-container invalid-feedback' />
                  {errors.name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{errors.name}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='mb-3 fv-row fv-plugins-icon-container w-100'>
                  <label className='required form-label'>
                    {' '}
                    {intl.formatMessage({ id: 'Form.Value' })}
                  </label>
                  <input
                    type='text'
                    name='value'
                    className='form-control mb-2'
                    value={data.value}
                    onChange={handleChange}
                    placeholder={intl.formatMessage({ id: 'Form.Value' })}
                  />
                  <div className='fv-plugins-message-container invalid-feedback' />
                  {errors.value && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{errors.value}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/*  */}

              <div className='card card-flush py-4'>
                <div className='card-header'>
                  <div className='card-title'>
                    <h2>{intl.formatMessage({ id: 'Table.Points' })}</h2>
                  </div>
                </div>

                <div className='card-body pt-0'>
                  <div className='row'>
                    <div className='mb-3 fv-row fv-plugins-icon-container mt-3 '>
                      <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                        <thead className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                          <tr>
                            <th>{intl.formatMessage({ id: 'Form.app_key' })}</th>
                            <th>{intl.formatMessage({ id: 'Form.Value' })}</th>
                            <th>
                              <button
                                type='button'
                                className='btn me-2 btn-light-primary'
                                onClick={addTableRows}
                              >
                                +
                              </button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {rowsData?.map((row, key) => (
                            <tr key={key}>
                              <td style={{ width: '400px' }}>
                                <input
                                  type='text'
                                  value={row?.App_key}
                                  onChange={(evnt) => handleChange2(key, evnt)}
                                  name='App_key'
                                  className='form-control'
                                />
                              </td>
                              <td style={{ width: '400px' }}>
                                <input
                                  type='text'
                                  value={row?.value}
                                  onChange={(evnt) => handleChange2(key, evnt)}
                                  name='value'
                                  className='form-control'
                                />
                              </td>
                              <td>
                                <div className='d-flex flex-center'>
                                  <button
                                    type='button'
                                    className='btn me-2 btn-light-primary'
                                    onClick={addTableRows}
                                  >
                                    +
                                  </button>
                                  <button
                                    className='btn me-2 btn-light-danger'
                                    type='button'
                                    onClick={() => deleteTableRows(key)}
                                  >
                                    x
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='d-flex justify-content-end '>
              {check_role('edit-payments') && (
                <button type='submit' className='btn btn-primary'>
                  {!loading && (
                    <span className='indicator-label'>
                      {intl.formatMessage({ id: 'Form.Edit' })}{' '}
                    </span>
                  )}
                  {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      {intl.formatMessage({ id: 'Form.Pleasewait' })}{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </motion.nav>
  )
}
