import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import axiosInstance from '../../../api/axios'
import Swal from 'sweetalert2'
import {KTSVG} from '../../../../_metronic/helpers'
import {Link, useNavigate} from 'react-router-dom'
import {motion} from 'framer-motion'
import ReactTable from '../../../../_metronic/partials/widgets/React_Table/ReactTable'
import {Skeleton} from '@mui/material'
import {FaCopy} from 'react-icons/fa'
import Pagination from '@mui/material/Pagination' // Importing Pagination
import useAuthContext from '../../../Auth/AuthContext'

const SuppliersHome = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [Items, setItems] = useState([])
  const {check_role} = useAuthContext()
  const navigate = useNavigate()

  const [check, setCheck] = useState({
    browse: false,
    add: false,
    show: false,
    edit: false,
    delete: false,
  })

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage] = useState(10)

  // Search state
  const [searchTerm, setSearchTerm] = useState('')

  const CustomDiv = () => (
    <div className='row flex-end w-100 mb-4'>
      {check.add && (
        <div className='col-auto'>
          <Link to='/Add-Suppliers' className='btn btn-primary shadow-sm rounded-pill'>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            {intl.formatMessage({id: 'Suppliers.Create'})}
          </Link>
        </div>
      )}
    </div>
  )

  const get_data = async () => {
    setLoading(true)
    try {
      const {data} = await axiosInstance.get('/admin-suppliers')
      setItems(data)
    } catch (error) {
      console.error(error)
    }
    setLoading(false)
  }

  const success = () => {
    Swal.fire({
      icon: 'success',
      text: intl.formatMessage({id: 'Table.text_copy'}),
      showConfirmButton: false,
      timer: 1500,
    })
  }

  const edit_status = async (value, id) => {
    try {
      await axiosInstance.get(`/change-suppliers-status/${id}/${value}`)
      get_data()
    } catch (error) {
      console.error(error)
    }
  }

  const Delete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const {data} = await axiosInstance.delete(`/admin-suppliers/${id}`)
          if (!data || data.length === 0) {
            throw new Error(JSON.stringify(data))
          }
          get_data()
        } catch (error) {
          Swal.showValidationMessage(`Request failed: ${error}`)
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Deleted!',
          icon: 'success',
        })
      }
    })
  }

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = Items.slice(indexOfFirstItem, indexOfLastItem)

  const handlePageChange = (event, value) => {
    setCurrentPage(value)
  }

  // Search logic for card view
  const filteredItems = currentItems.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  )

  // Column definitions
  const columns = [
    {
      Header: intl.formatMessage({id: 'Table.ID'}),
      accessor: 'id',
      Cell: ({row}) => (
        <div className='w-100 text-center'>{row?.original ? `#${row.original.id}` : 'N/A'}</div>
      ),
    },
    {
      Header: intl.formatMessage({id: 'Table.Name'}),
      accessor: 'name',
      Cell: ({row}) => (
        <div className='w-100 text-center'>{row?.original ? row.original.name : 'N/A'}</div>
      ),
    },
    {
      Header: 'Enable',
      accessor: 'status',
      Cell: ({row}) => (
        <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid d-flex justify-content-center'>
          {check.edit && (
            <input
              className='form-check-input'
              type='checkbox'
              value={row?.original?.status}
              defaultChecked={row?.original?.status}
              onClick={() => edit_status(!row.original.status, row.original.id)}
            />
          )}
        </div>
      ),
    },
    {
      Header: intl.formatMessage({id: 'Table.app_key'}),
      accessor: 'app_key',
      Cell: ({row}) => (
        <div className='d-flex justify-content-center'>
          <button
            onClick={() => [navigator.clipboard.writeText(row?.original?.app_key), success()]}
            className='btn btn-sm btn-primary shadow-sm rounded-pill'
          >
            <FaCopy className='me-2' />
            {intl.formatMessage({id: 'Table.Copy'})}
          </button>
        </div>
      ),
    },
    {
      Header: intl.formatMessage({id: 'Table.app_screet'}),
      accessor: 'app_screet',
      Cell: ({row}) => (
        <div className='d-flex justify-content-center'>
          <button
            onClick={() => [navigator.clipboard.writeText(row?.original?.app_screet), success()]}
            className='btn btn-sm btn-primary shadow-sm rounded-pill'
          >
            <FaCopy className='me-2' />
            {intl.formatMessage({id: 'Table.Copy'})}
          </button>
        </div>
      ),
    },
    {
      Header: intl.formatMessage({id: 'Table.Action'}),
      Cell: ({row}) => (
        <div>
          <div className='d-flex justify-content-center mb-2'>
            {check.edit && (
              <Link
                to={`/Edit-Suppliers/${row?.original?.id}`}
                className='btn btn-sm btn-light-primary shadow-sm rounded-pill me-2'
              >
                {intl.formatMessage({id: 'Table.Edit'})}
              </Link>
            )}
            {check.delete && (
              <button
                onClick={() => Delete(row?.original?.id)}
                className='btn btn-sm btn-light-danger shadow-sm rounded-pill'
              >
                {intl.formatMessage({id: 'Table.Delete'})}
              </button>
            )}
          </div>
          <div className='d-flex justify-content-center'>
            <Link
              to={`/Suppliers/${row?.original?.value}/get-Categories/${row?.original?.id}`}
              className='btn btn-sm btn-light-primary shadow-sm rounded-pill me-2'
            >
              {intl.formatMessage({id: 'Menu.Categories'})}
            </Link>
            <Link
              to={`/Suppliers/${row?.original?.value}/Reports/${row?.original?.value}`}
              className='btn btn-sm btn-light-primary shadow-sm rounded-pill'
            >
              {intl.formatMessage({id: 'Menu.Reports'})}
            </Link>
          </div>
        </div>
      ),
    },
  ]

  useEffect(() => {
    setCheck({
      browse: check_role('browse-suppliers'),
      add: check_role('add-suppliers'),
      show: check_role('show-suppliers'),
      edit: check_role('edit-suppliers'),
      delete: check_role('delete-suppliers'),
    })

    if (!check_role('browse-suppliers')) {
      navigate('/')
    }
    get_data()
  }, [])

  return (
    <motion.nav
      initial={{opacity: 0, x: 100}}
      animate={{opacity: 1, x: 0, transition: {duration: 0.3}}}
      exit={{opacity: 0, x: 100, transition: {duration: 0.2}}}
    >
      <div className='mb-5 mt-5 d-flex flex-stack'>
        <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
          <h1 className='page-heading text-dark fw-bold fs-3 my-0'>
            {intl.formatMessage({id: 'Menu.Suppliers'})}
          </h1>
          <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
            <li className='breadcrumb-item text-muted'>{intl.formatMessage({id: 'Menu.Home'})}</li>
            <li className='breadcrumb-item'>
              <span className='bullet bg-gray-400 w-5px h-2px'></span>
            </li>
            <li className='breadcrumb-item text-muted'>
              {intl.formatMessage({id: 'Menu.Suppliers'})}
            </li>
          </ul>
        </div>
      </div>

      {Items.length > 0 && !loading ? (
        <>
          {/* Table for large screens */}
          <div className='d-none d-lg-block'>
            <ReactTable columns={columns} data={Items} customComponent={<CustomDiv />} />
          </div>

          {/* Card view for small screens */}
          <div className='d-lg-none d-block'>
            {/* Search input for card view */}
            <div className='mb-4'>
              <CustomDiv />

              <input
                type='text'
                className='form-control'
                placeholder={intl.formatMessage({id: 'Search.Input'})}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>

            {filteredItems.map((item) => (
              <div key={item.id} className='card mb-4 shadow-sm border-0 rounded-3'>
                <div className='card-body'>
                  <h5 className='card-title fw-bold'>{item.name}</h5>
                  <p className='card-text'>
                    <strong>{intl.formatMessage({id: 'Table.ID'})}:</strong> {item.id}
                  </p>
                  <div className='d-flex justify-content-between mb-2'>
                    <div className='me-2'>
                      <strong>{intl.formatMessage({id: 'Table.app_key'})}:</strong>{' '}
                      <span>************</span>
                    </div>
                    <button
                      onClick={() => [navigator.clipboard.writeText(item.app_key), success()]}
                      className='btn btn-sm btn-primary shadow-sm rounded-pill'
                    >
                      <FaCopy />
                      {intl.formatMessage({id: 'Table.Copy'})}
                    </button>
                  </div>
                  <div className='d-flex justify-content-between mb-2'>
                    <div className='me-2'>
                      <strong>{intl.formatMessage({id: 'Table.app_screet'})}:</strong>{' '}
                      <span>************</span>
                    </div>
                    <button
                      onClick={() => [navigator.clipboard.writeText(item.app_screet), success()]}
                      className='btn btn-sm btn-primary shadow-sm rounded-pill'
                    >
                      <FaCopy />
                      {intl.formatMessage({id: 'Table.Copy'})}
                    </button>
                  </div>
                  <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid d-flex justify-content-start mb-2'>
                    {check.edit && (
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value={item.status}
                        defaultChecked={item.status}
                        onClick={() => edit_status(!item.status, item.id)}
                      />
                    )}
                    <label className='form-check-label ms-2'>
                      {intl.formatMessage({id: 'Table.Enable'})}
                    </label>
                  </div>
                  <div className='row flex-between'>
                    <div className='col-6 my-2'>
                      <Link
                        to={`/Edit-Suppliers/${item.id}`}
                        className='btn btn-sm btn-light-primary shadow-sm rounded-pill w-100'
                      >
                        {intl.formatMessage({id: 'Table.Edit'})}
                      </Link>
                    </div>
                    <div className='col-6 my-2'>
                      <button
                        onClick={() => Delete(item.id)}
                        className='btn btn-sm btn-light-danger shadow-sm rounded-pill w-100'
                      >
                        {intl.formatMessage({id: 'Table.Delete'})}
                      </button>
                    </div>
                    <div className='col-6 my-2'>
                      <Link
                        to={`/Suppliers/${item?.value}/get-Categories/${item.id}`}
                        className='btn btn-sm btn-light-primary shadow-sm rounded-pill me-2 w-100'
                      >
                        {intl.formatMessage({id: 'Menu.Categories'})}
                      </Link>
                    </div>
                    <div className='col-6 my-2'>
                      <Link
                        to={`/Suppliers/${item?.value}/Reports/${item?.value}`}
                        className='btn btn-sm btn-light-primary shadow-sm rounded-pill w-100'
                      >
                        {intl.formatMessage({id: 'Menu.Reports'})}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            {/* Pagination for card view */}
            <div className='mt-6 d-flex justify-content-center'>
              <Pagination
                count={Math.ceil(Items.length / itemsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                color='primary'
              />
            </div>
          </div>
        </>
      ) : (
        <div className='row'>
          <div className='col-12'>
            <Skeleton
              variant='rounded'
              animation='wave'
              sx={{width: '100%', height: '90vh', marginTop: '1vh'}}
            />
          </div>
        </div>
      )}
    </motion.nav>
  )
}

export default SuppliersHome
